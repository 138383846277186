import { Box } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import OverviewSection from '../../components/OverviewSection';
import PortfolioSelector from '../../components/PortfolioSelector';
import SelectUs from '../../components/selectUs';

const PortfolioWebsite = () => {
  const menuItemData = [
    {
      label: "Select Your Industry",
      value: "",
      images: [
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/01.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/01.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/03.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/01.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/01.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/03.jpg",




        // Add more image URLs as needed
      ],
    },
    {
      label: "Accounting",
      value: "accounting",
      images: [
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/01.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/01.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/accounting/03.jpg",


        // Add more image URLs as needed
      ],
    },
    {
      label: "Agricultural",
      value: "agriculture",
      images: [
        "https://www.uptownlogodesign.com/assets/images/industry-web/agriculture/thmb/03.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/agriculture/thmb/04.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/agriculture/thmb/03.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/agriculture/thmb/04.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/agriculture/thmb/03.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry-web/agriculture/thmb/04.jpg",





        // Add more image URLs as needed
      ],
    },
    // Add more menu items as needed
  ];

  // for select us
  const Navigate = useNavigate();
  const handlePackages = () => {
    Navigate("/logo-design/packages");
  };
  return (
    <div  style={{ width: "100%", height: "100%", marginTop: "160px" }}>
      <OverviewSection
        title1="A Quick Look
        At Our Portfolio"
        subTitle="The Projects We Created For Our Clients Are A Source Of Pride And Motivation For Us."
        listItems={[
          "Modern Web Design Concepts",
          "Web Revision Cycles",
          "Iconic Websites For 100% Satisfaction",
          "Affordable Web Design Packages",
          "Web Design Gurus At Work",
        ]}
        // imageUrl="/assets/MainContentImg/workspace_04.jpg"
        // imageUrl2="/assets/MainContentImg/workspace_04.jpg"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/portfolio.jpg"
        reverseLayout={true}
      />
      <Box sx={{ padding: "30px" }}>
      <PortfolioSelector menuItemData={menuItemData} />
      </Box>
      <Box
        sx={{
          boxShadow: {
            xs: "none", 
            md: "0px 1px 20px rgba(0, 0, 0.4, 0.4)" 
          },
          marginBottom: "0rem",
          padding: "0rem",
        }}

      >
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
    </div>
  )
}

export default PortfolioWebsite