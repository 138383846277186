import React, { useEffect, useRef, useState } from "react";
import OverviewSection from "../../components/OverviewSection";
import OverViewDetails from "../../components/OverViewDetails";
import SelectUs from "../../components/selectUs";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import MoreIndustriesOverview from "../../components/MoreIndustriesOverview";
import OverviewLogoDesignStappers from "../../components/OverviewLogoDesignStappers";
import Reviews from "../../components/Reviews";
import Brosheres from "../../components/Brosheres";
import RegistrationForm from "../../components/RegistrationForm";
import IncludedPackageImg from "..//..//Assets/IncEveryoneWebsite/web.png"
import brosherImg01 from "../..//Assets/keyFeatures/guarantee.png"
import keyFeatureImg01 from "../../Assets//keyFeatures/guarantee.png"
import keyFeatureImg02 from "../../Assets//keyFeatures/money-back-guarantee.png"
import keyFeatureImg03 from "../../Assets//keyFeatures/think-outside-the-box.png"
import keyFeatureImg04 from "../../Assets//keyFeatures/video-call.png"
import keyFeatureImg05 from "../../Assets//keyFeatures/trophy.png"
import step01 from "../../Assets/stappers/stappers1.gif"
import step02 from "../../Assets/stappers/stappers2.gif"
import step03 from "../../Assets/stappers/stappers3.gif"
import step04 from "../../Assets/stappers/stappers4.gif"
import step05 from "../../Assets/stappers/stappers5.gif"
import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"




const OverviewWebsite = () => {
  //for brosher scroll

  const brosheresRef = useRef<HTMLDivElement>(null);
  const originalRef = useRef<HTMLDivElement | null>(null); // Store the original ref
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    originalRef.current = brosheresRef.current; // Assign the original ref
    // Start scrolling automatically when the component mounts
    startAutoScroll();

    // Clear the interval when the component unmounts
    return () => {
      if (scrollInterval) clearInterval(scrollInterval);
    };
  }, []);

  const startAutoScroll = () => {
    if (originalRef.current) {
      // Set an interval to scroll every 3 seconds
      const interval = setInterval(() => {
        if (originalRef.current) {
          // Calculate the new scroll position
          const newScrollLeft =
            originalRef.current.scrollLeft * 2 +
            originalRef.current.offsetWidth;

          // Calculate the maximum scroll position
          const maxScrollLeft =
            originalRef.current.scrollWidth * 2 -
            originalRef.current.offsetWidth * 2;

          // Scroll to the new position with smooth behavior
          originalRef.current.scrollTo({
            left: newScrollLeft >= maxScrollLeft ? 0 : newScrollLeft,
            behavior: "smooth",
          });
        }
      }, 10000); // Adjust the interval time as needed

      setScrollInterval(interval);
    }
  };

  const ScrollMouseEnter = () => {
    // Set brosheresRef to null when mouse enters the div
    originalRef.current = null;
  };

  const ScrollMouseLeave = () => {
    // Restore brosheresRef to its original value when mouse leaves the div
    originalRef.current = brosheresRef.current;
  };
  const cardsData = [
    {
      title: "Static website",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      // buttonText: "View More",
    },
    {
      title: "E-commerce website",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      // buttonText: "View More",
    },
    {
      title: "CMS website",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      // buttonText: "View More",
    },
    {
      title: "B2B & B2C portals",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      // buttonText: "View More",
    },
  ];

  // for select us packages render
  const Navigate = useNavigate();
  const handlePackages = () => {
    Navigate("/website/packages");
  };

  function handleButtonClick(): void {
    throw new Error("Function not implemented.");
  }

  //for more industriers

  const topics = [
    "Enterprise",
    "B2B",
    "B2C",
    "E-commerce",
    "Education",
    "N0-Profit",
    "Startups",
  ];
  

  //for steppers

  const steps = [
    {
      step: "Research",
      content:
        "Based on the client`s requisitions, our team of highly specialized professionals gleans and researches the trending norms of the industry. The extensive research regime allows our professional logo designers to curate, and handpick the very essential, yet subtle, elements necessary to transmute the particulars into unique and creative logo designs.",
      imageUrl: step01,
    },
    {
      step: "Ideate",
      content:
        "With the content stash full, our professional logo designers conceive a couple of logo design concepts to share with the client. Once scrutinized, the concepts are narrowed down to a single logo design, which is then worked upon throughout the process.",
      imageUrl: step02,
    },
    {
      step: "Create",
      content:
        "With the particulars decided, our team of professional logo designers proceeds to craft a preliminary design draft. The draft is then shared with the client, revised if necessary, and later perfected, to create the awe-inspiring logo design that sets the foundation of your marketing stratagems.",
      imageUrl: step03,
    },
    {
      step: "Color",
      content:
        "Colors impact emotions and our specialized logo designers make sure that impact lasts an eternity. Meticulously selecting a number of unique color systems as per the brand`s identity, our professional logo designers add life to the client`s logo design",
      imageUrl: step04,
    },
    {
      step: "Revision",
      content:
        "Perfection escapes humanity, and thus the revision comes in. If the client doesn’t get completely satisfied with the custom logo design, Uptown Logo Design upholds its promise, and allows tweaking of the logo design elements, until 100% client-satisfaction is achieved.",
      imageUrl: step05,
    },
    {
      step: "Finalize",
      content:
        "With the logo finalized, the revisions done (if any), and 100% client-satisfaction achieved, the team moves towards finalizing the entire project. The royalty-free custom logo design is delivered as per the agreed terms, and the process of meticulously crafting the client`s custom logo design comes to an end.",
      imageUrl: step05,
    },
  ];


  //for gallery
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const box = document.getElementById("animated-box");
      if (box) {
        const rect = box.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight - 100;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //for website gallery
  const [activeButton, setActiveButton] = useState("Promotional");

  const handleWebistegallery = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  const imageUrls = [
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/1.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/2.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/3.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/4.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/5.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/6.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/7.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/8.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/9.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/10.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/11.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/12.jpg",
  ];

  const eComImageUrls = [
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/1.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/2.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/3.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/6.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/7.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/8.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/9.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/10.jpg",
    "https://www.uptownlogodesign.com/assets/images/website-design-lp/portfolio/ecomm/11.jpg",
  ];

  const containerRefs = useRef<HTMLDivElement[]>(
    Array(imageUrls.length).fill(null) as HTMLDivElement[]
  );

  const handleMouseEnter = (ref: HTMLDivElement | null) => {
    const containerHeight = ref?.scrollHeight ?? 0;
    ref?.scrollBy({
      top: containerHeight,
      behavior: "smooth",
    });
  };

  const handleMouseLeave = (ref: HTMLDivElement | null) => {
    const containerHeight = ref?.scrollHeight ?? 0;
    ref?.scrollBy({
      top: -containerHeight,
      behavior: "smooth",
    });
  };

  const containerRefs2 = useRef<HTMLDivElement[]>(
    Array(eComImageUrls.length).fill(null) as unknown as HTMLDivElement[]
  );

  const handleMouseEnter2 = (ref: HTMLDivElement | null) => {
    const containerHeight = ref?.scrollHeight ?? 0;
    ref?.scrollBy({
      top: containerHeight,
      behavior: "smooth",
    });
  };

  const handleMouseLeave2 = (ref: HTMLDivElement | null) => {
    const containerHeight = ref?.scrollHeight ?? 0;
    ref?.scrollBy({
      top: -containerHeight,
      behavior: "smooth",
    });
  };

  const handleScrollBack = () => {
    if (originalRef.current) {
      originalRef.current.scrollTo({
        left: originalRef.current.scrollLeft - 300, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const handleScrollForward = () => {
    if (originalRef.current) {
      originalRef.current.scrollTo({
        left: originalRef.current.scrollLeft + 300, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "170%" : "initial";
    const packagesWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "200%" : "initial" ;

  return (
    <>
      <Box style={{ width: "100%", height: "100%", marginTop: containerWidth === "initial" ? "160px" : "0px"  }}>
        <Box>
          <OverviewSection
            bgImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCDZ9lR9XJlHgpJfEMUAsaOsgyrzv9zRyiEusCSmVryA&s"
            bgImage2="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACoCAMAAABt9SM9AAAAA1BMVEUekP/tgdY3AAAAR0lEQVR4nO3BAQEAAACCIP+vbkhAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAO8GxYgAAb0jQ/cAAAAASUVORK5CYII="
            title1="Empower Your Brand With Custom Websites"
            title2="E-commerce, Website for your bussiness"
            subTitle="took a galley of type and scrambled it to make a type specimen book,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
            titleColor="white"
            subTitleColor="white"
            imageUrl="https://www.uptownlogodesign.com/assets/images/website-design-lp/bann-slider/01.png"
            imageUrl2="https://www.uptownlogodesign.com/assets/images/website-design-lp/bann-slider/04.png"
            reverseLayout={false}
            runEffect={true}
          />
        </Box>
        <Box
          sx={{
            padding: "30px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            backgroundColor: "white",
            display: { xs: "none", md: "block" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#990304",
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                Included In Every Package
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#2922323", fontFamily: "Poppins", fontSize: "1rem" }}
              >
                Ensure Complete Optimization Of Your Website
              </Typography>
            </Box>
          </Box>
          <Box sx={{ paddingBottom: "50px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                paddingX: "50px",
                paddingY: "50px",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ gap: "15px", alignItems: "center", textAlign: "center" }}
              >
                <img
                  src={IncludedPackageImg}
                  alt="Feature"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#292323",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                  }}
                >
                  Analyze Goals and  Opportunities
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    paddingX: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  We review your competition, evaluate your products and
                  services, and design your branding plan.
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ gap: "15px", alignItems: "center", textAlign: "center" }}
              >
                <img
                  src={IncludedPackageImg}
                  alt="Feature"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#292323",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "1rem",

                  }}
                >
                  Interactive Website  Layout Strategy
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    paddingX: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Our experts determine the best website structure to instigate
                  credible clientele traffic to your site.
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ gap: "15px", alignItems: "center", textAlign: "center" }}
              >
                <img
                  src={IncludedPackageImg}
                  alt="Feature"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#292323",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "1rem",

                  }}
                >
                  Content Development  / Copywriting
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    paddingX: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Content is checked, organized & optimized for SEO to enhance
                  the web flow and improve search rating..
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ gap: "15px", alignItems: "center", textAlign: "center" }}
              >
                <img
                  src={IncludedPackageImg}
                  alt="Feature"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#292323",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "1rem",

                  }}
                >
                  Custom Design & Development
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    paddingX: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Each page is custom designed and include unlimited revisions &
                  stock photography.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                paddingX: "50px",
                paddingY: "50px",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ gap: "15px", alignItems: "center", textAlign: "center" }}
              >
                <img
                  src={IncludedPackageImg}
                  alt="Feature"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#292323",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "1rem",

                  }}
                >
                  Responsive Web  Development
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    paddingX: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Your site is developed to adjust for different screen sizes
                  and supports all devices & orientation..
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ gap: "15px", alignItems: "center", textAlign: "center" }}
              >
                <img
                  src={IncludedPackageImg}
                  alt="Feature"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#292323",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                  }}
                >
                  WordPress Content
                  Management 
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    paddingX: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Includes point/clicking editing for content updates in
                  seconds. Editing made easy with no monthly fee..
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ gap: "15px", alignItems: "center", textAlign: "center" }}
              >
                <img
                  src={IncludedPackageImg}
                  alt="Feature"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#292323",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                  }}
                >
                  One on One
                  Training
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    paddingX: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Your website comes with in-depth training on editing and
                  creating pages. Keep your site up-to-date, always!
                </Typography>
              </Box>
              
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ gap: "15px", alignItems: "center", textAlign: "center" }}
              >
                <img
                  src={IncludedPackageImg}
                  alt="Feature"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#292323",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                  }}
                >
                  Other Items
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    paddingX: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  We launch the site to your hosting & include social media
                  links, contact forms, and stock photos.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <OverViewDetails
          mainHeading="Type Of Websites We Design"
          mainSubheading="Take Advantage Of Our Logo Designing Services Mentioned Below"
          cardsData={cardsData}
          onButtonClick={handleButtonClick}
        />

        <Box
          sx={{
            boxShadow:
              containerWidth === "initial"
                ? "0px 5px 20px rgba(0, 0, 0.2, 0.2)"
                : "none",
            padding: "0rem",
          }}
        >
          <SelectUs
            buttonText1={"Select Package"}
            buttonText2={"Live chat"}
            onClickVoid={handlePackages}
            title={"Ready To Select Your Package ?"}
            subtitle={"Or Talk To Our Design Consultant"}
          />
        </Box>

        {/* <OverviewBenefits
          title="Website Design Benefits"
          subtitles={["Subtitle 1", "Subtitle 2", "Subtitle 3"]}
          paragraphs={[
            " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
          ]}
          images={[
            "./assets/benefitsImages/logo-pic-01.png",
            "./assets/benefitsImages/logo-pic-02.png",
            "./assets/benefitsImages/logo-pic-03.png",
            // "./assets/benefitsImages/logo-pic-04.png",
            // "./assets/benefitsImages/logo-pic-05.png",
            // "./assets/benefitsImages/logo-pic-06.png",
            // "./assets/benefitsImages/logo-pic-07.png",
            // "./assets/benefitsImages/logo-pic-08.png",
            // "./assets/benefitsImages/logo-pic-09.png",
            // "./assets/benefitsImages/logo-pic-10.png",
            // "./assets/benefitsImages/logo-pic-11.png",
            // "./assets/benefitsImages/logo-pic-12.png",
            // "./assets/benefitsImages/logo-pic-13.png",
            // "./assets/benefitsImages/logo-pic-14.png",
            // "./assets/benefitsImages/logo-pic-15.png",
            // "./assets/benefitsImages/logo-pic-16.png",
            // "./assets/benefitsImages/logo-pic-16.png",
            // "./assets/benefitsImages/logo-pic-17.png",
            // "./assets/benefitsImages/logo-pic-18.png",
          ]}
        /> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f1f1f1",
            width:packagesWidth,
            marginTop: containerWidth === "initial" ? "-0px" : "0rem",
          }}
        >
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "100%",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#990304",
                  fontSize: { sm: "1.5rem", md: "2rem" },
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "10px",
                fontFamily: "Poppins",
              }}
            >
              Carefully Crafted Website Packages For New And Existing
              Businesses
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#292323",
                textAlign: "center",
                marginBottom: "20px",
                fontFamily: "Poppins",
                fontSize: "1rem",
              }}
            >
              We Provide Highly Flexible And Affordable Design Options
            </Typography>
            <Box
              style={{ display: "flex", alignItems: "center", width: "100%", }}
            >
              {/* <ArrowBackIosIcon
                onClick={handleScrollBack}
                style={{
                  cursor: "pointer",
                  fontSize: "4rem",
                  color: "#990304",
                }}
              /> */}
              <div
                style={{
                  display: "flex",
                  gap: "50px", // Add some space between items
                  overflowX: "auto", // Enable horizontal scrolling
                  maxWidth: "100%",
                  marginTop: "-3rem",
                  paddingTop: "3rem",
                  paddingBottom: "3rem",
                  

                }}
                onMouseEnter={ScrollMouseEnter}
                onMouseLeave={ScrollMouseLeave}
                ref={brosheresRef}
              >
                {/* Container for the first set of three Brosheres */}
                <div
                  style={{ display: "flex", flexWrap: "nowrap", gap: "10px", justifyContent: "center", paddingLeft: "8rem" }}
                >
                  <>
                    <Box
                      width={{
                        xs: "100%",
                        sm: "calc(50% - 10px)",
                        md: "calc(33.33% - 10px)",
                        lg: "calc(33.33% - 10px)",
                      }}
                      mb={"1rem"}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Brosheres
                        title1="Best seller"
                        title2="Smart design solution"
                        listItems={[
                          "List Item 1",
                          "List Item 2",
                          "List Item 3",
                          "List Item 4",
                          "List Item 5",
                        ]}
                        amount="$39"
                        imageSrc={brosherImg01}
                      />
                    </Box>
                    <Box
                      width={{
                        xs: "100%",
                        sm: "calc(50% - 10px)",
                        md: "calc(33.33% - 10px)",
                        lg: "calc(33.33% - 10px)",
                      }}
                      mb={"1rem"}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Brosheres
                        title1="The Ultimate"
                        title2="Cutting-edge Design"
                        listItems={[
                          "List Item 1",
                          "List Item 2",
                          "List Item 3",
                          "List Item 4",
                          "List Item 5",
                        ]}
                        amount="$68"
                        imageSrc={brosherImg01}
                      />
                    </Box>

                    <Box
                      width={{
                        xs: "100%",
                        sm: "calc(50% - 10px)",
                        md: "calc(33.33% - 10px)",
                        lg: "calc(33.33% - 10px)",
                      }}
                      mb={"1rem"}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Brosheres
                        title1="Business Plus"
                        title2="Ideal design solutions"
                        listItems={[
                          "List Item 1",
                          "List Item 2",
                          "List Item 3",
                          "List Item 4",
                          "List Item 5",
                        ]}
                        amount="$119"
                        imageSrc={brosherImg01}
                      />
                    </Box>
                    {/* <Box
                      width={{
                        xs: "100%",
                        sm: "calc(50% - 10px)",
                        md: "calc(33.33% - 10px)",
                        lg: "calc(33.33% - 10px)",
                      }}
                    >
                      <Brosheres
                        title1="Business Plus"
                        title2="Ideal design solutions"
                        listItems={[
                          "List Item 1",
                          "List Item 2",
                          "List Item 3",
                          "List Item 4",
                          "List Item 5",
                        ]}
                        amount="$119"
                        imageSrc="/assets/keyFeatures/money-back-guarantee.png"
                      />
                    </Box> */}
                  </>
                </div>
              </div>
              {/* <ArrowForwardIosIcon
                onClick={handleScrollForward}
                style={{
                  cursor: "pointer",
                  fontSize: "4rem",
                  color: "#990304",
                }}
              /> */}
            </Box>

            {/* Add custom scrollbar styling */}
            <style>
              {`
        ::-webkit-scrollbar {
          display: none; /* Hide scrollbar */
        }
      `}
            </style>

            <Box
              justifyContent={"center"}
              sx={{
                marginTop: "-30px",
                display: {
                  xs: "none",
                  sm: "flex",
                  lg: "flex",
                  xl: "flex",
                  md: "flex",
                },
              }}
              color={"#990304"}
              fontSize={"1.2rem"}
              fontFamily={"Poppins"}
              fontWeight={"500"}
            >
              {" "}
              Key Feature
            </Box>
            <Box
              id="animated-box"
              justifyContent={"center"}
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  lg: "flex",
                  xl: "flex",
                  md: "flex",
                },
                marginTop: "20px",
                color: "#990304",
                fontSize: "0.8rem",
                position: "relative",
                paddingBottom: "20px",
                left: isVisible ? "0" : "-100%", // Initial position outside the viewport
                transition: "left 0.5s ease", // Animation transition
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "8rem",
                }}
              >
                <img
                  src={keyFeatureImg01}
                  alt="Image 1"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  100% Setisfaction 
                  Gurentee
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "8rem",
                }}
              >
                <img
                  src={keyFeatureImg02}
                  alt="Image 2"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Unique logo
                   design
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "8rem",
                }}
              >
                <img
                  src={keyFeatureImg03}
                  alt="Image 3"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  100% money Back
                  Gurentee
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "8rem",
                }}
              >
                <img
                  src={keyFeatureImg04}
                  alt="Image 3"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  24/4 Design 
                  Consultancy
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "0",
                }}
              >
                <img
                  src={keyFeatureImg05}
                  alt="Image 3"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Award Winning 
                  Designers
                </Typography>
              </div>
              {/* Repeat this structure for additional image and quote combinations */}
            </Box>
          </Box>
        </Box>

        <Box sx={{
          boxShadow: containerWidth === "initial" ? "0px 5px 20px rgba(0, 0, 0.2, 0.2)":"none",
          marginBottom: containerWidth === "initial" ? "5rem":"0rem",
          padding: "0rem",
        }}>
          <SelectUs
            buttonText1={"Select Package"}
            buttonText2={"Live chat"}
            onClickVoid={handlePackages}
            title={"Ready To Select Your Package ?"}
            subtitle={"Or Talk To Our Design Consultant"}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "center",
            color: "white", // Adjust as needed
            width: containerWidth,
          }}
        >
          <Typography
            variant="h3"
            color="#990304"
            gutterBottom
            fontSize={"2rem"}
            fontWeight={"600"}
            fontFamily="Poppins"
          >
            A Glimpse Into Our Award-Winning Website Portfolio
          </Typography>
          <Typography
            variant="subtitle1"
            color="#292323"
            fontWeight={"400"}
            fontSize={"1rem"}
            fontFamily="Poppins"
          >
            Having Spent Years In The Industry, We Have A Wide Portfolio To Talk
            About
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
              paddingY: "3rem",
            }}
          >
            <Button
              variant="outlined"
              size="large"
              sx={{
                fontFamily: "Poppins",
                color: activeButton === "Promotional" ? "#fff" : "#990304",
                borderColor:
                  activeButton === "Promotional" ? "#990304" : "#990304",
                fontWeight: "Normal",
                fontSize: "1rem",
                padding: "10px 3rem",
                backgroundColor:
                  activeButton === "Promotional" ? "#990304" : "transparent",
                "&:hover": {
                  backgroundColor: "#990304",
                  color: "white",
                  borderColor: "#990304",
                },
              }}
              onClick={() => handleWebistegallery("Promotional")}
            >
              Promotional Website
            </Button>
            <Button
              variant="outlined"
              size="large"
              sx={{
                fontFamily: "Poppins",
                color: activeButton === "E-Commerce" ? "#fff" : "#990304",
                borderColor:
                  activeButton === "E-Commerce" ? "#990304" : "#990304",
                fontWeight: "Normal",
                fontSize: "1rem",
                padding: "10px 3rem",
                backgroundColor:
                  activeButton === "E-Commerce" ? "#990304" : "transparent",
                "&:hover": {
                  backgroundColor: "#990304",
                  color: "white",
                  borderColor: "#990304",
                },
              }}
              onClick={() => handleWebistegallery("E-Commerce")}
            >
              E-Commerce Website
            </Button>
          </Box>

          <Box
            sx={{
              display: activeButton === "Promotional" ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              flexWrap: "wrap",
              gap: "100px",
              paddingBottom: "80px",
            }}
          >
            {imageUrls.map((imageUrl, index) => (
              <Box
                key={index}
                sx={{
                  width: "380px", // Adjust the width as needed
                  height: "450px", // Adjust the height as needed
                  overflowX: "auto",
                  boxSizing: "border-box",
                  border: "2px solid #000",
                  "&:hover": {
                    overflowX: "scroll",
                    transform: "scale(1.06)",
                    transition: "transform 0.3s ease-in-out",
                  },
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar
                  },
                  whiteSpace: "nowrap", // Prevent images from wrapping
                }}
                onMouseEnter={() =>
                  handleMouseEnter(containerRefs.current[index])
                }
                onMouseLeave={() =>
                  handleMouseLeave(containerRefs.current[index])
                }
                ref={(ref) =>
                  (containerRefs.current[index] = ref as HTMLDivElement)
                }
              >
                <img
                  src={imageUrl}
                  style={{ width: "100%", objectFit: "cover" }}
                  alt={`Image ${index + 1}`}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: activeButton === "E-Commerce" ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              flexWrap: "wrap",
              gap: "100px",
              paddingBottom: "80px",
            }}
          >
            {eComImageUrls.map((eComImageUrls, index) => (
              <Box
                key={index}
                sx={{
                  width: "380px", // Adjust the width as needed
                  height: "450px", // Adjust the height as needed
                  overflowX: "auto",
                  boxSizing: "border-box",
                  border: "2px solid #000",
                  "&:hover": {
                    overflowX: "scroll",
                    transform: "scale(1.06)",
                    transition: "transform 0.3s ease-in-out",
                  },
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar
                  },
                  whiteSpace: "nowrap", // Prevent images from wrapping
                }}
                onMouseEnter={() =>
                  handleMouseEnter2(containerRefs2.current[index])
                }
                onMouseLeave={() =>
                  handleMouseLeave2(containerRefs2.current[index])
                }
                ref={(ref) =>
                  (containerRefs2.current[index] = ref as HTMLDivElement)
                }
              >
                <img
                  src={eComImageUrls}
                  style={{ width: "100%", objectFit: "cover" }}
                  alt={`Image ${index + 1}`}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <MoreIndustriesOverview
          title="Over 200 Industries Served And Growing"
          subtitle="Through Our Dynamic Logo Design Services, We Have Meritoriously Served Clients Emanating From Every Industry"
          topics={topics}
          listItems={[
            "Accounting",
            "Agriculture & Construction",
            "Automative & Transportation",
            "Clearing & Maintainance",
            "Computer & Technology",
            "Education",
            "Fashion",
            "Industrial Logo Design",
            "Photography",
            "Restaurants",
            "Sports",
            "Technology",
            "Argricultural",
            "Art & Culture",
            "Bussiness Consulting",
            "Communications",
            "Constructing tools",
            "Entertainment",
            "Floral Plans",
            "Landscaping Logo Design",
            "Physical Fitness",
            "Real Estate",
            "Spa-ethistic",
            "Wedding Services",
            "Animal & Pets",
            "Attorney & Law",
            "Child Care",
            "Community",
            "Cosmatics & Beauty",
            "Food & Beverages",
            "Medical & Phrmaceutical",
            "Security",
            "Traval % Hotel",
            "Enviromental",
            "Real State & Mortgage",
            "Food & Drinks",
          ]}
        />
        <Box width={"99vw"}>
          <OverviewLogoDesignStappers
            title={"Our Seamless Logo Designing Process"}
            subtitle={"You Relax, While We Do All The Work. It`S This Simple."}
            steps={steps}
          />
        </Box>
        <Box sx={{
          boxShadow: containerWidth === "initial" ? "0px 5px 20px rgba(0, 0, 0.2, 0.2)":"none",
          marginBottom: containerWidth === "initial" ? "5rem":"0rem",
          padding: "0rem",
        }}>
          <SelectUs
            buttonText1={"Select Package"}
            buttonText2={"Live chat"}
            onClickVoid={handlePackages}
            title={"Ready To Select Your Package ?"}
            subtitle={"Or Talk To Our Design Consultant"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "white",
            paddingBottom: "3rem",
            width: containerWidth
          }}
        >
          <Grid container spacing={3} sx={{ width: "80%" }}>
            {/* First Grid Item */}
            <Grid item xs={12} md={6}>
              {/* Image */}
              <img
                src="https://www.uptownlogodesign.com/assets/images/website-design-lp/launch-img.png"
                alt="Launch Image"
                style={{ width: "80%", borderRadius: "8px" }}
              />
            </Grid>
            {/* Second Grid Item */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#990304",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "2rem",
                  }}
                >
                  Launch & Training
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#292323",
                    fontWeight: "400",
                    fontSize: "1.2rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Launch To a Host of Your Choice & Get Free Training
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#71706E",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontFamily: "Poppins",
                    fontWeight: "300",
                  }}
                >
                  We launch your completed website onto your server. Our web
                  development team will take care of the entire process, and
                  once your site is live we train you on how to manage it. You
                  can edit, change and optimize your site to ensure it stays up
                  to date for future use.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ width: "80%" }}>
            {/* Third Grid Item */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10rem",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#990304",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "2rem",
                  }}
                >
                  About lead Design
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#292323",
                    fontWeight: "400",
                    fontSize: "1.2rem",
                    fontFamily: "Poppins",
                  }}
                >
                  We Trigger Business Evolution
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#71706E",
                    fontSize: "1rem",
                    marginTop: "1rem",
                    fontWeight: "300",
                    fontFamily: "Poppins",
                  }}
                >
                  Digital Design Agency Since 2010, we specialize in Logo
                  Designs, Websites, Animations and Applications. We believe in
                  the customized process, we customize niche specific designs
                  which triggers your target audience to build a relationship
                  with you. We believe in maintaining brilliance throughout our
                  process. Trust, effective communication, vigorous thinking,
                  hard work with a dash of fun!
                </Typography>
              </Box>
            </Grid>
            {/* Fourth Grid Item */}
            <Grid item xs={12} md={6}>
              {/* Image */}
              <img
                src="https://www.uptownlogodesign.com/assets/images/website-design-lp/about-img.png"
                alt="About Uptown Image"
                style={{
                  width: "80%",
                  borderRadius: "8px",
                  marginTop: "10rem",
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Reviews
          subtitle={
            "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
          }
          heading={"Look What Our Customers Have Said About Us"}
          paragraphs={[
            "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something ",
            "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
          ]}
          reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}
        />
        <RegistrationForm />
      </Box>
    </>
  );
};

export default OverviewWebsite;
