import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import OverviewSection from "../../components/OverviewSection";

// Define styles for the Accordion component
const styles = {
  root: {
    boxShadow: "none",
    borderRadius: 0,
    padding: "16px",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
};

const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "160%" : "inital";


const FaqsWebsite = () => {
  return (
    <Box sx={{ height: "100%", width: "100%", marginTop: "160px" }}>
      <OverviewSection
        title1="Everything You Need To Know"
        subTitle="Find Answers To Your Queries Or Contact Us!"
        subTitleColor="black"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/faqs.jpg"
        listItems={[
          "Clarify your Queries",
          "Connect with Our Team",
          "24/7 Support",
          "Get Your Project Done",
        ]}
        reverseLayout={true}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        padding={8}
        width={containerWidth}
      >
        {/* Accordion 1 */}
        <Accordion
          sx={{
            ...styles.root,
            // width: "75%",
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
            borderTop: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How long does it take to build a website?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              The time varies from project to project. It might take 15 days to
              120 days or even more to create a website depending on the
              complexity of the website as well as the revisions needed by the
              client.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root, width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How much does the website cost?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              We provide you with different packages.
              <ul>
                <li>
                  Budget: Starts from $325 and provides a hassle free design
                  solution for startups.
                </li>
                <li>
                  Best Seller: Starts from $550 and provides smart design
                  solution for professionals.
                </li>
                <li>
                  The Ultimate Starts from $789 and provides cutting-edge design
                  solution for all businesses.
                </li>
                {/* Add more list items as needed */}
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root, width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Can I see my website while it’s in progress?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              Of course you can! While working on the website, we provide you
              with a username and a password so that you can check the progress
              of your website. Your feedback and suggestions are always welcomed
              as we truly believe in satisfying our clients.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root, width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Can I make changes to my site without additional cost?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              You will be given multiple themes by our designers. You will
              select a specific theme for your website and then we will make
              revisions according to your requirements while its under
              construction.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root, width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Can you help me update my existing website that another web firm
              built?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              Yes. We will help revamp your website from head to toe as per your
              requirement and market trend.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root,width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              When will I get the copyrights of my website?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              Once the development of your website is done, we deliver a
              complete website to you and transfer the copyrights.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root, width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Do you write content for the website?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              Yes. Our creative team will write the content suitable for your
              website upon your requirement.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root, width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Do I own my website?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              Definitely! Your website will be owned by you by all means. But if
              you decide to take your site to another hosting service, our team
              will assist you in doing that in the most effortless and efficient
              way.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root,width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              What are the benefits of a Website?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}

            >
              We live in a world surrounded by technology. For any service or
              products the customers tend to search the suppliers through
              different platforms. Website helps you in supporting your business
              through its online presence and also supports in establishing your
              brand’s identity.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ ...styles.root, width: {xs: "none", sm: "75%", md: "85%"}, borderBottom: "3px solid #292323" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h4"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
              fontFamily={"Poppins"}

            >
              What is web hosting?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily={"Poppins"}
            >
              It is where your unique domain name is located and where the
              website it hosted, allowing online access to your website 24/7
              typically done on asp or php.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default FaqsWebsite;
