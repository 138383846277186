import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import LogoDesign from "./pages/LogoDesign";
import { About } from "./supportPages/About";
import { Contact } from "./supportPages/Contact";
import { Reviews } from "./supportPages/Reviews";
import { Blog } from "./supportPages/Blog";
import Website from "./pages/Website";
import Branding from "./pages/Branding";
import Animations from "./pages/Animations";
import OverviewLogoDesign from "./pages/componentsPage/OverviewLogoDesign";
import WhyChooseUsLogoDesign from "./pages/componentsPage/WhyChooseUsLogoDesign";
import LogoProcess from "./pages/componentsPage/LogoProcessLogoDesign";
import PortfolioLogoDesign from "./pages/componentsPage/PortfolioLogoDesign";
import PackagesLogoDesgin from "./pages/componentsPage/PackagesLogoDesgin";
import ReviewsLogoDesign from "./pages/componentsPage/ReviewsLogoDesign";
import FaqsLogoDesign from "./pages/componentsPage/FaqsLogoDesign";
import Logo101LogoDesign from "./pages/componentsPage/Logo101LogoDesign";
import OurGuaranteeLogoDesign from "./pages/componentsPage/OurGuaranteeLogoDesign";
import OverviewWebsite from "./pages/componentsPage/OverviewWebsite";
import WhyChooseUsWebsite from "./pages/componentsPage/WhyChooseUsWebsite";
import WebsiteProcessWebsite from "./pages/componentsPage/WebsiteProcessWebsite";
import PortfolioWebsite from "./pages/componentsPage/PortfolioWebsite";
import PackagesWebsite from "./pages/componentsPage/PackagesWebsite";
import ReviewsWebsite from "./pages/componentsPage/ReviewsWebsite";
import FaqsWebsite from "./pages/componentsPage/FaqsWebsite";
import Logo101Website from "./pages/componentsPage/Logo101Website";
import OurGuaranteeWebsite from "./pages/componentsPage/OurGuaranteeWebsite";
import OverviewBranding from "./pages/componentsPage/OverviewBranding";
import WhyChooseUsBranding from "./pages/componentsPage/WhyChooseUsBranding";
import BrandingProcessBranding from "./pages/componentsPage/BrandingProcessBranding";
import PortfolioBranding from "./pages/componentsPage/PortfolioBranding";
import PackagesBranding from "./pages/componentsPage/PackagesBranding";
import ReviewsBranding from "./pages/componentsPage/ReviewsBranding";
import FaqsBranding from "./pages/componentsPage/FaqsBranding";
import Logo101Branding from "./pages/componentsPage/Logo101Branding";
import OurGuaranteeBranding from "./pages/componentsPage/OurGuaranteeBranding";
import IconicLogoDesign from "./components/subPagesForOverview/IconicLogoDesign";
import Footer from "./components/Footer";
import MobileApps from "./pages/MobileApps";
import Packages from "./pages/Packages";



const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <NavBar />
        <Routes>
          
        <Route path="/" element={<Navigate to="/logo-design/overview" />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/branding" element={<Branding />} />
          <Route path="/animations" element={<Animations />} />
          <Route path="/mobile-apps" element={<MobileApps />} />
          <Route path="/packages" element={<Packages />} />
          {/* Route for Logo Design page */}
          <Route path="/logo-design" element={<LogoDesign />}>
            <Route index element={<OverviewLogoDesign />} />
            <Route path="overview" element={<OverviewLogoDesign />} />
            <Route path="why-choose-us" element={<WhyChooseUsLogoDesign />} />
            <Route path="logo-process" element={<LogoProcess />} />
            <Route path="portfolio" element={<PortfolioLogoDesign />} />
            <Route path="packages" element={<PackagesLogoDesgin />} />
            <Route path="reviews" element={<ReviewsLogoDesign />} />
            <Route path="faqs" element={<FaqsLogoDesign />} />
            <Route path="logo-101" element={<Logo101LogoDesign />} />
            <Route path="our-guarantee" element={<OurGuaranteeLogoDesign />} />
            {/* <Route path= "Iconic-Logo-Design" element={<IconicLogoDesign />} /> */}
          </Route>
          {/* Route for Website page */}
          <Route path="/website" element={<Website />}>
            <Route index element={<OverviewWebsite />} />
            <Route path="overview" element={<OverviewWebsite />} />
            <Route path="why-choose-us" element={<WhyChooseUsWebsite />} />
            <Route path="website-process" element={<WebsiteProcessWebsite />} />
            <Route path="portfolio" element={<PortfolioWebsite />} />
            <Route path="packages" element={<PackagesWebsite />} /> 
            <Route path="reviews" element={<ReviewsWebsite />} /> 
            <Route path="faqs" element={<FaqsWebsite />} /> 
            <Route path="website-101" element={<Logo101Website />} /> 
            <Route path="our-guarantee" element={<OurGuaranteeWebsite />} /> 
          </Route>
          <Route path="/branding" element={<Branding />}>
            <Route index element={<OverviewBranding />} />
            <Route path="overview" element={<OverviewBranding />} />
            <Route path="why-choose-us" element={<WhyChooseUsBranding />} />
            <Route path="branding-process" element={<BrandingProcessBranding />} />
            <Route path="portfolio" element={<PortfolioBranding />} />
            <Route path="packages" element={<PackagesBranding />} />
            <Route path="reviews" element={<ReviewsBranding />} />
            <Route path="faqs" element={<FaqsBranding />} />
            <Route path ="branding-101" element={<Logo101Branding />} />
            <Route path="our-guarantee" element={<OurGuaranteeBranding />} />
          </Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

