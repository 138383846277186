// import React from "react";
// import { Box, Typography } from "@mui/material";

// import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
// import PublicIcon from "@mui/icons-material/Public";
// import { cursorTo } from "readline";

// const Footer = () => {
//   const listsData = [
//     {
//       title: "Resources",
//       items: [
//         "Home",
//         "About us",
//         "Contact Us",
//         "Reviews",
//         "Refunds and Dispute",
//       ],
//     },
//     {
//       title: "Logo Design",
//       items: [
//         "Logo Portfolio",
//         "Logo Packages",
//         "Logo Reviews",
//         "Logo FAQs",
//         "Logo Guides 101",
//       ],
//     },
//     {
//       title: "Website Design",
//       items: [
//         "Website Portfolio",
//         "Website Packages",
//         "Website Reviews",
//         "Website FAQs",
//         "Website Guides 101",
//       ],
//     },
//     {
//       title: "Branding Design",
//       items: [
//         "Branding Portfolio",
//         "Branding Packages",
//         "Branding Reviews",
//         "Branding FAQs",
//         "Branding Guides 101",
//       ],
//     },
//     {
//       title: "Other Services",
//       items: [
//         "Animated Logo Design",
//         "Stationery Design",
//         "Video Animations",
//         "Mobile Applications",
//         "Typography",
//       ],
//     },
//     {
//       title: "Quick Links",
//       items: ["My Account", "Sign up", "  Sitemap", "Sign In", "FAQ"],
//     },
//   ];

//   return (
//     <Box
//       sx={{
//         width: containerWidth,
//         position: "sticky",
//         backgroundColor: "#292323",
//         padding: "20px",
//         textAlign: "center",
//       }}
//     >
//       <Box display="flex" flexDirection="column" alignItems="center">
//         <Box mb={4}>
//           <img
//             src="/assets/footer.jpg"
//             alt="Logo"
//             style={{ width: "10%", height: "10%" }}
//           />
//         </Box>

//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           flexDirection="row"
//           flexWrap="wrap"
//         >
//           <Box mb={4}>
//           <img
//             src="/assets/footer.jpg"
//             alt="Logo"
//             style={{ width: "10%", height: "10%" }}
//           />
//         </Box>
//           {listsData.map((list, index) => (
//             <Box
//               key={index}
//               display={{ xs: "none", md: "flex" }}
//               justifyContent="center"
//               alignItems="center"
//               flexDirection="column"
//               padding="2rem"
//             >
//               <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
//                 <Typography
//                   component="span" // Add component prop
//                   variant="subtitle1"
//                   color="white"
//                   bgcolor="#990304"
//                   padding="1px 5px 5px 5px"
//                   fontSize="1.5rem"
//                   marginRight="10px"
//                   fontFamily="Poppins"
//                   // sx={{ textDecoration: "underline" }} // Use sx prop for inline styling
//                 >
//                   {list.title}
//                 </Typography>
//                 {list.items.map((item, itemIndex) => (
//                   <li key={itemIndex}>
//                     <Typography
//                       component="span" // Add component prop
//                       variant="subtitle1"
//                       color="white"
//                       fontSize="1.2rem"
//                       fontFamily= "Poppins"
//                       onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {

//                         // Specify type for 'e'
//                         const target = e.target as HTMLElement;
//                         target.style.color = "#990304";
//                         target.style.cursor = "pointer";
//                       }}
//                       onMouseLeave={(e: React.MouseEvent<HTMLSpanElement>) => {
//                         // Specify type for 'e'
//                         const target = e.target as HTMLElement;
//                         target.style.color = "white";
//                         target.style.cursor = "pointer";
//                       }}
//                     >
//                       {item}
//                     </Typography>
//                   </li>
//                 ))}
//               </ul>
//             </Box>
//           ))}
//         </Box>
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           flexDirection="column"
//           sx={{
//             marginTop: "20px",
//             backgroundColor: "#990304",
//             padding: "20px",
//           }}
//         >
//           <Box
//             display="grid"
//             gridTemplateColumns={containerWidth !== "inital" ? "none" : "5fr 5fr "}
//             gap= "2rem"
//             width="100%"
//             maxWidth="100%"
//             margin="0 auto"
//           >
//             <Box>
//               <Typography variant="body1" color="white" textAlign={"center"} justifyContent={"center"} display={"flex"} fontFamily= "Poppins" >
//                 Copyright © 2024 Uptown Logo Design | All rights reserved. Terms
//                 And Conditions | Privacy Policy
//               </Typography>
//             </Box>
//             <Box>
//               <img
//                 src="/assets/footer1.png"
//                 alt="Image"
//                 style={{ width: "50%", height: "50" }}
//               />
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// const ContactItem = ({
//   icon,
//   text,
// }: {
//   icon: React.ReactNode;
//   text: string;
// }) => (
//   <Box mr={4} mb={2}>
//     {icon}
//     <Typography
//       variant="subtitle1"
//       color="#564c4d"
//       sx={{ fontSize: "1.3rem", ml: "5px" }}
//     >
//       {text}
//     </Typography>
//   </Box>
// );

// export default Footer;

// <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           // flexDirection={containerWidth !== "inital" ? "column" : "row"}
//         >
//           <Typography
//             component="span" // Add component prop
//             variant="subtitle1"
//             style={{
//               display: "flex",
//               alignItems: "center",
//               color: "white",
//               fontSize: "1.3rem",
//               marginRight: "50px",
//               fontFamily: "Poppins",
//             }}
//           >
//             <CallRoundedIcon
//               sx={{ fontSize: "1.5rem", color: "#990304", marginRight: "10px" }}
//             />{" "}
//             123-456-7890
//           </Typography>
//           <Typography
//             component="span" // Add component prop
//             variant="subtitle1"
//             style={{
//               display: "flex",
//               alignItems: "center",
//               color: "white",
//               fontSize: "1.3rem",
//               marginRight: "50px",
//               fontFamily: "Poppins",
//             }}
//           >
//             <EmailRoundedIcon sx={{ fontSize: "1.5rem", color: "#990304",marginRight: "10px" }} />{" "}
//             info@league_design.com
//           </Typography>
//           <Typography
//             component="span" // Add component prop
//             variant="subtitle1"
//             style={{
//               display: "flex",
//               alignItems: "center",
//               color: "white",
//               fontSize: "1.3rem",
//               marginRight: "50px",
//               fontFamily: "Poppins",
//             }}
//           >
//             <LocationOnRoundedIcon
//               sx={{ fontSize: "1.5rem", color: "#990304",marginRight: "10px" }}
//             />{" "}
//             5042 Wilshire Blvd #43900 Los Angeles, CA 90036
//           </Typography>
//           <Box hidden component="div">
//             {" "}
//             {/* Fix hidden prop */}
//             <ContactItem
//               icon={
//                 <>
//                   <FacebookRoundedIcon />
//                   <PublicIcon />
//                   <FacebookRoundedIcon />
//                   <PublicIcon />
//                 </>
//               }
//               text=""
//             />
//           </Box>
//         </Box>

import { Box, Typography } from "@mui/material";
import React from "react";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../Assets/main logo/leaguelogo.png"
import footer from "../Assets/background/footer1.png"

const Footer = () => {
  const containerWidth =
    window.innerWidth >= 290 && window.innerWidth <= 920 ? "210%" : "inital";

  return (
    <Box width={containerWidth}>
      <footer
        style={{ backgroundColor: "black", color: "white", padding: "20px" }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flex: "1", width: "100%", padding: "10px" }}>
            <img
              src={logo}
              alt="Your Logo"
              style={{ maxWidth: "100%" }}
            />
            <Typography
              variant="h6"
              textAlign={"start"}
              fontFamily={"Poppins"}
              fontSize={"0.8rem"}
              mt={2}
              mx={2}
            >
              {" "}
              We believe in the customized process, we customize niche specific
              designs which triggers your target audience to build a
              relationship with you. We believe in maintaining brilliance
              throughout our process
            </Typography>
          </div>
          <div
            style={{
              flex: "1",
              width: "25%",
              padding: "10px",
              color: "white",
              paddingLeft: "150px",
              fontSize: "1.5rem",
              fontFamily: "Poppins",
            }}
          >
            <h4 style={{ fontSize: "1.5rem" }}>Useful Links</h4>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li>
                <a
                  href="/logo-design/overview"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="/reviews"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Reviews
                </a>
              </li>
            </ul>
          </div>
          <div
            style={{
              flex: "1",
              width: "25%",
              padding: "10px",
              fontSize: "1.5rem",
              paddingRight: "100px",
            }}
          >
            <h4 style={{ fontSize: "1.5rem" }}>Our Services</h4>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li>
                <a
                  href="/logo-design/overview"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1.2rem",
                  }}
                >
                  Logo Design
                </a>
              </li>
              <li>
                <a
                  href="/wrbsite/overview"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Website
                </a>
              </li>
              <li>
                <a
                  href="/branding/overview"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Branding
                </a>
              </li>
              <li>
                <a
                  href="/animations/overview"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Animation
                </a>
              </li>
              <li>
                <a
                  href="/mobile-apps/overview"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Moblile Apps
                </a>
              </li>
            </ul>
          </div>
          <div
            style={{
              flex: "1",
              width: "25%",
              padding: "10px",
              fontSize: "2rem",
              paddingRight: "100px",
            }}
          >
            <h4 style={{ fontSize: "1.5rem" }}>Connect With Us</h4>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li>
                <div
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  <CallRoundedIcon
                    sx={{
                      fontSize: "1.2rem",
                      color: "#990304",
                      marginRight: "15px",
                      alignSelf: "center",
                    }}
                  />
                  123-456-7890{" "}
                </div>
              </li>
              <li>
                <div
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  <LocationOnRoundedIcon
                    sx={{
                      fontSize: "1.5rem",
                      color: "#990304",
                      marginRight: "5px",
                    }}
                  />
                  5042 Wilshire Blvd Los Angeles,
                </div>
              </li>
              <li>
                <div
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "1.2rem",
                    marginTop: "10px",
                    marginRight: "15px",

                  }}
                >
                  Follow Us <br />
                  <FacebookRoundedIcon
                    sx={{
                      fontSize: "2.0rem",
                      color: "#990304",
                      marginRight: "15px",
                    }}
                  />
                  <InstagramIcon
                    sx={{
                      color: "#990304",
                      fontSize: "2.0rem",
                      marginRight: "10px",
                    }}
                  />
                  <XIcon
                    sx={{
                      color: "#990304",
                      fontSize: "2.0rem",
                      marginRight: "10px",
                    }}
                  />
                  <LinkedInIcon sx={{ color: "#990304", fontSize: "2.0rem" }} />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            marginTop: "20px",
            backgroundColor: "#990304",
            padding: "20px",
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns={
              containerWidth !== "inital" ? "none" : "5fr 5fr "
            }
            gap="5rem"
            width="100%"
            maxWidth="100%"
            margin="0 auto"
          >
            <Box
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
              <Typography
                variant="body1"
                color="white"
                textAlign={"center"}
                justifyContent={"center"}
                display={"flex"}
                fontFamily="Poppins"
                fontSize={"0.8rem"}
              >
                Copyright © 2024 Uptown Logo Design | All rights reserved. Terms
                And Conditions | Privacy Policy
              </Typography>
            </Box>
            <Box
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
              <img
                src= {footer}
                alt="Image"
                style={{ width: "40%", height: "100%" }}
              />
            </Box>
          </Box>
        </Box>
      </footer>
    </Box>
  );
};

export default Footer;
