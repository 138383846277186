import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import styled, { keyframes } from "styled-components";
import slideimage1 from "../Assets/BrandImg/01.png"
import slideimage2 from "../Assets/BrandImg/02.png"
import slideimage3 from "../Assets/BrandImg/03.png"
import slideimage4 from "../Assets/BrandImg/04.png"
import slideimage5 from "../Assets/BrandImg/05.png"
import slideimage6 from "../Assets/BrandImg/06.png"
import slideimage7 from "../Assets/BrandImg/07.png"
import slideimage8 from "../Assets/BrandImg/08.png"
import slideimage9 from "../Assets/BrandImg/09.png"



interface OverviewSectionProps {
  title1?: string;
  title2?: string;
  subTitle?: string;
  listItems?: string[];
  imageUrl?: string;
  imageUrl2?: string;
  bgImage?: string;
  bgImage2?: string;
  titleColor?: string;
  subTitleColor?: string;
  setPaddingY?: string;
  runEffect?: boolean;
  reverseLayout?: boolean;
}

const slideAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-1000px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const AnimatedTypography = styled(Typography)`
  opacity: 0;
  transform: translateX(-100px);
  animation: ${slideAnimation} 1s ease forwards;
`;

const OverviewSection: React.FC<OverviewSectionProps> = ({
  bgImage,
  bgImage2,
  title1 = "",
  title2 = "",
  subTitle,
  titleColor = "#292323",
  subTitleColor = "#990304",
  listItems,
  imageUrl,
  imageUrl2,
  setPaddingY = 10,
  reverseLayout,
  runEffect,
}) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sectionWidth = width >= 300 && width <= 800 ? "210%" : "100%";

  useEffect(() => {
    const section = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update state based on whether the component is in the viewport
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.5, // Trigger when at least 50% of the component is visible
      }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  const imagesBoxRef = useRef<HTMLDivElement>(null);
  const [firstTitle, setFirstTitle] = useState(title1);
  const [secondTitle, setSecondTitle] = useState(title2);
  const [FirstImage, setFirstImage] = useState(imageUrl);
  const [SecondImage, setSecondImage] = useState(imageUrl2);
  const [firstBgImage, setfirstBgImage] = useState(bgImage);
  const [secondBgImage, setSeconfBgImage] = useState(bgImage2);

  useEffect(() => {
    if (!runEffect) return;
    let showContent = !reverseLayout; // Initialize toggle based on reverseLayout prop
    const interval = setInterval(() => {
      // Toggle between showing title and subtitle
      if (showContent) {
        setFirstTitle(reverseLayout ? title1 : title2);
        setSecondTitle("");
        setFirstImage(reverseLayout ? imageUrl2 : imageUrl);
        setSecondImage(""); // Ensure the second image is set to an empty string
        setfirstBgImage(reverseLayout ? bgImage2 : bgImage);
        setSeconfBgImage(""); // Ensure the second background image is set to an empty string
      } else {
        setFirstTitle("");
        setSecondTitle(reverseLayout ? title2 : title1);
        setFirstImage(reverseLayout ? imageUrl : imageUrl2);
        setSecondImage(reverseLayout ? imageUrl2 : imageUrl); // Set the second image based on reverseLayout
        setfirstBgImage("");
        setSeconfBgImage(reverseLayout ? bgImage : bgImage2); // Set the second background image based on reverseLayout
      }
      showContent = !showContent; // Toggle the state for the next iteration
    }, 5000);

    return () => clearInterval(interval);
  }, [
    title1,
    title2,
    imageUrl,
    imageUrl2,
    bgImage,
    bgImage2,
    reverseLayout,
    runEffect,
  ]);

  useEffect(() => {
    const imagesBox = imagesBoxRef.current;

    if (!imagesBox) return; // Check if imagesBox is null, and return early if it is

    let scrollInterval: NodeJS.Timeout | undefined; // Declare scrollInterval variable

    const scrollImagesBox = () => {
      const scrollDistance = imagesBox.scrollWidth - imagesBox.clientWidth;
      let scrollPosition = 0;
      scrollInterval = setInterval(() => {
        // Increment the scroll position
        scrollPosition += 1;
        // Reset to the beginning if reached the end
        if (scrollPosition >= scrollDistance) {
          scrollPosition = 0;
        }
        // Scroll to the new position
        imagesBox.scrollTo(scrollPosition, 0);
      }, 15); // Increased interval for slower scrolling (adjust as needed)

      return () => clearInterval(scrollInterval); // Return the clearInterval function
    };

    // Call the scrollImagesBox function to start scrolling
    scrollImagesBox();

    // Cleanup function to remove the scrollInterval
    return () => {
      if (scrollInterval) clearInterval(scrollInterval);
    };
  }, []);

  const screenWidth = window.innerWidth;

  return (
    <>
      <Box
        sx={{
          overflowX: "hidden",
        }}
        ref={sectionRef}
        width={sectionWidth}
      >
        <Box
          sx={{
            // backgroundColor: "white",
            backgroundImage: `url(${
              firstBgImage && !secondBgImage ? firstBgImage : secondBgImage
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            p: 3,
            borderRadius: 1,
            boxShadow: 5,
            mt: 0.5,
            boxSizing: "border-box",
            paddingY: setPaddingY || 10,
            width: "100%",
            paddingLeft: { xs: "5rem", md: "initial" },
          }}
        >
          <Box
            sx={{
              display: screenWidth >= 1280 ? "grid" : "flex", // Use grid on large screens (>= 1280px), flex on others
              gridTemplateColumns: reverseLayout ? "1fr 1fr" : "1fr 1fr",
              gap: "5rem",
              marginLeft:
                screenWidth >= 1280
                  ? reverseLayout
                    ? "15rem"
                    : "15rem"
                  : "2rem",
              marginRight: reverseLayout ? "auto" : "15rem",
            }}
          >
            {!reverseLayout && ( // Render image first if reverseLayout is false
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "5rem",
                }}
              >
                <img
                  src={FirstImage}
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </Box>
            )}
            <Box
              ref={sectionRef}
              className={isVisible ? "visible" : ""}
              sx={{
                paddingLeft: "1rem",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <AnimatedTypography
                variant="h1"
                style={{
                  fontSize: "2.5rem",
                  marginBottom: "0.5rem",
                  color: titleColor,
                  fontWeight: "600",
                  fontFamily: "Poppins ",
                }}
              >
                {" "}
                {firstTitle && !secondTitle && firstTitle}
                {secondTitle && !firstTitle && secondTitle}{" "}
              </AnimatedTypography>
              {subTitle && (
                <AnimatedTypography
                  variant="h6"
                  style={{
                    marginBottom: "1rem",
                    color: subTitleColor,
                    fontSize: "1.2rem",
                    fontFamily: "Poppins",
                  }}
                >
                  {subTitle}
                </AnimatedTypography>
              )}
              <ul style={{ listStyle: "none", padding: 0 }}>
                {listItems &&
                  listItems.map((item, index) => (
                    <li
                      key={index}
                      style={{
                        marginBottom: "0.5rem",
                        color: "#71706E",
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#990304",
                          marginRight: "1rem",
                        }}
                      ></span>
                      {item}
                    </li>
                  ))}
              </ul>
            </Box>
            {reverseLayout && ( // Render image last if reverseLayout is true
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "5rem",
                }}
              >
                <img
                  src={SecondImage}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          ref={imagesBoxRef}
          // width={sectionWidth}
          sx={{
            height: "100px",
            overflowX: "auto",
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            boxSizing: "border-box",
            // Hide scrollbar
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: "15rem",
            }}
          >
            <img
              src= {slideimage1}
              alt="Slider Image 1"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src= {slideimage2}
              alt="Slider Image 2"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src= {slideimage3}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src= {slideimage4}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src= {slideimage4}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src= {slideimage5}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src= {slideimage6}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src={slideimage7}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src={slideimage7}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src={slideimage8}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "5rem" }}
            />
            <img
              src={slideimage9}
              alt="Slider Image 3"
              style={{ maxWidth: "80%", height: "auto", marginRight: "15rem" }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OverviewSection;
