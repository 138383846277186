import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OverviewLogoDesign from './componentsPage/OverviewLogoDesign';
import WhyChooseUsLogoDesign from './componentsPage/WhyChooseUsLogoDesign';
import LogoProcessLogoDesign from './componentsPage/LogoProcessLogoDesign'; 
import PortfolioLogoDesign from './componentsPage/PortfolioLogoDesign';
import PackagesLogoDesgin from './componentsPage/PackagesLogoDesgin';
import ReviewsLogoDesign from './componentsPage/ReviewsLogoDesign';
import FaqsLogoDesign from './componentsPage/FaqsLogoDesign';
import Logo101LogoDesign from './componentsPage/Logo101LogoDesign';
import OurGuaranteeLogoDesign from './componentsPage/OurGuaranteeLogoDesign';

const LogoDesign = () => {
  return (
    <div>
      {/* <ComponentBar /> */}
      
      <Routes>
        <Route path='/' element={<OverviewLogoDesign />} />
        <Route path="/overview" element={<OverviewLogoDesign />} />
        <Route path="/why-choose-us" element={<WhyChooseUsLogoDesign />} />
        <Route path="/logo-process" element={<LogoProcessLogoDesign />} /> 
        <Route path="/portfolio" element={<PortfolioLogoDesign />} /> 
        <Route path="/packages" element={<PackagesLogoDesgin />} />
        <Route path="/reviews" element={<ReviewsLogoDesign />} />
        <Route path="/faqs" element={<FaqsLogoDesign />} />
        <Route path="/logo-101" element={<Logo101LogoDesign />} />
        <Route path="/our-guarantee" element={<OurGuaranteeLogoDesign />} />
      </Routes>
    </div>
  );
};

export default LogoDesign;
