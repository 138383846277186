import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewSection from "../../components/OverviewSection";
import rattingImg from "../../Assets/reviwesImg/rating.png"

const ReviewsWebsite = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const reviews = [
    {
      imageUrl:
        "https://www.uptownlogodesign.com/assets/images/testi-author-01.jpg",
      text: "The last time I picked a brand after searching through web design company reviews it left me unsatisfied. However, this time it has been totally opposite. UptownLogoDesign has given my website a brand-new life. Keep up the good work guys!",
    },
    {
      imageUrl:
        "https://www.uptownlogodesign.com/assets/images/testi-author-02.jpg",
      text: "This is my first project with UptownLogoDesign and I cannot be happier. They are literally the best designing agency I have ever worked with, and trust me I have worked with a lot of them. Giving them five stars on being best custom logo designers..",
    },
    {
      imageUrl:
        "https://www.uptownlogodesign.com/assets/images/testi-author-03.jpg",
      text: "I believe I wanted the most complicated logo design ever. So, I searched through logo design guarantee reviews to look for a reliable company. I picked a few firms but finally decided on UptownLogoDesign. To my surprise they really designed the logo as it was asked. Rather did a better job than I expected actually. Well they truly are legends..",
    },
    // Add more review objects here
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) =>
        prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [reviews.length]);

  const currentReview = reviews[currentReviewIndex];

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "190%" : "inital";


  return (
    <>
      <Box width={"100%"} height={"100%"} marginTop={containerWidth === "inital" ? "150px" : "0"}>
        <OverviewSection
          title1="Testimonials From
      Our Clients"
      titleColor="White"
          subTitle="Our Team’s Utmost Dedication, Commitment, And Hard Work Is The Secret Behind Us Having The Best Website Design Company Reviews In The USA"
          subTitleColor="White"
          bgImage="https://www.uptownlogodesign.com/assets/images/banners/webdesign-reviewbann.jpg"
          reverseLayout={true}
        />
        <Box
          display={"flex"}
          flexDirection={"column"} // Change to column direction
          justifyContent={"center"}
          alignItems={"center"}
          width={containerWidth}
        >
          <Typography
            variant="h3"
            color={"#990304"}
            marginTop={"50px"}
            fontSize={"2rem"}
            fontWeight={"600"}
            fontFamily={"Poppins"}
          >
            Designed The Journey Of 50,000+ Happy Clients And Growing
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#71706E"}
            fontSize={"1rem"}
            marginTop={"10px"}
            fontFamily={"Poppins"}
          >
            Read our web design company reviews from our valuable clients to see
            how Uptown transformed their magical design journey.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.5rem"}
            marginTop={"35px"}
            fontWeight={500}
            fontFamily={"Poppins"}
          >
            Leat Logo Design Responsive Web Design Service
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#71706E"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            paddingX = {containerWidth !== "inital" ? "0rem" : "5rem"}
            fontFamily={"Poppins"}
          >
            Having a stunning website design from every aspect is a vital need
            to define a brand’s journey. Uptown’s qualified web designers ensure
            to build your brand’s image with a responsive website that
            captivates the eye of your target audiences. It is for this reason
            we wear the emblem of having the best website design company reviews
            in the USA.
            <br />
            <br />
            Uptown is a leading design agency that provides premium web design
            services to clients. Our proficient team of developers and designers
            ventures out to provide the best website designs based on the needs
            of the clients. Moreover, it strives to create a persona that
            matches with the image of your brand.
            <br />
            <br />
            Read into our best website design company reviews and understand how
            UptownLogoDesign benefits its clients through a stellar website
            design to fulfill their business needs.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.5rem"}
            marginTop={"35px"}
            fontWeight={500}
            fontFamily={"Poppins"}
          >
            What Benefits Do We Provide?
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#71706E"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            paddingX = {containerWidth !== "inital" ? "0rem" : "5rem"}
            fontFamily={"Poppins"}

          >
            Uptown’s interactive layout and design strategy offer custom
            functionality, responsiveness, and more growth opportunities to
            clients. Moreover, it also provides the following types of design
            benefits:
            <br />
            <br />
            Static Design- Uptown’s static website design services provide an
            instant boost to your brand’s sales and conversions.
            <br />
            <br />
            E-commerce Design- The skilled team of designers optimizes your
            business reach by providing interactive and easy interfaces.
            <br />
            <br />
            CMS Website- With complete control over your website’s management,
            Uptown offers professional CMS websites based on your requirements.
            <br />
            <br />
            B2B and B2C- The company provides effective B2B and B2C portals to
            expand your potential market by creating maximum business exposure.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.5rem"}
            marginTop={"35px"}
            fontWeight={500}
            fontFamily={"Poppins"}
          >
            About Leat Logo Design
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#71706E"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            paddingX = {containerWidth !== "inital" ? "0rem" : "5rem"}
            fontFamily={"Poppins"}

          >
            A decade earlier, Uptown started its revolutionary journey with
            great enthusiasm and high spirits. Now, the company has mainly
            expanded with more than 400+ skilled employees and various types of
            offerings to its clients.
            <br />
            <br />
            At present, the company holds a strong online reputation with the
            best logo design websites’ reviews. The clients trust Uptown for its
            commendable services and an in-depth understanding of various
            offered services. For more credibility, Uptown also receives the top
            web design site reviews in the USA.
            <br />
            <br />
            From beginning to end, Uptown’s expert designers and developers also
            ensure the smooth delivery of projects while gaining client’s
            satisfaction at every step of the design process.
            <br />
            <br />f you’re willing to take your design journey to the next
            level, hire Uptown’s creative team of designers to build your brand
            image through a responsive website design.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }} padding={10}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: "flex", justifyContent: "center", width:containerWidth !== "inital" ? "250%" : "100%" }}>
                <img
                  src={currentReview.imageUrl}
                  alt="logo"
                  style={{
                    width: "120px", // Set width
                    height: "120px", // Maintain aspect ratio
                    borderRadius: "100%", // Make it circular
                    border: "2px solid #990304", // Add border
                  }}
                />
                <Box
                  bgcolor={"#f1f1f1"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    width: "700px",
                    height: "100px", 
                    padding: "3rem",
                    overflow: "hidden"
                    
                  }}
                >
                  <Typography  variant="subtitle1"
                    sx={{
                      color: "#333",
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}>
                    {currentReview.text}
                  </Typography>
                  <img
                    src={rattingImg}
                    alt="ratting"
                    style={{
                      width: "100px", // Set width
                      height: "100px", // Maintain aspect ratio
                      alignItems: "start",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        
      </Box>
    </>
  );
};

export default ReviewsWebsite;
