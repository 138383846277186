import React, { useState, useRef, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography, Grid, Box, IconButton, styled } from '@mui/material';
import { useInView } from 'react-intersection-observer';


interface OverviewBenefitsProps {
  title?: string;
  subtitles?: string[];
  paragraphs?: string[];
  images?: string[];
}

const CustomIconButton = styled(IconButton)({
  '&:hover': {
    backgroundColor: 'transparent', // Remove the circle background on hover
    transform: 'scale(1.5)',
    transition: 'transform 0.3s ease',
  },
});

const AnimatedTypography: React.FC<any> = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <Typography ref={ref} style={{ 
      transition: 'transform 1s', // Adjusted transition duration to 5 seconds
      transform: inView ? 'none' : 'translateY(300px)',
      color: '#990304', 
      fontWeight: '600', 
      fontSize: inView ? '2.4rem' : '1.5rem',
      marginBottom: '8px',
      fontFamily: 'Poppins',
    }}>
      {children}
    </Typography>
  );
};

const OverviewBenefits: React.FC<OverviewBenefitsProps> = ({ title, subtitles = [], paragraphs = [], images = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState<number | null>(null);
  const iconsRef = useRef<any[]>([]);
  const [containerRef, containerInView] = useInView({ triggerOnce: true });
  const [animatedRefs, inView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Changed interval to 5000 milliseconds (5 seconds)
    return () => clearInterval(interval);
  }, [images.length]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleIconHover = (index: number) => {
    setIsHovered(true);
    setHoveredIcon(index);
  };

  const handleIconLeave = () => {
    setIsHovered(false);
    setHoveredIcon(null);
  };

  // Calculate the width dynamically based on the window width
  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "inital";

  return (
    <Box
      sx={{
        width: containerWidth, // Set width to 200% for widths between 300px and 800px
        display: 'flex',
        justifyContent: 'center',
        backgroundColor:"#F1F1F1",
      }}
    >
      <Box boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)" padding={10} boxSizing="border-box" ref={containerRef}   >
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={2} container justifyContent="center" alignItems="center" display={{ xs: 'none', sm: 'flex' }}>
            <CustomIconButton
              ref={(el) => (iconsRef.current[0] = el)}
              onClick={handlePrevious}
              disableRipple
              onMouseEnter={() => handleIconHover(0)}
              onMouseLeave={handleIconLeave}
            >
              <ArrowBackIosIcon style={{ color: isHovered && hoveredIcon === 0 ? '#990304' : '#990304', fontSize: '3rem'  }} />
            </CustomIconButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box ref={animatedRefs}>
              <AnimatedTypography>{title}</AnimatedTypography>
              <Typography variant="subtitle1" style={{ color: '#292323', fontWeight: 'bold', fontSize: '1.5rem', fontFamily: 'Poppins' }}>{subtitles[currentIndex]}</Typography>
              <Typography variant="body1" style={{ color: '#71706E', fontSize: '1.2rem',fontFamily: 'Poppins' }}>{paragraphs[currentIndex]}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box position="relative" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={images[currentIndex]} alt="Benefit" style={{ maxWidth: '100%', height: 'auto', transition: 'filter 0.5s' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} container justifyContent="center" alignItems="center" display={{ xs: 'none', sm: 'flex' }}>
            <CustomIconButton
              ref={(el) => (iconsRef.current[1] = el)}
              onClick={handleNext}
              disableRipple
              onMouseEnter={() => handleIconHover(1)}
              onMouseLeave={handleIconLeave}
            >
              <ArrowForwardIosIcon style={{ color: isHovered && hoveredIcon === 1 ? '#990304' : '#990304', fontSize: '5rem' }} />
            </CustomIconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};



export default OverviewBenefits;


