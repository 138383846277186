import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import bgimage from "../Assets/background/allPackBG.jpg"
import allpackImg from "../Assets/benefitsImages/logo-pic-10.png"

interface LeadDesignProps {
  title?: string;
  subtitle?: string;
  paragraph?: string;
  list?: string[];
  amount?: string;
}

const LeadDesignOverviewPack: React.FC<LeadDesignProps> = ({ title, subtitle, paragraph, list = [], amount }) => {
  const midpointIndex = Math.ceil(list.length / 2);
  const firstHalf = list.slice(0, midpointIndex);
  const secondHalf = list.slice(midpointIndex);

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "inital";


  return (
    <Box
      sx={{
        backgroundImage:`url(${bgimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '80vh', // Adjust the minimum height as needed
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: containerWidth,
      }}
    >
      <Grid container justifyContent="center" alignItems="center" sx={{ width: '90%' }}>
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: { xs: '20px', md: '40px' } }}>
            <Typography variant="h2" fontWeight="bold" fontFamily="Poppins" sx={{ color: '#990304', fontSize: { xs: '2.5rem', sm: '3rem', md: '3rem' } }}>{title}</Typography>
            <Typography variant="h4" fontWeight="450" fontFamily="Poppins" sx={{ color: '#edf5e1', fontSize: { xs: '1.8rem', sm: '2.5rem', md: '2.5rem' } }}>{subtitle}</Typography>
            <Typography variant="subtitle2" fontWeight="normal" sx={{ color: '#edf5e1', fontSize: { xs: '1rem', sm: '1.4rem', md: '1rem' }, marginTop: '10px' }}>{paragraph}</Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'row' }, marginTop: '10px' }}>
              <Box sx={{ flex: 1 }}>
                <ul>
                  {firstHalf.map((item, index) => (
                    <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <Typography variant="body1"fontFamily="Poppins" sx={{ marginRight: '5px', color: '#990304', fontSize: '1.2rem', fontWeight: 'bold' }}>{'\u2713'}</Typography>
                      <Typography variant="body1" fontFamily="Poppins" sx={{ color: 'white', fontSize: '1rem', fontWeight: '700' }}>{item}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
              <Box sx={{ flex: 2 }}>
                <ul>
                  {secondHalf.map((item, index) => (
                    <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <Typography variant="body1"fontFamily="Poppins" sx={{ marginRight: '10px', color: '#990304', fontSize: '1.2rem', fontWeight: 'bold' }}>{'\u2713'}</Typography>
                      <Typography variant="body1"fontFamily="Poppins" sx={{ color: 'white', fontSize: '1rem', fontWeight: '700' }}>{item}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
              <Typography variant="h1"fontFamily="Poppins" sx={{ color: '#fdc024', fontSize: { xs: '3rem', sm: '4rem', md: '4rem' }, fontWeight: 'bold', marginRight: { xs: '10px', md: '20px' } }}>{amount}</Typography>
              <Typography variant="h1" fontFamily="Poppins"sx={{ color: 'white', fontSize: '2rem', fontWeight: 'normal', textDecorationLine: 'line-through', marginRight: { xs: '10px', md: '20px' } }}>250</Typography>
              <Typography variant="h1"fontFamily="Poppins" sx={{ color: 'white', fontSize: '2rem', fontWeight: 'normal', marginRight: '20px' }}>80% Off</Typography>
              <Button variant="contained" sx={{ background: '#900304', borderRadius: '30px', height: { xs: '30px', md: '50px' }, width: '30%', fontSize: '1rem', fontWeight: 'bold' ,fontFamily:"Poppins" }}>Order Now</Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={allpackImg} alt="Other image" style={{ width: '100%', height: 'auto' }} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default LeadDesignOverviewPack;
