import React, { useEffect, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
} from "@mui/material";

interface CardData {
  title: string;
  paragraph: string;
  buttonText?: string;
}

interface OverViewDetailsProps {
  mainHeading: string;
  mainSubheading: string;
  cardsData: CardData[];
  onButtonClick: () => void;
}

const OverViewDetails: React.FC<OverViewDetailsProps> = ({
  mainHeading,
  mainSubheading,
  cardsData,
  onButtonClick,
}) => {
  const cardsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (cardsRef.current) {
        const cardsPosition = cardsRef.current.getBoundingClientRect().top;
        const screenHeight = window.innerHeight;

        if (cardsPosition < screenHeight) {
          // Cards are in view, add animation class
          cardsRef.current.classList.add("slide-in");
        }
      }
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "200%" : "inital";

  return (
    <Box
      sx={{
        width: containerWidth,
        backgroundColor: "#ebecee",
        paddingY: { xs: "3rem", sm: "4rem" ,md: "3rem" },
        paddingX: { xs: "1rem", sm: "4rem", md: "3rem" },
        overflowX: "auto", // Enable horizontal scrolling
      }}
    >
      <style>
        {`
          .slide-in {
            animation: slideIn 0.5s ease forwards;
            animation-delay: 0.1s; /* Add a 0.1s delay */
          }

          @keyframes slideIn {
            from {
              opacity: 0;
              transform: translateX(100%);
            }
            to {
              opacity: 1;
              transform: translateX(0);
            }
          }
        `}
      </style>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#990304",
            fontWeight: "600",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "2rem" },
            marginBottom: "0.5rem",
            fontFamily: "Poppins",
          }}
        >
          {mainHeading}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: "Poppins",
            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
            color: "#292323",
            marginBottom: "1rem",
          }}
        >
          {mainSubheading}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        ref={cardsRef}
        className="cards-container" // Add className for animation
      >
        {cardsData.map((data, index) => (
          <Grid item xs={12} sm={8} md={3} key={index}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: "10rem",
              }}
            >
              <Card
                sx={{
                  // color: "#990304",
                  width: "80%",
                  padding: { xs: "1rem", sm: "2rem" ,md: "1rem" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  transition: "background-color 0.5s",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor:
                      index === 0
                        ? "#F7AD31"
                        : index === 1
                        ? "#51C3F0"
                        : index === 2
                        ? "#D14055"
                        : index === 3
                        ? "#9BD85B"
                        : "none",
                    color: "white",
                    // transitionDelay: "0.5s",
                    transform: "scale(1.05)",
                  },
                }}
                className="slide-in-card" // Add className for animation
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: { xs: "1.5rem", sm: "1.2rem", md: "1.2rem" },
                      marginBottom: "1rem",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      color: "#990304",
                    }}
                  >
                    {data.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "1.5rem", sm: "1.2rem", md: "1.0rem" },
                      marginBottom: "1rem",
                      fontFamily: "Poppins",
                      fontWeight: "100",
                       color: "#292323",
                       "&:hover": {
                        color: "white", // Change text color to white on hover
                      },
                    }}
                  >
                    {data.paragraph}
                  </Typography>
                </CardContent>
                <Button
                  variant="text"
                  onClick={onButtonClick}
                  sx={{
                    fontSize: { xs: "1rem", sm: "1rem" , md: "1rem" },
                    color: "inherit",
                    textDecoration: "underline",
                    fontFamily: "Poppins",
                  }}
                >
                  {data.buttonText}
                </Button>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OverViewDetails;
