import React from "react";
import { Box, Typography } from "@mui/material";
import OverviewSection from "../../components/OverviewSection";
import SelectUs from "../../components/selectUs";
import Reviews from "../../components/Reviews";
import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"


const WhyChooseUsWebsite = () => {
  //for packages
  const handlePackages = () => {
    window.location.href = "website/packages";
  };

  //for Reviews



  return (
    <div style={{ width: "100%", height: "100%", marginTop: "160px" }}>
      <OverviewSection
        title1="Why We Rule The Web Design Industry"
        subTitle="Because We Provide The Best Web Design Services, Why Else?"
        listItems={[
          "Modern Web Design Concepts",
          "Web Revision Cycles",
          "Iconic Websites For 100% Satisfaction",
          "Affordable Web Design Packages",
          "Web Design Gurus At Work",
        ]}
        // imageUrl="/assets/MainContentImg/workspace_04.jpg"
        // imageUrl2="/assets/MainContentImg/workspace_04.jpg"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/why-us.jpg"
        reverseLayout={true}
      />

      <Box
        sx={{
          padding: "50px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          display: { xs: "none", sm: "block" },
          backgroundColor: "#f1f1f1",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#990304",
                fontSize: "2rem",
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              Why Owners & Enterprises Trust Us With Web Designs
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#292323",
                paddingX: "5rem",
                fontFamily: "Poppins",
                fontSize: "1rem",
                fontWeight: 300,
              }}
            >
              The world is going digital and Uptown bears the onus of getting it
              there the right way to The Uptown Way! We ‘House’ dedicated teams
              of professional web designers (and developers) renowned in the
              digital realm for their professional ethos and unparalleled
              expertise. Uptown ascertains that every web designing and
              developing project it undertakes, it aces the responsiveness,
              symmetrical equilibrium, design balance, and other front-end and
              back-end nuances to boost the website designed, glaringly standing
              out to the global notion of going digital!
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "50px",
              paddingX: "100px",
              paddingY: "50px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-1.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#292323",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                Expert
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.5rem",
                }}
              >
                Web Developer
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Consummate mastery in various programming languages and web
                frameworks leads the professional web developers at Uptown to
                devise the RIGHT web experiences on demand and as per the
                client`s requisition
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-2.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#292323",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                Client
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.5rem",
                }}
              >
                Friendly Policies
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Sedulously crafted guidelines ensure that every web design and
                development undertaking at Uptown is nothing but client-centric.
                From easy payment methodology to handling post-order nuances,
                client-friendly policies govern Uptown all the way
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-3.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#292323",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                Intuitive
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  FONTSIZE: "1.5rem",
                }}
              >
                Professionals
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Decades of experience and professional aptitude renders every
                web design stemming at Uptown, a balanced amalgamation of
                front-end and back-end deliverables. All thanks to the
                stimulating web design and web development experts!
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "50px",
              paddingX: "100px",
              paddingY: "50px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-4.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#292323",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                Design
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.5rem",
                }}
              >
                Equilibrium
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Engaging web-design elements leveraged by symmetrical
                device-centric layout provide the optimal balance between UI/UX
                aesthetics and traffic-engagement.
              </Typography>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-5.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#292323",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                Engaging
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.5rem",
                }}
              >
                Folds
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Meticulous content placement, compartmentalized web structures,
                tempting visual cues governed by enticing “Call To Actions” for
                maximum conversions! The right way of web designing caters
                functionality with aesthetics!
              </Typography>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-6.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#292323",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                Guaranteed
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.5rem",
                }}
              >
                Satisfaction
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                State of the art web development, meticulous web design, timely
                delivery, constant customer care, and post-order support, all at
                a price that`s light on the pocket. Dissatisfaction isn`t an
                option!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          boxShadow: {
            xs: "none",
            md: "0px 1px 20px rgba(0, 0, 0.4, 0.4)",
            marginBottom: "2rem",
          },
        }}
      >
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>

      <Reviews
        subtitle={
          "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
        }
        heading={"Look What Our Customers Have Said About Us"}
        paragraphs={[
          "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
          "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]}
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}      />
    </div>
  );
};

export default WhyChooseUsWebsite;
