import { Box, Button, Grid, Input, Typography } from "@mui/material";
import React from "react";

interface DownloadGuideProps {
  pdfUrl: string;
}
const DownloadGuide: React.FC<DownloadGuideProps> = ({ pdfUrl }) => {
  const [email, setEmail] = React.useState("");
  const handleDownload = () => {
    window.open(pdfUrl, "_blank");
    setEmail("");
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "215%" : "initial";
  return (
    <Box width={containerWidth}
      sx={{
        backgroundImage:
          "url('https://www.uptownlogodesign.com/assets/images/download-cta-bg.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // Set minimum height to fill the viewport
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="start"
        spacing={2}
        sx={{ padding: "3rem" }} // Add padding to the container
      >
        <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              paddingLeft: containerWidth==="initial"?"5rem":"0rem",
            }}
          >
            <Typography
              variant="h1"
              color="#FFF"
              fontWeight={600}
              fontSize="2rem"
              marginBottom="1rem"
              fontFamily="Poppins"
            >
              DOWNLOAD THE COMPLETE GUIDE!
            </Typography>
            <Typography
              variant="subtitle1"
              color="#FFF"
              fontWeight={500}
              fontSize="1rem"
              marginBottom="3rem"
              fontStyle="italic"
              textAlign="start"
              fontFamily="Poppins"
            >
              Want to know even more about logos? Get all the secrets to great
              logo design when you download our comprehensive eBook
            </Typography>
            <Input
              type="text"
              placeholder="Enter your Email"
              value={email}
              onChange={handleEmailChange}
              sx={{
                backgroundColor: "white",
                borderRadius: "5px",
                paddingY: "10 px",
                paddingX: "10px",
                width: "50%", // Adjust width to 80% for responsiveness
                fontSize: "1rem",
                marginBottom: "10px",
                fontFamily: "Poppins",
              }}
            />
            <Button
              sx={{
                backgroundColor: "#990304",
                color: "white",
                borderRadius: "5px",
                fontSize: "1rem",
                width: "50%",
                fontWeight: "500",
                fontFamily: "Poppins",
                "&:hover": {
                  backgroundColor: "#990304",
                },
              }}
              onClick={handleDownload}
            >
              Download Now
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
          <Box display="flex" justifyContent="center">
            {/* Right side for image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/download-cta-pic.png"
              alt="Your Image"
              style={{ maxWidth: "80%", height: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadGuide;
