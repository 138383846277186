import React, { useEffect, useRef, useState } from "react";
import OverviewSection from "../../components/OverviewSection";
import { Box, Typography } from "@mui/material";
import Brosheres from "../../components/Brosheres";
import LeadDesignOverviewPack from "../../components/LeadDesignOverviewPack";
import Reviews from "../../components/Reviews";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import brosherImg01 from "../..//Assets/keyFeatures/guarantee.png"
import keyFeatureImg01 from "../../Assets//keyFeatures/guarantee.png"
import keyFeatureImg02 from "../../Assets//keyFeatures/money-back-guarantee.png"
import keyFeatureImg03 from "../../Assets//keyFeatures/think-outside-the-box.png"
import keyFeatureImg04 from "../../Assets//keyFeatures/video-call.png"
import keyFeatureImg05 from "../../Assets//keyFeatures/trophy.png"
import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"

const PackagesWebsite = () => {
  //key feature animation
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const box = document.getElementById("animated-box");
      if (box) {
        const rect = box.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight - 100;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //for brosher scroll

  const brosheresRef = useRef<HTMLDivElement>(null);
  const originalRef = useRef<HTMLDivElement | null>(null); // Store the original ref
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    originalRef.current = brosheresRef.current; // Assign the original ref
    // Start scrolling automatically when the component mounts
    startAutoScroll();

    // Clear the interval when the component unmounts
    return () => {
      if (scrollInterval) clearInterval(scrollInterval);
    };
  }, []);

  const startAutoScroll = () => {
    if (originalRef.current) {
      // Set an interval to scroll every 3 seconds
      const interval = setInterval(() => {
        if (originalRef.current) {
          // Calculate the new scroll position
          const newScrollLeft =
            originalRef.current.scrollLeft * 5 +
            originalRef.current.offsetWidth;

          // Calculate the maximum scroll position
          const maxScrollLeft =
            originalRef.current.scrollWidth * 5 -
            originalRef.current.offsetWidth * 5;

          // Scroll to the new position with smooth behavior
          originalRef.current.scrollTo({
            left: newScrollLeft >= maxScrollLeft ? 0 : newScrollLeft,
            behavior: "smooth",
          });
        }
      }, 10000); // Adjust the interval time as needed

      setScrollInterval(interval);
    }
  };

  const ScrollMouseEnter = () => {
    // Set brosheresRef to null when mouse enters the div
    originalRef.current = null;
  };

  const ScrollMouseLeave = () => {
    // Restore brosheresRef to its original value when mouse leaves the div
    originalRef.current = brosheresRef.current;
  };

  const handleScrollBack = () => {
    if (originalRef.current) {
      originalRef.current.scrollTo({
        left: originalRef.current.scrollLeft - 300, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const handleScrollForward = () => {
    if (originalRef.current) {
      originalRef.current.scrollTo({
        left: originalRef.current.scrollLeft + 300, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  //for Reviews

  const ReviewsSlidingimages = [
    "/assets/BrandImg/01.png",
    "/assets/BrandImg/02.png",
    "/assets/BrandImg/03.png",
    "/assets/BrandImg/04.png",
    "/assets/BrandImg/05.png",
    "/assets/BrandImg/06.png",
    "/assets/BrandImg/07.png",
    "/assets/BrandImg/08.png",
    "/assets/BrandImg/08.png",

    // Add more logo URLs as needed
  ];

  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "initial";

  return (
    <div style={{ width: "100%", height: "100%", marginTop: containerWidth === "initial" ? "160px" : "0px" }}>
      <OverviewSection
        title1="THE MOST COST-FRIENDLY PACKAGES"
        subTitle="Our Packages Are Designed With Consideration Of Fulfilling Different Sets Of Requirements In A Cost Effective Way For All Businesses."
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/packages.jpg"
        reverseLayout={true}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f1f1f1",
          width: containerWidth,
        }}
      >
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "90%",
            marginTop: "50px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#990304",
              fontSize:{ xs: "2rem", sm: "1.5rem", md: "1.8rem"},
              fontWeight: "600",
              textAlign: "center",
              marginBottom: "10px",
              fontFamily: "Poppins",
            }}
          >
            Carefully Crafted Logo Packages For New And Existing Businesses
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#292323",
              textAlign: "center",
              marginBottom: "20px",
              fontFamily: "Poppins",
            }}
          >
            We Provide Highly Flexible And Affordable Design Options
          </Typography>
          <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <ArrowBackIosIcon
              onClick={handleScrollBack}
              style={{ cursor: "pointer", fontSize: "3rem", color: "#990304" }}
            />
            <div
              style={{
                display: "flex",
                gap: "0px", // Add some space between items
                overflowX: "auto", // Enable horizontal scrolling
                maxWidth: "90%",
                marginTop: "-3rem",
                padding: containerWidth === "initial" ? "2rem" : "0rem",
                paddingTop: "4rem",
                paddingBottom: "4rem"
              }}
              onMouseEnter={ScrollMouseEnter}
              onMouseLeave={ScrollMouseLeave}
              ref={brosheresRef}
            >
              {/* Container for the first set of three Brosheres */}
              <div style={{ display: "flex", flexWrap: "nowrap", gap: "30px", justifyContent: "center" }}>
                <>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                    mb={"1rem"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform 0.3s ease",
                      },
                    }}
                  >
                    <Brosheres
                      title1="Best seller"
                      title2="Smart design solution"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$39"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                    mb={"1rem"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform 0.3s ease",
                      },
                    }}
                  >
                    <Brosheres
                      title1="The Ultimate"
                      title2="Cutting-edge Design"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$68"
                      imageSrc= {brosherImg01}
                    />
                  </Box>

                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                    mb={"1rem"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform 0.3s ease",
                      },
                    }}
                  >
                    <Brosheres
                      title1="Business Plus"
                      title2="Ideal design solutions"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$119"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Business Plus"
                      title2="Ideal design solutions"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$119"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Enterprise"
                      title2="Perfect design solution"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$199"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Enterprise Plus"
                      title2="Value added design solutions"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$349"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="The Corporate"
                      title2="Complete design solution"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$499"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Illustrative Starter"
                      title2="Illustrative/Mascot Logo"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$199"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Illustrative Budget"
                      title2="Ideal design solutions"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$299"
                      imageSrc= {brosherImg01}
                    />
                  </Box>

                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Illustrative Budget"
                      title2="Illustrative/Mascot Logo"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$399"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="3D Special"
                      title2="Perspective Brand Identity"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$399"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                </>
              </div>
            </div>
            <ArrowForwardIosIcon
              onClick={handleScrollForward}
              style={{ cursor: "pointer", fontSize: "3rem", color: "#990304" }}
            />
          </Box>

          {/* Add custom scrollbar styling */}
        <style>
          {`
        ::-webkit-scrollbar {
          display: none; /* Hide scrollbar */
        }
      `}
        </style>

        <Box
          justifyContent={"center"}
          sx={{ marginTop: "-30px", display:{xs: "none", sm: "flex", lg: "flex", xl: "flex", md: "flex"} }}
          color={"#990304"}
          fontSize={"1.5rem"}
          fontFamily={"Poppins"}
        >
          {" "}
          Key Feature
        </Box>
        <Box
          id="animated-box"
          justifyContent={"center"}
          sx={{
            display:{xs: "none", sm: "flex", lg: "flex", xl: "flex", md: "flex"},
            marginTop: "20px",
            color: "#990304",
            fontSize: "1rem",
            position: "relative",
            paddingBottom: "25px",
            left: isVisible ? "0" : "-100%", // Initial position outside the viewport
            transition: "left 0.5s ease", // Animation transition
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "8rem",

            }}
          >
            <img
              src= {keyFeatureImg01}
              alt="Image 1"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              100% Setisfaction 
              Gurentee
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "8rem",
              
            }}
          >
            <img
              src= {keyFeatureImg02}
              alt="Image 2"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              Unique logo
              design
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "8rem",
              
            }}
          >
            <img
              src= {keyFeatureImg03}
              alt="Image 3"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              100% money Back 
              Gurentee
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "8rem",
              
            }}
          >
            <img
              src= {keyFeatureImg04}
              alt="Image 3"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              24/4 Design 
              Consultancy
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "0",
              
            }}
          >
            <img
              src= {keyFeatureImg05}
              alt="Image 3"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              Award Winning
              Designers
            </Typography>
          </div>
          {/* Repeat this structure for additional image and quote combinations */}
        </Box>
        </Box>
      </Box>
      <LeadDesignOverviewPack
        title="league Design All in One"
        subtitle="The Comprehensive Branding Solution"
        paragraph="Starting from scratch or revamping your brand`s existence? Uptown Logo Design brings forth the very package that gets your business rolling seamlessly. From providing a custom logo design to asserting your brand`s digital presence across all the major platforms, the “All in One” proves to be the singularly comprehensive solution to all your branding predicaments"
        list={[
          "Logo Design",
          "Branding",
          "Social Media",
          "Website",
          "Banner Design",
          "Special Features",
        ]}
        amount="1499"
      />
      <Reviews
        subtitle={
          "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
        }
        heading={"Look What Our Customers Have Said About Us"}
        paragraphs={[
          "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
          "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]}
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
      images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}
      />
    </div>
  );
};

export default PackagesWebsite;
