import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import OverviewSection from "../components/OverviewSection";
// import RegistrationForm from "../components/RegistrationForm";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    country: false,
    subject: false,
    message: false,
    isAgreed: false,
  });

  const handleCheckboxChange = (event: any) => {
    setIsAgreed(event.target.checked);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    // Validation
    const errorsCopy = { ...errors };
    if (!name) {
      errorsCopy.name = true;
    } else {
      errorsCopy.name = false;
    }
    if (!email) {
      errorsCopy.email = true;
    } else {
      errorsCopy.email = false;
    }
    if (!phoneNumber) {
      errorsCopy.phoneNumber = true;
    } else {
      errorsCopy.phoneNumber = false;
    }
    if (!country) {
      errorsCopy.country = true;
    } else {
      errorsCopy.country = false;
    }
    if (!subject) {
      errorsCopy.subject = true;
    } else {
      errorsCopy.subject = false;
    }
    if (!message) {
      errorsCopy.message = true;
    } else {
      errorsCopy.message = false;
    }
    if (!isAgreed) {
      errorsCopy.isAgreed = true;
    } else {
      errorsCopy.isAgreed = false;
    }

    setErrors(errorsCopy);

    // Submit if no errors
    if (!Object.values(errorsCopy).includes(true)) {
      const RegistrationData = {
        name,
        email,
        phoneNumber,
        country,
        subject,
        message,
        isAgreed,
      };
      console.log("Form submitted:", RegistrationData);

      // Clear form fields
      setName("");
      setEmail("");
      setPhoneNumber("");
      setCountry("");
      setSubject("");
      setMessage("");
      setIsAgreed(false);
    }
  };

  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "160%" : "initial";

  return (
    <Box style={{ width: "100%", height: "100%", marginTop: containerWidth === "initial" ? "80px" : "0" }}>
      <Box>
        <OverviewSection
          title1="Get In Touch With Us"
          subTitle="Our Team Of Experienced Design Professionals Is Here To Guide You About Everything To Do With Your Design Needs"
          subTitleColor="#990304"
          bgImage="https://www.uptownlogodesign.com/assets/images/banners/contac.jpg"
          reverseLayout={true}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
         paddingY={"5rem"}
        width={containerWidth}
      >
        <Box
          width={"90%"}
          sx={{
            boxShadow: { xs: "none", sm: "rgba(0, 0, 0, 0.5) 0px 0px 8px" },
          }}
        >
          <Grid container spacing={2}>
            {/* Left side for the form */}
            <Grid item xs={12} md={8} sm={4}>
              <Box
                padding={5}
                boxSizing={"border-box"}
                display={"flex-start"}
                flexDirection={"column"}
                alignContent={"start"}
                justifyContent={"center"}
                width={containerWidth}
                height={"100%"}
                sx={{
                  backgroundImage:
                    "url(https://www.uptownlogodesign.com/assets/images/grey-arrow-bg.png)",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontWeight: "500",
                    fontSize: "2rem",
                    fontFamily: "Poppins",
                    marginBottom: "1rem",
                    textAlign: "start",
                    alignContent: "start",
                  }}
                >
                  Fill the Required Information:
                </Typography>
                <Grid container spacing={4} padding={4}>
                  <Grid item xs={12} sm={8} md={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      id="fullWidth"
                      size="small"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      variant="outlined"
                      error={errors.name}
                      helperText={errors.name && "Please enter your name"}
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#898989",
                          fontSize: "1rem", // Change label font size
                          fontFamily: "Poppins", // Change label font family
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color when focused
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color on hover
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Default border color
                          },
                          "& .MuiInputBase-input": {
                            color: "#898989", // Change input text color
                          },
                          "& .MuiInputBase-input:hover": {
                            color: "#990304",
                            fontFamily: "Poppins",
                            fontSize: "1rem", // Change input text color on hover
                          },
                          "& .MuiInputBase-input:focus": {
                            color: "#333", // Change input text color when focused
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      id="fullWidth"
                      size="small"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      error={errors.email}
                      helperText={errors.email && "Please enter your email"}
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#898989",
                          fontSize: "1rem", // Change label font size
                          fontFamily: "Poppins", // Change label font family
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color on hover
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Default border color
                          },
                          "& .MuiInputBase-input": {
                            color: "#898989", // Change input text color
                          },
                          "& .MuiInputBase-input:hover": {
                            color: "#990304",
                            fontFamily: "Poppins",
                            fontSize: "1rem", // Change input text color on hover
                          },
                          "& .MuiInputBase-input:focus": {
                            color: "#333", // Change input text color when focused
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      id="fullWidth"
                      size="small"
                      value={phoneNumber}
                      onChange={(e) => {
                        // Remove non-numeric characters from the input value
                        const formattedValue = e.target.value.replace(
                          /\D/g,
                          ""
                        );
                        setPhoneNumber(formattedValue);
                      }}
                      error={errors.phoneNumber}
                      helperText={
                        errors.phoneNumber && "Please enter your phone number"
                      }
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#898989",
                          fontSize: "1rem", // Change label font size
                          fontFamily: "Poppins", // Change label font family
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color when focused
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color on hover
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Default border color
                          },
                          "& .MuiInputBase-input": {
                            color: "#898989", // Change input text color
                          },
                          "& .MuiInputBase-input:hover": {
                            color: "#990304",
                            fontFamily: "Poppins",
                            fontSize: "1rem", // Change input text color on hover
                          },
                          "& .MuiInputBase-input:focus": {
                            color: "#333", // Change input text color when focused
                          },
                        },
                      }}
                      onKeyPress={(e) => {
                        // Allow only numbers and backspace/delete keys
                        const allowedKeys = /[0-9\b]/;
                        if (!allowedKeys.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      label="Country"
                      size="small"
                      select
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      error={errors.country}
                      helperText={
                        errors.country && "Please select your country"
                      }
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#898989",
                          fontSize: "1rem", // Change label font size
                          fontFamily: "Poppins", // Change label font family
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color when focused
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color on hover
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Default border color
                          },
                          "& .MuiInputBase-input": {
                            color: "#898989", // Change input text color
                          },
                          "& .MuiInputBase-input:hover": {
                            color: "#990304",
                            fontFamily: "Poppins",
                            fontSize: "1rem", // Change input text color on hover
                          },
                          "& .MuiInputBase-input:focus": {
                            color: "#333", // Change input text color when focused
                          },
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ fontFamily: "Poppins" }}
                        value="United States of America"
                      >
                        United States of America
                      </MenuItem>
                      <MenuItem sx={{ fontFamily: "Poppins" }} value="Canada">
                        Canada
                      </MenuItem>
                      <MenuItem sx={{ fontFamily: "Poppins" }} value="UK">
                        UK
                      </MenuItem>
                      {/* Add more countries as needed */}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={8} md={12}>
                    <TextField
                      fullWidth
                      label="Subject"
                      id="fullWidth"
                      size="small"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                      error={errors.subject}
                      helperText={errors.subject && "Please enter your email"}
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#898989",
                          fontSize: "1rem", // Change label font size
                          fontFamily: "Poppins", // Change label font family
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color on hover
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Default border color
                          },
                          "& .MuiInputBase-input": {
                            color: "#898989", // Change input text color
                          },
                          "& .MuiInputBase-input:hover": {
                            color: "#990304",
                            fontFamily: "Poppins",
                            fontSize: "1rem", // Change input text color on hover
                          },
                          "& .MuiInputBase-input:focus": {
                            color: "#333", // Change input text color when focused
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={12}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      label="Message"
                      multiline
                      rows={5}
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      error={errors.message}
                      helperText={errors.message && "Please enter your message"}
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#898989",
                          fontSize: "1rem", // Change label font size
                          fontFamily: "Poppins", // Change label font family
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color when focused
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Change border color on hover
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#990304", // Default border color
                          },
                          "& .MuiInputBase-input": {
                            color: "#898989", // Change input text color
                          },
                          "& .MuiInputBase-input:hover": {
                            color: "#990304",
                            fontFamily: "Poppins",
                            fontSize: "1rem", // Change input text color on hover
                          },
                          "& .MuiInputBase-input:focus": {
                            color: "#333", // Change input text color when focused
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          checked={isAgreed}
                          onChange={handleCheckboxChange}
                          size="small"
                          sx={{
                            fontFamily: "Poppins",
                            color: errors.isAgreed ? "#f44336" : "#990304", // Change the color based on errors
                            "&.Mui-checked": {
                              color: errors.isAgreed ? "#f44336" : "#990304", // Change the color based on errors
                            },
                          }}
                        />
                      }
                      label="I agree to the terms"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={!isAgreed}
                      onClick={handleSubmit}
                      sx={{
                        backgroundColor: "#990304",
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: "1.2rem",
                        paddingX: "3rem",
                        "&:hover": {
                          backgroundColor: "transparent",
                          borderColor: "#990304",
                          border: "1px solid #990304",
                          color: "#990304",
                          boxShadow: "none",
                        },
                        "&:hover .MuiButton-label": {
                          color: "#990304",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/* Right side for the image */}
            <Grid item xs={12} sm={4} md={4}>
              <Box
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "96%",
                  
                  width: containerWidth !== "initial" ? "150%" : "100%",
                  flexDirection: "column",
                  backgroundColor: "#292323",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="start"
                  flexDirection="column"
                  gap={{ xs: 2, sm: 4, md: 6 }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#990304",
                      fontWeight: "500",
                      fontSize: "2rem",
                      fontFamily: "Poppins",
                      // marginBottom: "3rem",
                      textAlign: "start",
                      alignContent: "start",
                    }}
                  >
                    Contact Us
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#990304",
                      fontWeight: "500",
                      fontSize: "1.5rem",
                      fontFamily: "Poppins",
                      // marginBottom: "2rem",
                      textAlign: "start",
                      alignContent: "start",
                    }}
                  >
                    Address
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#edf5e1",
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      fontFamily: "Poppins",
                      // marginBottom: "3rem",
                      textAlign: "start",
                      alignContent: "start",
                    }}
                  >
                    5042 Wilshire Blvd #43900 Los Angeles, CA 90036
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#990304",
                      fontWeight: "500",
                      fontSize: "1.5rem",
                      fontFamily: "Poppins",
                      // marginBottom: "2rem",
                      textAlign: "start",
                      alignContent: "start",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#edf5e1",
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      fontFamily: "Poppins",
                      // marginBottom: "3rem",
                      textAlign: "start",
                      alignContent: "start",
                    }}
                  >
                    info@leadLogoDesign.com
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#990304",
                      fontWeight: "500",
                      fontSize: "1.5rem",
                      fontFamily: "Poppins",
                      // marginBottom: "2rem",
                      textAlign: "start",
                      alignContent: "start",
                    }}
                  >
                    Toll Free
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#edf5e1",
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      fontFamily: "Poppins",
                      // marginBottom: "3rem",
                      textAlign: "start",
                      alignContent: "start",
                  }}
                  >
                    1-888-420-8889
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#990304",
                      fontWeight: "500",
                      fontSize: "1.5rem",
                      fontFamily: "Poppins",
                      textAlign: "start",
                      alignContent: "start",
                    }}
                  >
                    Social Media
                  </Typography>
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <FacebookIcon
                      sx={{ color: "#edf5e1", fontSize: "1.5rem" }}
                    />
                    <InstagramIcon
                      sx={{ color: "#edf5e1", fontSize: "1.5rem" }}
                    />
                    <XIcon sx={{ color: "#edf5e1", fontSize: "1.5rem" }} />
                    <LinkedInIcon
                      sx={{ color: "#edf5e1", fontSize: "1.5rem" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
