import { Box, Typography } from "@mui/material";
import OverviewSection from "../components/OverviewSection";
import SelectUs from "../components/selectUs";
import { useNavigate } from "react-router-dom";
import Reviews from "../components/Reviews";
import reviewDefultImg01 from "../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../Assets/BrandImg/10.png"
import rattingImg from "../Assets/reviwesImg/rating.png"
import reviewImg01 from "../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../Assets/reviwesImg/topReview3.jpg"


export const About = () => {
  let heading =
    "We Trigger Business Evolution , Blending Creative Intellect And Client Relationship To Craft A  Digital Experience That None Can Provide.";
  const words = heading.split(" ");

  const Navigate = useNavigate();
  const handlePackeages = () => {
    Navigate("/packages");
  };

  //for Reviews

  
  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "200%" : "initial";

  return (
    <Box style={{ width: "100%", height: "100%", marginTop: containerWidth === "initial" ? "80px" : "0" }}>
      <Box>
        <OverviewSection
          title1="Creativity Needs No Introductions"
          subTitle="A Digital Design Agency Since 2010 Specialized In Logo Designs, Websites, Animations And Applications"
          subTitleColor="#990304"
          bgImage="https://www.uptownlogodesign.com/assets/images/banners/about-us.jpg"
          reverseLayout={true}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={containerWidth}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          padding={"5rem"}
          paddingX={containerWidth === "initial" ? "2rem" : "2rem"}
        >
          <Typography
            variant="h1"
            sx={{ textAlign: "center" }}
            fontSize={"2.5rem"}
            fontWeight={"600"}
            color={"#292323"}
            fontFamily={"Poppins"}
            whiteSpace={"pre-line"}
            letterSpacing={"2px"}
            lineHeight={"4rem"}
            marginLeft={containerWidth !== "initial" ? "5rem" : "0"}
            
          >
            {words.map((word, index) => (
              <span
                key={index}
                style={{
                  color:
                    index === 2
                      ? "#990304"
                      : "inherit" && index === 3
                      ? "#990304"
                      : "inherit" && index === 5
                      ? "#990304"
                      : "inherit" && index === 6
                      ? "#990304"
                      : "inherit" && index === 14
                      ? "#990304"
                      : "inherit" && index === 15
                      ? "#990304"
                      : "inherit" && index === 16
                      ? "#990304"
                      : "inherit",
                  fontFamily:
                    index === 2
                      ? "cursive"
                      : "inherit" && index === 3
                      ? "cursive"
                      : "inherit" && index === 5
                      ? "cursive"
                      : "inherit" && index === 6
                      ? "cursive"
                      : "inherit" && index === 14
                      ? "cursive"
                      : "inherit" && index === 15
                      ? "cursive"
                      : "inherit" && index === 16
                      ? "cursive"
                      : "inherit",
                }}
              >
                {word}{" "}
              </span>
            ))}
          </Typography>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"3rem"}
        sx={{
          backgroundImage:
            "url(https://www.uptownlogodesign.com/assets/images/grey-arrow-bg.png)",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          padding={"1rem"}
          width={containerWidth}
        >
          <Box flex={"1"} justifyContent={"center"} alignItems={"center"}>
            {/* Left side for typography */}
            <Typography
              variant="h1"
              color={"#990304"}
              fontWeight={"500"}
              // marginTop={"5rem"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "10rem",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1.5rem" : "1.8rem",
              }}
            >
              What To Expect?
            </Typography>
            <Typography
              variant="subtitle1"
              color={"black"}
              fontWeight={"400"}
              marginBottom={"3rem"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "10rem",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1.2rem" : "1.5rem",
              }}
            >
              Trust, Effective Communication, Vigorous Thinking, Hard Work With
              A Dash Of Fun
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#666"}
              fontWeight={"400"}
              marginBottom={"3rem"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "10rem",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1rem" : "1.5rem",
              }}
            >
              We believe in the customized process, we customize niche specific
              designs which triggers your target audience to build a
              relationship with you. We believe in maintaining brilliance
              throughout our process.
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#666"}
              fontWeight={"400"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "10rem",
                width: containerWidth === "initial" ? "60%" : "100%",
                fontSize: containerWidth === "initial" ? "1rem" : "1.5rem",
              }}
            >
              We deliver trust, transparent communication, and hard work along
              with creativity and intellect. We believe in teamwork thus we work
              as a team of developers, designers, and our clients. Together we
              share, brainstorm, learn and create a masterpiece. Our team
              strives for quality, served just on right time and supersedes the
              expectations of our clients!
            </Typography>
          </Box>
          <Box flex={"1"} 
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
          }}
           >
            {/* Right side for image */}
            <img
              src="https://img.freepik.com/free-photo/colleagues-giving-fist-bump_53876-20861.jpg?t=st=1713398184~exp=1713401784~hmac=de97b4a7642b38811bd3550a1ba0c95165e5a07b8e97c230ed168a111e61de83&w=826"
              alt="Your Image"
              style={{ width: "80%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          boxShadow: containerWidth === "initial" ? "0px 5px 20px rgba(0, 0, 0.2, 0.2)":"none",
          marginBottom: containerWidth === "initial" ? "2rem":"0rem",
          padding: "0rem",
        }}
      >
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackeages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
      <Reviews
        subtitle={
          "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
        }
        heading={"Look What Our Customers Have Said About Us"}
        paragraphs={[
          "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary ",
          "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]}
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}
      />
    </Box>
  );
};
