import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import OverviewSection from "../../components/OverviewSection";

// Define styles for the Accordion component
const styles = {
  root: {
    boxShadow: "none",
    borderRadius: 0,
    padding: "16px",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
};

const containerWidth =
  window.innerWidth >= 300 && window.innerWidth <= 900 ? "160%" : "inital";

const FaqsBranding = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        marginTop: containerWidth === "inital" ? "160px" : "0px",
      }}
    >
      <OverviewSection
        title1="Everything You Need To Know"
        subTitle="Find Answers To Your Queries Or Contact Us!"
        subTitleColor="#990304"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/faqs.jpg"
        listItems={[
          "Clarify your Queries",
          "Connect with Our Team",
          "24/7 Support",
          "Get Your Project Done",
        ]}
        reverseLayout={true}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        padding={5}
        marginBottom={10}
        width={containerWidth}
        paddingLeft={containerWidth === "inital" ? "0rem" : "5rem"}
      >
        {/* Accordion 1 */}
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "none", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
            borderTop: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
              fontFamily="Poppins"
            >
              What is branding?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              It is about evolving your business and your brand internally and
              externally. It helps the business in getting attention from the
              customers, and setting them apart from their competitors.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "none", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How long does it take to develop a brand identity?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              It takes almost 90 to 120 days to develop a brand. It depends on
              the availability of the clients team and the decision making
              process. The sooner they will take the decision, the better for
              the company.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily="Poppins"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Do the clients own the rights of the work you do?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              The rights will be transferred to client as soon as we receive the
              full payment. Since the brand was created for you, you have
              complete right to own it!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily="Poppins"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              What happens if your client is not satisfied with your work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              You just don’t need to worry about that. We have a great team
              working for us and they will be glad helping you out in any such
              situation.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily="Poppins"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              What is the need for branding?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              To build a strong connection between you and your brand, your
              brand identity must be recognizable. You must create awareness
              about your brand to encourage buying and cultivates loyalty.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily="Poppins"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Is a custom designed corporate logo a necessity for the brand?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              It is an excellent way to differentiate your brand from others. A
              logo is basically a brands identity and is a very important
              element for the face of your brand. We would suggest you to have a
              logo designed for your brand and we would be glad to help you in
              doing so.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily="Poppins"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How often should I update my brand image?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              You can do minor alterations in your branding whenever you feel
              the need for doing it. But if you want to renew your brand image
              you can update your branding after every 5-7 years to give a fresh
              look to your brand.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily="Poppins"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Does your branding include other marketing material?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              Yes, we also design stationery and brochures and other promotional
              materials according to the need of your business.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily="Poppins"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              What other promotional material do you offer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              We offer:
              <ul>
                <li>Brochures</li>
                <li>Flyers</li>
                <li>T-Shirts</li>
                <li>Posters</li>
                <li>Custom Packing</li>
                <li>Custom Postcards</li>
                <li>Stationery</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: {xs: "none", sm: "75%", md: "85%"},
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily="Poppins"
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Is your team flexible enough to work with?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily="Poppins"
            >
              We have a flexible team working for us and will be ready to help
              you in all matters.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default FaqsBranding;
