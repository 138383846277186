import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import OverviewSection from "../../components/OverviewSection";

// Define styles for the Accordion component
const styles = {
  root: {
    boxShadow: "none",
    borderRadius: 0,
    padding: "16px",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
};

const containerWidth =
  window.innerWidth >= 300 && window.innerWidth <= 900 ? "160%" : "inital";

const FaqsLogoDesign = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        marginTop: containerWidth === "inital" ? "160px" : "0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <OverviewSection
        title1="Everything You Need To Know"
        subTitle="Find Answers To Your Queries Or Contact Us!"
        subTitleColor="#990304"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/faqs.jpg"
        listItems={[
          "Clarify your Queries",
          "Connect with Our Team",
          "24/7 Support",
          "Get Your Project Done",
        ]}
        reverseLayout={true}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        padding={10}
        width={containerWidth}
      >
        {/* Accordion 1 */}
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
            borderTop: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
              
            >
              Will my logo be unique
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              It all depends on how you want your logo to be. We offer different
              budget-friendly packages starting from $42 which is the basic
              package mainly for the startups. Our best-seller package starts
              from $85 and has some Smart design Solution Recommended For Small
              Scale Businesses. We also offer a complete package known as the
              ‘’The Ultimate’’ package for $170 which serves as a cutting-edge
              design solution and is recommended for all businesses.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How much does a logo cost?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              It all depends on how you want your logo to be. We offer different
              budget friendly packages starting from $42 which is the basic
              package mainly for the startups. Our best seller package starts
              from $85 and has some Smart design Solution Recommended For Small
              Scale Businesses. We also offer a complete package known as the
              ‘’The Ultimate’’ package for $170 which serves as a cutting-edge
              design solution and is recommended for all businesses.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Are there hidden, or additional fees for revising logos during my
              project?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              No. All our packages have built-in revision cycle also, our
              designers are flexible enough to accommodate a few more revisions
              without any extra charges. We do not promise you to offer
              unlimited revisions as we do not believe in following an
              unrealistic approach.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How many logo samples do I receive?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              It depends upon the package you will choose from the below
              mentioned packages:
              <ul>
                <li>Budget</li>
                <li>Best Seller</li>
                <li>The Ultimate</li>
                {/* Add more list items as needed */}
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How many formats you will provide?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              We will provide the final logo in different formats such as:
              <ul>
                <li>eps (editable vector source file) (CMYK)</li>
                <li>gif</li>
                <li>The Ultimate</li>
                <li>AI (Adobe Illustrator)</li>
                <li>tiff (CMYK)</li>
                <li>jpg (in multiple sizes,RGB)PDF</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              What is the procedure?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              It all starts with our logo design briefing form which you can
              complete online or download as a Word file to email back to us.
              You will have an account manager and a lead designer who will
              discuss your project with you over the telephone. If you have
              asked us to look at other companies’ brands we will research these
              on the web. We will then prepare an initial selection of prototype
              designs. These are intended to start a dialogue that will continue
              through a series of development stages to complete the design.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How do I keep check of progress?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              Telephone and email have served us very well over the years and we
              have built great working relationships with hundreds of clients we
              have never met. However you keep a complete check on your order
              progress right from your Customer Panel (dashboard).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Who will own My Logo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              You will be get full ownership rights of your logo, however you
              can avail our copyrights service for further use.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              How do revisions work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              You will be given multiple design concepts by our designers. You
              will select a specific design for your logo and then we will make
              revisions according to your requirements. We will also ask you to
              give feedback on the designs already provided
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            ...styles.root,
            width: { xs: "100%", sm: "75%", md: "85%" },
            borderBottom: "3px solid #292323",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#990304", fontSize: "2rem" }} />
            }
          >
            <Typography
              variant="h5"
              fontFamily={"Poppins"}
              fontWeight={"600"}
              color={"#990304"}
              fontSize={"1.2rem"}
            >
              Do you design stationery?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              color={"#292323"}
              fontWeight={"400"}
              paddingX={5}
              fontSize={"1rem"}
              fontFamily= "Poppins"
            >
              Yes, stationery design can be provided at an additional fee, if
              its not included in your package selected. We give you artwork
              files to take it to your printer for letterheads, business cards,
              compliments slips and other specialized stationery you may
              require.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default FaqsLogoDesign;
