import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import OverviewSection from "../components/OverviewSection";

export const Reviews = () => {

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "170%" : "inital";

  return (
    <Box width="100%" height="100%" marginTop={containerWidth === "inital" ? "80px" : "0"}>
      <OverviewSection
        title1="Our Client's Review Our Magic"
        subTitle="Build Your Brand And Your Business - With TheOur Client's Esteemed Reviews Represent Our Team`S Sincere Efforts And The Reason We Occupy The Leading Position For Having The Best Online Logo Design Company Reviews In The USA.
      Right Website!"
        subTitleColor="black"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/reviews.jpg"
        reverseLayout={true}
      />
      <Box
        display={"flex"}
        flexDirection={"column"} // Change to column direction
        justifyContent={"center"}
        alignItems={"center"}
        padding={"3rem"}
        marginLeft={containerWidth === "inital" ? "0rem" : "3rem"}
        width={containerWidth}
      >
        <Typography
          variant="h3"
          color={"#990304"}
          marginTop={"50px"}
          fontSize={"2rem"}
          fontWeight={"600"}
          textAlign={"center"}
          fontFamily="Poppins"
        >
          Delivered More Than 10,000 Projects And 250+ Business Solutions
        </Typography>
        <Typography
          variant="subtitle1"
          color={"black"}
          marginTop={"10px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
        >
          Read what our valuable clients have to say about our custom services
          and expert design team.
        </Typography>
        <Typography
          variant="h1"
          color={"#292323"}
          fontSize={containerWidth === "inital" ? "1.5rem" : "1.8rem"}
          marginTop={"35px"}
          fontWeight={400}
          fontFamily="Poppins"
        >
          Lead Logo Design's Remarkable Services
        </Typography>
        <Typography
          variant="subtitle1"
          color={"#666"}
          marginTop={"20px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
       >
          Whether it's web design or logo design, Uptown's highly-skilled team
          understands the clients' needs and ensures to provide the best
          services in town at affordable rates.
          <br />
          <br />
          Uptown is a one-stop shop that offers customized design services to
          clients. With a professional team of designers, the company strives to
          provide expert services in logo, web designing, branding, mobile apps
          development, and animations. You can look at our best logo maker
          websites' reviews to learn more about how our designers perform
          logo-making for different websites.
          <br />
          <br />
          If you're looking for efficient services to illustrate your brand's
          journey, UptownLogoDesign offers versatile options and customized
          packages based on your needs.
        </Typography>
        <Typography
          variant="h1"
          color={"#292323"}
          fontSize={containerWidth === "inital" ? "1.5rem" : "1.8rem"}
          marginTop={"35px"}
          fontWeight={400}
          fontFamily="Poppins"
        >
          What are Uptown's Offered Services?
        </Typography>
        <Typography
          variant="subtitle1"
          color={"#666"}
          marginTop={"10px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
        >
          From reading Uptown's web design company reviews, you can have a
          better understanding of its high-quality services. Further, it offers
          the following services:
        </Typography>
        <Typography
          variant="h1"
          color={"#990304"}
          fontSize={containerWidth === "inital" ? "1.4rem" : "1.8rem"}
          marginTop={"35px"}
          fontWeight={"bold"}
          fontFamily="Poppins"
        >
          Logo Design
        </Typography>
        <Typography
          variant="subtitle1"
          color={"#666"}
          marginTop={"2px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
        >
          The brands that incorporate content marketing in their branding
          strategy see six times more conversions as compared to businesses
          without it. With Uptown, your company gets the full advantage of
          content marketing. Moreover, you get future shoppers and clients in
          every stage of the buying funnel with our data-driven branding
          services.
        </Typography>
        <Typography
          variant="h1"
          color={"#990304"}
          fontSize={containerWidth === "inital" ? "1.4rem" : "1.8rem"}
          marginTop={"35px"}
          fontWeight={"bold"}
          fontFamily="Poppins"
        >
          Website Development and Design
        </Typography>
        <Typography
          variant="subtitle1"
          color={"#666"}
          marginTop={"2px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
        >
          Uptown's talented design team provides responsive website development
          and design to elevate your brand's image.
        </Typography>
        <Typography
          variant="h1"
          color={"#990304"}
          fontSize={containerWidth === "inital" ? "1.4rem" : "1.8rem"}
          marginTop={"35px"}
          fontWeight={"bold"}
          fontFamily="Poppins"
        >
          Branding
        </Typography>
        <Typography
          variant="subtitle1"
          color={"#666"}
          marginTop={"2px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
        >
          Choose competitive-edge branding services such as Print and Packaging,
          T-shirt design, brochure design, social media design, and more from
          Uptown's comprehensive branding solutions.
        </Typography>
        <Typography
          variant="h1"
          color={"#990304"}
          fontSize={containerWidth === "inital" ? "1.4rem" : "1.8rem"}
          marginTop={"35px"}
          fontWeight={"bold"}
          fontFamily="Poppins"
        >
          Mobile Apps Development
        </Typography>
        <Typography
          variant="subtitle1"
          color={"#666"}
          marginTop={"2px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
        >
          Connect smartly with Uptown's cross-platform apps and customized app
          services to boost your brand's visibility on smartphones and other
          devices.
        </Typography>
        <Typography
          variant="h1"
          color={"#990304"}
          marginTop={"35px"}
          fontWeight={"bold"}
          fontSize={containerWidth === "inital" ? "1.4rem" : "1.8rem"}
          fontFamily="Poppins"
        >
          Animation Solutions
        </Typography>
        <Typography
          variant="subtitle1"
          color={"#666"}
          marginTop={"2px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
        >
          With various animation services such as explainer videos, typography
          animations, motion graphics, whiteboard animations, and more, empower
          your digital presence with Uptown's animation solutions.
        </Typography>
        <Typography
          variant="h1"
          color={"#292323"}
          fontSize={containerWidth === "inital" ? "1.5rem" : "2.5rem"}
          marginTop={"35px"}
          fontWeight={400}
          fontFamily="Poppins"
        >
          About Us
        </Typography>
        <Typography
          variant="subtitle1"
          color={"#666"}
          marginTop={"20px"}
          textAlign={"center"}
          fontSize={containerWidth === "inital" ? "1.2rem" : "1.5rem"}
          paddingX={containerWidth === "inital" ? "5rem" : "0rem"}
          fontFamily="Poppins"
        >
          UptownLogoDesign initiated its journey over a decade ago with endless
          possibilities and great future hopes. With the delivery of more than
          10,000 projects and 500 staff employees, the company enjoys a robust
          reputation in the market.
          <br />
          <br />
          If you want to know more about Uptown's work, you can read its online
          logo design guarantee reviews that explain more about its versatile
          design services. Further, the company provides different types of
          services within affordability and customized packages.
          <br />
          <br />
          Uptown's skilled team of designers defeat any challenges on their way
          to provide you with an exceptional design experience. They understand
          your business needs clearly. Therefore, they strive to achieve your
          trust by providing unique design ideas and concepts for your business.
          <br />
          <br />
          Further, it has also received the best online logo design company
          reviews in the USA. In short, the clients are happy to work with the
          professional team of Uptown.
          <br />
          <br />
          <i>
            So, if you're ready to join Uptown's ultimate design journey, be
            prepared to experience creativity and professionalism at its finest.
          </i>
        </Typography>
      </Box>
            
      <Box sx={{ display: "flex", justifyContent: "center" }} padding={10} width={containerWidth} >
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src="https://www.uptownlogodesign.com/assets/images/testi-author-02.jpg"
                alt="logo"
                style={{
                  width: "120px", // Set width
                  height: "120px", // Maintain aspect ratio
                  borderRadius: "100%", // Make it circular
                  border: "2px solid #3eb489", // Add border
                }}
              />
              <Box
                bgcolor={"#f1f1f1"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                  width: "60%",
                  height: "150px",
                  overflow: "hidden",
                  padding: "3rem",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)", 
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily="Poppins"
                  fontSize={"0.8rem"}
                >
                  I was looking for Uptown logo design reviews and was
                  astonished by the positive feedbacks. I had to try this
                  agency. Finally, I did, and I am so happy with them. They are
                  nothing but the best.
                </Typography>
                <img
                  src={"/assets/reviwesImg/rating.png"}
                  alt="ratting"
                  style={{
                    width: "100px", // Set width
                    height: "100px", // Maintain aspect ratio
                    alignItems: "start",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src="https://www.uptownlogodesign.com/assets/images/testi-author-01.jpg"
                alt="logo"
                style={{
                  width: "100px", // Set width
                  height: "100px", // Maintain aspect ratio
                  borderRadius: "100%", // Make it circular
                  border: "2px solid #3eb489", // Add border
                }}
              />
              <Box
                bgcolor={"#f1f1f1"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                  width: "60%",
                  height: "150px",
                  overflow: "hidden",
                  padding: "3rem",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)", 
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily="Poppins"
                  fontSize={"0.8rem"}
                >
                  When you look for custom logo designers reviews, it gets
                  nothing but impossible to pick a brand. However, I chose
                  UptownLogoDesign as they have the most reliable comments. Well
                  their services are top-level. I am so proud of their work.
                </Typography>
                <img
                  src={"/assets/reviwesImg/rating.png"}
                  alt="ratting"
                  style={{
                    width: "120px", // Set width
                    height: "120px", // Maintain aspect ratio
                    alignItems: "start",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src="https://www.uptownlogodesign.com/assets/images/testi-author-01.jpg"
                alt="logo"
                style={{
                  width: "100px", // Set width
                  height: "100px", // Maintain aspect ratio
                  borderRadius: "100%", // Make it circular
                  border: "2px solid #3eb489", // Add border
                }}
              />
              <Box
                bgcolor={"#f1f1f1"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                  width: "60%",
                  height: "150px",
                  overflow: "hidden",
                  padding: "3rem",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)", 
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily="Poppins"
                  fontSize={"0.8rem"}
                >
                  When you look for custom logo designers reviews, it gets
                  nothing but impossible to pick a brand. However, I chose
                  UptownLogoDesign as they have the most reliable comments. Well
                  their services are top-level. I am so proud of their work.
                </Typography>
                <img
                  src={"/assets/reviwesImg/rating.png"}
                  alt="ratting"
                  style={{
                    width: "100px", // Set width
                    height: "100px", // Maintain aspect ratio
                    alignItems: "start",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src="https://www.uptownlogodesign.com/assets/images/testi-author-01.jpg"
                alt="logo"
                style={{
                  width: "100px", // Set width
                  height: "100px", // Maintain aspect ratio
                  borderRadius: "100%", // Make it circular
                  border: "2px solid #3eb489", // Add border
                }}
              />
              <Box
                bgcolor={"#f1f1f1"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                  width: "60%",
                  height: "200px",
                  overflow: "hidden",
                  padding: "3rem",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)", 
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily="Poppins"
                  fontSize={"0.8rem"}
                >
                  When you look for custom logo designers reviews, it gets
                  nothing but impossible to pick a brand. However, I chose
                  UptownLogoDesign as they have the most reliable comments. Well
                  their services are top-level. I am so proud of their work.
                </Typography>
                <img
                  src={"/assets/reviwesImg/rating.png"}
                  alt="ratting"
                  style={{
                    width: "100px", // Set width
                    height: "100px", // Maintain aspect ratio
                    alignItems: "start",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src="https://www.uptownlogodesign.com/assets/images/testi-author-01.jpg"
                alt="logo"
                style={{
                  width: "100px", // Set width
                  height: "100px", // Maintain aspect ratio
                  borderRadius: "100%", // Make it circular
                  border: "2px solid #3eb489", // Add border
                }}
              />
              <Box
                bgcolor={"#f1f1f1"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                  width: "60%",
                  height: "150px",
                  overflow: "hidden",
                  padding: "3rem",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)", 
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily="Poppins"
                  fontSize={"0.8rem"}
                >
                  When you look for custom logo designers reviews, it gets
                  nothing but impossible to pick a brand. However, I chose
                  UptownLogoDesign as they have the most reliable comments. Well
                  their services are top-level. I am so proud of their work.
                </Typography>
                <img
                  src={"/assets/reviwesImg/rating.png"}
                  alt="ratting"
                  style={{
                    width: "100px", // Set width
                    height: "100px", // Maintain aspect ratio
                    alignItems: "start",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src="https://www.uptownlogodesign.com/assets/images/testi-author-01.jpg"
                alt="logo"
                style={{
                  width: "100", // Set width
                  height: "100px", // Maintain aspect ratio
                  borderRadius: "100%", // Make it circular
                  border: "2px solid #3eb489", // Add border
                }}
              />
              <Box
                bgcolor={"#f1f1f1"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                  width: "60%",
                  height: "150px",
                  overflow: "hidden",
                  padding: "3rem",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)", 
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily="Poppins"
                  fontSize={"0.8rem"}
                >
                  When you look for custom logo designers reviews, it gets
                  nothing but impossible to pick a brand. However, I chose
                  UptownLogoDesign as they have the most reliable comments. Well
                  their services are top-level. I am so proud of their work.
                </Typography>
                <img
                  src={"/assets/reviwesImg/rating.png"}
                  alt="ratting"
                  style={{
                    width: "100px", // Set width
                    height: "100px", // Maintain aspect ratio
                    alignItems: "start",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
