// import {
//   Box,
//   Button,
//   Checkbox,
//   FormControlLabel,
//   Grid,
//   MenuItem,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useState } from "react";

// const RegistrationForm = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [country, setCountry] = useState("");
//   const [service, setService] = useState("");
//   const [message, setMessage] = useState("");
//   const [isAgreed, setIsAgreed] = useState(false);
//   const [errors, setErrors] = useState({
//     name: false,
//     email: false,
//     phoneNumber: false,
//     country: false,
//     service: false,
//     message: false,
//     isAgreed: false,
//   });

//   const handleCheckboxChange = (event: any) => {
//     setIsAgreed(event.target.checked);
//   };

//   const handleSubmit = (event: any) => {
//     event.preventDefault();

//     // Validation
//     const errorsCopy = { ...errors };
//     if (!name) {
//       errorsCopy.name = true;
//     } else {
//       errorsCopy.name = false;
//     }
//     if (!email) {
//       errorsCopy.email = true;
//     } else {
//       errorsCopy.email = false;
//     }
//     if (!phoneNumber) {
//       errorsCopy.phoneNumber = true;
//     } else {
//       errorsCopy.phoneNumber = false;
//     }
//     if (!country) {
//       errorsCopy.country = true;
//     } else {
//       errorsCopy.country = false;
//     }
//     if (!service) {
//       errorsCopy.service = true;
//     } else {
//       errorsCopy.service = false;
//     }
//     if (!message) {
//       errorsCopy.message = true;
//     } else {
//       errorsCopy.message = false;
//     }
//     if (!isAgreed) {
//       errorsCopy.isAgreed = true;
//     } else {
//       errorsCopy.isAgreed = false;
//     }

//     setErrors(errorsCopy);

//     // Submit if no errors
//     if (!Object.values(errorsCopy).includes(true)) {
//       const RegistrationData = {
//         name,
//         email,
//         phoneNumber,
//         country,
//         service,
//         message,
//         isAgreed,
//       };
//       console.log("Form submitted:", RegistrationData);

//       // Clear form fields
//       setName("");
//       setEmail("");
//       setPhoneNumber("");
//       setCountry("");
//       setService("");
//       setMessage("");
//       setIsAgreed(false);
//     }
//   };

//   const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "180%" : "inital";

//   return (
//     <>
//       <Box
//         display={"flex"}
//         flexDirection={"column"} // Ensure items are stacked vertically
//         justifyContent={"center"}
//         alignItems={"center"}
//         sx={{
//           backgroundColor: "#edf5e1",
//           // backgroundImage:
//             // "url(https://www.uptownlogodesign.com/assets/images/portf-bg.jpg)",
//           backgroundRepeat: "no-repeat",
//           backgroundSize: "cover",
//           padding: "40px",
//           width: containerWidth,
//         }}
//       >

//         <Box
//           padding={4}
//           boxShadow={"0px 8px 20px rgba(0, 0, 0, 0.8)"}
//           boxSizing={"border-box"}
//           borderRadius={"10px"}
//           display={"flex"}
//           flexDirection={"column"}
//           alignContent={"center"}
//           justifyContent={"center"}
//           maxWidth={"md"}
//           sx={{
//             backgroundColor: "white",
//           }}
//         >
//           <Typography
//             variant="h4"
//             sx={{ color: "#990304", fontWeight: "600", fontSize: "2.5rem" ,fontFamily:"Poppins", marginBottom: "1rem", textAlign: "center", alignContent: "center"}}
//           >
//             Request For Proposal
//           </Typography>
//           <Grid container spacing={5} padding={5}>
//             <Grid item xs={12} sm={8} md={12}>

//               <TextField
//                 fullWidth
//                 label="Name"
//                 id="fullWidth"
//                 size="medium"
//                 value={name}
//                 onChange={(e) => {
//                   setName(e.target.value);
//                 }}
//                 variant="outlined"
//                 error={errors.name}
//                 helperText={errors.name && "Please enter your name"}
//                 sx={{
//                   "& .MuiInputLabel-root": {
//                     color: "#898989",
//                     fontSize: "1.2rem", // Change label font size
//                     fontFamily: "Poppins", // Change label font family
//                   },
//                   "& .MuiOutlinedInput-root": {
//                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color when focused
//                     },
//                     "&:hover .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color on hover
//                     },
//                     "& .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Default border color
//                     },
//                     "& .MuiInputBase-input": {
//                       color: "#898989", // Change input text color
//                     },
//                     "& .MuiInputBase-input:hover": {
//                       color: "#5cdb95", fontFamily: "Poppins",  fontSize: "1rem", // Change input text color on hover
//                     },
//                     "& .MuiInputBase-input:focus": {
//                       color: "#333", // Change input text color when focused
//                     },
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} sm={8} md={12}>
//               <TextField
//                 fullWidth
//                 label="Email"
//                 id="fullWidth"
//                 size="medium"
//                 value={email}
//                 onChange={(e) => {
//                   setEmail(e.target.value);
//                 }}
//                 error={errors.email}
//                 helperText={errors.email && "Please enter your email"}
//                 sx={{
//                   "& .MuiInputLabel-root": {
//                     color: "#898989",
//                     fontSize: "1.2rem", // Change label font size
//                     fontFamily: "Poppins", // Change label font family
//                   },
//                   "& .MuiOutlinedInput-root": {
//                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95",
//                     },
//                     "&:hover .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color on hover
//                     },
//                     "& .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Default border color
//                     },
//                     "& .MuiInputBase-input": {
//                       color: "#898989", // Change input text color
//                     },
//                     "& .MuiInputBase-input:hover": {
//                       color: "#5cdb95",  fontFamily: "Poppins",  fontSize: "1rem",// Change input text color on hover
//                     },
//                     "& .MuiInputBase-input:focus": {
//                       color: "#333", // Change input text color when focused
//                     },
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} sm={8} md={12}>
//               <TextField
//                 fullWidth
//                 label="Phone Number"
//                 id="fullWidth"
//                 size="medium"
//                 value={phoneNumber}
//                 onChange={(e) => {
//                   // Remove non-numeric characters from the input value
//                   const formattedValue = e.target.value.replace(/\D/g, "");
//                   setPhoneNumber(formattedValue);
//                 }}
//                 error={errors.phoneNumber}
//                 helperText={
//                   errors.phoneNumber && "Please enter your phone number"
//                 }
//                 sx={{
//                   "& .MuiInputLabel-root": {
//                     color: "#898989",
//                     fontSize: "1.2rem", // Change label font size
//                     fontFamily: "Poppins", // Change label font family
//                   },
//                   "& .MuiOutlinedInput-root": {
//                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color when focused
//                     },
//                     "&:hover .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color on hover
//                     },
//                     "& .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Default border color
//                     },
//                     "& .MuiInputBase-input": {
//                       color: "#898989", // Change input text color
//                     },
//                     "& .MuiInputBase-input:hover": {
//                       color: "#5cdb95", fontFamily: "Poppins",  fontSize: "1rem", // Change input text color on hover
//                     },
//                     "& .MuiInputBase-input:focus": {
//                       color: "#333", // Change input text color when focused
//                     },
//                   },
//                 }}
//                 onKeyPress={(e) => {
//                   // Allow only numbers and backspace/delete keys
//                   const allowedKeys = /[0-9\b]/;
//                   if (!allowedKeys.test(e.key)) {
//                     e.preventDefault();
//                   }
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} sm={8} md={12}>
//               <TextField
//                 fullWidth
//                 id="fullWidth"
//                 label="Country"
//                 size="medium"
//                 select
//                 value={country}
//                 onChange={(e) => {
//                   setCountry(e.target.value);
//                 }}
//                 error={errors.country}
//                 helperText={errors.country && "Please select your country"}
//                 sx={{
//                   "& .MuiInputLabel-root": {
//                     color: "#898989",
//                     fontSize: "1.2rem", // Change label font size
//                     fontFamily: "Poppins", // Change label font family
//                   },
//                   "& .MuiOutlinedInput-root": {
//                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color when focused
//                     },
//                     "&:hover .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color on hover
//                     },
//                     "& .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Default border color
//                     },
//                     "& .MuiInputBase-input": {
//                       color: "#898989", // Change input text color
//                     },
//                     "& .MuiInputBase-input:hover": {
//                       color: "#5cdb95", fontFamily: "Poppins",  fontSize: "1rem", // Change input text color on hover
//                     },
//                     "& .MuiInputBase-input:focus": {
//                       color: "#333", // Change input text color when focused
//                     },
//                   },
//                 }}
//               >
//                 <MenuItem sx={{ fontFamily: "Poppins" }} value="United States of America">
//                   United States of America
//                 </MenuItem>
//                 <MenuItem sx={{ fontFamily: "Poppins" }} value="Canada">Canada</MenuItem>
//                 <MenuItem sx={{ fontFamily: "Poppins" }} value="UK">UK</MenuItem>
//                 {/* Add more countries as needed */}
//               </TextField>
//             </Grid>
//             <Grid item xs={12} sm={8} md={12}>
//               <TextField
//                 fullWidth
//                 id="fullWidth"
//                 label="Services"
//                 size="medium"
//                 value={service}
//                 onChange={(e) => {
//                   setService(e.target.value);
//                 }}
//                 select
//                 error={errors.service}
//                 helperText={errors.service && "Please select a service"}
//                 sx={{
//                   "& .MuiInputLabel-root": {
//                     color: "#898989",
//                     fontSize: "1.2rem", // Change label font size
//                     fontFamily: "Poppins", // Change label font family
//                   },
//                   "& .MuiOutlinedInput-root": {
//                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color when focused
//                     },
//                     "&:hover .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color on hover
//                     },
//                     "& .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Default border color
//                     },
//                     "& .MuiInputBase-input": {
//                       color: "#898989", fontFamily: "Poppins",  fontSize: "1rem", // Change input text color
//                     },
//                     "& .MuiInputBase-input:hover": {
//                       color: "#5cdb95", // Change input text color on hover
//                     },
//                     "& .MuiInputBase-input:focus": {
//                       color: "#333", // Change input text color when focused
//                     },
//                   },
//                 }}
//               >
//                 <MenuItem value="Logo Design">Logo Design</MenuItem>
//                 <MenuItem value="Web Site">Web Site</MenuItem>
//                 <MenuItem value="Mobile Application">
//                   Mobile Application
//                 </MenuItem>
//                 <MenuItem value="Branding">Branding</MenuItem>
//                 <MenuItem value="Animation">Animation</MenuItem>
//               </TextField>
//             </Grid>
//             <Grid item xs={12} sm={8} md={12}>
//               <TextField
//                 fullWidth
//                 id="outlined-multiline-static"
//                 label="Message"
//                 multiline
//                 rows={3}
//                 value={message}
//                 onChange={(e) => {
//                   setMessage(e.target.value);
//                 }}
//                 error={errors.message}
//                 helperText={errors.message && "Please enter your message"}
//                 sx={{
//                   "& .MuiInputLabel-root": {
//                     color: "#898989",
//                     fontSize: "1.2rem", // Change label font size
//                     fontFamily: "Poppins", // Change label font family
//                   },
//                   "& .MuiOutlinedInput-root": {
//                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color when focused
//                     },
//                     "&:hover .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Change border color on hover
//                     },
//                     "& .MuiOutlinedInput-notchedOutline": {
//                       borderColor: "#5cdb95", // Default border color
//                     },
//                     "& .MuiInputBase-input": {
//                       color: "#898989", // Change input text color
//                     },
//                     "& .MuiInputBase-input:hover": {
//                       color: "#5cdb95",  fontFamily: "Poppins",  fontSize: "1rem",// Change input text color on hover
//                     },
//                     "& .MuiInputBase-input:focus": {
//                       color: "#333", // Change input text color when focused
//                     },
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} sm={12} md={8}>
//               <FormControlLabel
//                 required
//                 control={
//                   <Checkbox
//                     checked={isAgreed}
//                     onChange={handleCheckboxChange}
//                     size="medium"
//                     sx={{
//                       fontFamily: "Poppins",
//                       color: errors.isAgreed ? "#f44336" : "#5cdb95", // Change the color based on errors
//                       "&.Mui-checked": {
//                         color: errors.isAgreed ? "#f44336" : "#5cdb95", // Change the color based on errors
//                       },
//                     }}
//                   />
//                 }
//                 label="I agree to the terms"
//               />
//             </Grid>
//             <Grid item xs={12} sm={12} md={8}>
//               <Button
//                 variant="contained"
//                 disabled={!isAgreed}
//                 onClick={handleSubmit}
//                 sx={{ backgroundColor: "#5cdb95", color: "white", fontFamily: "Poppins",  fontSize: "1rem", }}
//               >
//                 Submit
//               </Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default RegistrationForm;

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const RegistrationForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    country: false,
    service: false,
    message: false,
    isAgreed: false,
  });

  const handleCheckboxChange = (event: any) => {
    setIsAgreed(event.target.checked);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    // Validation
    const errorsCopy = { ...errors };
    if (!name) {
      errorsCopy.name = true;
    } else {
      errorsCopy.name = false;
    }
    if (!email) {
      errorsCopy.email = true;
    } else {
      errorsCopy.email = false;
    }
    if (!phoneNumber) {
      errorsCopy.phoneNumber = true;
    } else {
      errorsCopy.phoneNumber = false;
    }
    if (!country) {
      errorsCopy.country = true;
    } else {
      errorsCopy.country = false;
    }
    if (!service) {
      errorsCopy.service = true;
    } else {
      errorsCopy.service = false;
    }
    if (!message) {
      errorsCopy.message = true;
    } else {
      errorsCopy.message = false;
    }
    if (!isAgreed) {
      errorsCopy.isAgreed = true;
    } else {
      errorsCopy.isAgreed = false;
    }

    setErrors(errorsCopy);

    // Submit if no errors
    if (!Object.values(errorsCopy).includes(true)) {
      const RegistrationData = {
        name,
        email,
        phoneNumber,
        country,
        service,
        message,
        isAgreed,
      };
      console.log("Form submitted:", RegistrationData);

      // Clear form fields
      setName("");
      setEmail("");
      setPhoneNumber("");
      setCountry("");
      setService("");
      setMessage("");
      setIsAgreed(false);
    }
  };

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "100%";

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: containerWidth,
        }}
      >
        <Grid container spacing={2}>
          {/* Left side for the form */}
          <Grid item xs={0} md={6} sm={6} lg={6}>
            <Box
              p={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src="https://img.freepik.com/free-photo/happy-caucasian-female-call-centre-worker-smiling-office_1098-17815.jpg?t=st=1714435615~exp=1714439215~hmac=b9d33557e588e9d741fc2d2cdcf07326d13be284e59bf31ca5bfdd32dca72b73&w=996"
                alt="Image"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          </Grid>
          {/* Right side for the image */}
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <Box
              padding={5}
              // boxShadow={"0px 8px 20px rgba(0, 0, 0, 0.8)"}
              boxSizing={"border-box"}
              // borderRadius={"10px"}
              display={"flex"}
              flexDirection={"column"}
              alignContent={"center"}
              justifyContent={"center"}
              // maxWidth={"md"}
              width={"100%"}
              height={"100%"}
              sx={
                {
                  // backgroundColor: "white",
                }
              }
            >
              <Typography
                variant="h1"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontSize: "2rem",
                  fontFamily: "Poppins",
                  marginBottom: "1rem",
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                Request For Proposal
              </Typography>
              <Grid container spacing={4} padding={2}>
                <Grid item xs={12} sm={8} md={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    id="fullWidth"
                    size="small"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    variant="outlined"
                    error={errors.name}
                    helperText={errors.name && "Please enter your name"}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#898989",
                        fontSize: "1rem", // Change label font size
                        fontFamily: "Poppins", // Change label font family
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color when focused
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color on hover
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Default border color
                        },
                        "& .MuiInputBase-input": {
                          color: "#898989", // Change input text color
                        },
                        "& .MuiInputBase-input:hover": {
                          color: "#990304",
                          fontFamily: "Poppins",
                          fontSize: "1rem", // Change input text color on hover
                        },
                        "& .MuiInputBase-input:focus": {
                          color: "#333", // Change input text color when focused
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    id="fullWidth"
                    size="small"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    error={errors.email}
                    helperText={errors.email && "Please enter your email"}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#898989",
                        fontSize: "1rem", // Change label font size
                        fontFamily: "Poppins", // Change label font family
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color on hover
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Default border color
                        },
                        "& .MuiInputBase-input": {
                          color: "#898989", // Change input text color
                        },
                        "& .MuiInputBase-input:hover": {
                          color: "#990304",
                          fontFamily: "Poppins",
                          fontSize: "1rem", // Change input text color on hover
                        },
                        "& .MuiInputBase-input:focus": {
                          color: "#333", // Change input text color when focused
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    id="fullWidth"
                    size="small"
                    value={phoneNumber}
                    onChange={(e) => {
                      // Remove non-numeric characters from the input value
                      const formattedValue = e.target.value.replace(/\D/g, "");
                      setPhoneNumber(formattedValue);
                    }}
                    error={errors.phoneNumber}
                    helperText={
                      errors.phoneNumber && "Please enter your phone number"
                    }
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#898989",
                        fontSize: "1rem", // Change label font size
                        fontFamily: "Poppins", // Change label font family
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color when focused
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color on hover
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Default border color
                        },
                        "& .MuiInputBase-input": {
                          color: "#898989", // Change input text color
                        },
                        "& .MuiInputBase-input:hover": {
                          color: "#990304",
                          fontFamily: "Poppins",
                          fontSize: "1rem", // Change input text color on hover
                        },
                        "& .MuiInputBase-input:focus": {
                          color: "#333", // Change input text color when focused
                        },
                      },
                    }}
                    onKeyPress={(e) => {
                      // Allow only numbers and backspace/delete keys
                      const allowedKeys = /[0-9\b]/;
                      if (!allowedKeys.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                  <TextField
                    fullWidth
                    id="fullWidth"
                    label="Country"
                    size="small"
                    select
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    error={errors.country}
                    helperText={errors.country && "Please select your country"}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#898989",
                        fontSize: "1rem", // Change label font size
                        fontFamily: "Poppins", // Change label font family
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color when focused
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color on hover
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Default border color
                        },
                        "& .MuiInputBase-input": {
                          color: "#898989", // Change input text color
                        },
                        "& .MuiInputBase-input:hover": {
                          color: "#990304",
                          fontFamily: "Poppins",
                          fontSize: "1rem", // Change input text color on hover
                        },
                        "& .MuiInputBase-input:focus": {
                          color: "#333", // Change input text color when focused
                        },
                      },
                    }}
                  >
                    <MenuItem
                      sx={{ fontFamily: "Poppins" }}
                      value="United States of America"
                    >
                      United States of America
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "Poppins" }} value="Canada">
                      Canada
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "Poppins" }} value="UK">
                      UK
                    </MenuItem>
                    {/* Add more countries as needed */}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={8} md={12}>
                  <TextField
                    fullWidth
                    id="fullWidth"
                    label="Services"
                    size="small"
                    value={service}
                    onChange={(e) => {
                      setService(e.target.value);
                    }}
                    select
                    error={errors.service}
                    helperText={errors.service && "Please select a service"}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#898989",
                        fontSize: "1rem", // Change label font size
                        fontFamily: "Poppins", // Change label font family
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color when focused
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color on hover
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Default border color
                        },
                        "& .MuiInputBase-input": {
                          color: "#898989",
                          fontFamily: "Poppins",
                          fontSize: "1rem", // Change input text color
                        },
                        "& .MuiInputBase-input:hover": {
                          color: "#990304", // Change input text color on hover
                        },
                        "& .MuiInputBase-input:focus": {
                          color: "#333", // Change input text color when focused
                        },
                      },
                    }}
                  >
                    <MenuItem value="Logo Design">Logo Design</MenuItem>
                    <MenuItem value="Web Site">Web Site</MenuItem>
                    <MenuItem value="Mobile Application">
                      Mobile Application
                    </MenuItem>
                    <MenuItem value="Branding">Branding</MenuItem>
                    <MenuItem value="Animation">Animation</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={8} md={12}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Message"
                    multiline
                    rows={5}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    error={errors.message}
                    helperText={errors.message && "Please enter your message"}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#898989",
                        fontSize: "1rem", // Change label font size
                        fontFamily: "Poppins", // Change label font family
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color when focused
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Change border color on hover
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#990304", // Default border color
                        },
                        "& .MuiInputBase-input": {
                          color: "#898989", // Change input text color
                        },
                        "& .MuiInputBase-input:hover": {
                          color: "#990304",
                          fontFamily: "Poppins",
                          fontSize: "1rem", // Change input text color on hover
                        },
                        "& .MuiInputBase-input:focus": {
                          color: "#333", // Change input text color when focused
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={isAgreed}
                        onChange={handleCheckboxChange}
                        size="small"
                        sx={{
                          fontFamily: "Poppins",
                          color: errors.isAgreed ? "#f44336" : "#990304", // Change the color based on errors
                          "&.Mui-checked": {
                            color: errors.isAgreed ? "#f44336" : "#990304", // Change the color based on errors
                          },
                        }}
                      />
                    }
                    label="I agree to the terms"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    disabled={!isAgreed}
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: "#990304",
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "1.2rem",
                      paddingX: "3rem",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "#990304",
                        border: "1px solid #990304",
                        color: "#990304",
                        boxShadow: "none",
                      },
                      "&:hover .MuiButton-label": {
                        color: "#990304",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RegistrationForm;
