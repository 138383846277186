import React, { useRef, useState } from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { click } from "@testing-library/user-event/dist/click";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface OverviewGalleryProps {
  title: string;
  subtitle: string;
  portfolioLink?: string;
  mediaLinks: (string | null)[]; // Array of image URLs or video URLs
  backgroundColor?: string;
}

const OverviewGallery: React.FC<OverviewGalleryProps> = ({
  title,
  subtitle,
  portfolioLink,
  mediaLinks,
  backgroundColor = "#F1F1F1",
}) => {
  const [selectedMedia, setSelectedMedia] = useState<string | null>(null);
  const [hoveredMedia, setHoveredMedia] = useState<string | null>(null);
  const containerRef1 = useRef<HTMLDivElement>(null);
  const containerRef2 = useRef<HTMLDivElement>(null);


  const handleMediaClick = (media: string | null) => {
    setSelectedMedia(media);
  };

  const handleCloseMedia = () => {
    setSelectedMedia(null);
  };

  const scrollLeft = () => {
    if (containerRef1.current && containerRef2.current) {
      containerRef1.current.scrollBy({
        left: -370,
        behavior: "smooth",
      });
      containerRef2.current.scrollBy({
        left: -370,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (containerRef1.current && containerRef2.current) {
      containerRef1.current.scrollBy({
        left: 370,
        behavior: "smooth",
      });
      containerRef2.current.scrollBy({
        left: 370,
        behavior: "smooth",
      });
    }
  };


  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "inital";

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        padding: "40px",
        boxSizing: "border-box",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        width: containerWidth,
      }}
    >
      <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography
          variant="h4"
          sx={{
            color: "#990304",
            marginBottom: "10px",
            fontSize:{xs:"2rem",sm:"2rem" , md:"2rem"},
            fontWeight: "600",
            fontFamily: "Poppins",
          }}
        >
          {title}
        </Typography>
        <Typography variant="h6" sx={{ color: "black", fontFamily: "Poppins" }}>
          {subtitle}
        </Typography>
      </Box>
      <Box sx={{ overflowX: "hidden" }}>
        <Box
        ref={containerRef1}
          sx={{
            marginY: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: containerWidth === "inital" ? "60px" : "80px",
            marginX: containerWidth === "inital" ? "7rem" : "14rem",
            width: containerWidth === "inital" ? "85%" : "45%",
            overflowX: "auto",
            maxHeight: "90%", // Adjust this value according to your needs
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              // Hide scrollbar
              display: "none",
            },
          }}
        >
          {mediaLinks.map((media, index) => (
            <Box
              key={index}
              sx={{
                flex: "0 0 auto",
                marginRight: "0px",
                position: "relative",
                transition: "transform 0.3s",
                ":hover": {
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1,
                    cursor: "pointer",
                  },
                  transform: "scale(1.1)",
                },
              }}
              onMouseEnter={() => setHoveredMedia(media)}
              onMouseLeave={() => setHoveredMedia(null)}
              onClick={() => handleMediaClick(media)}
            >
              {media && media.includes("youtube.com") ? (
                <iframe
                  width="300"
                  height="300"
                  src={media}
                  frameBorder="0"
                  allowFullScreen
                  title={`Video ${index + 1}`}
                />
              ) : (
                <img
                  src={media as string}
                  alt={`Media ${index + 1}`}
                  style={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "200px",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
              )}
              {hoveredMedia === media && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 2,
                  }}
                  onClick={() => handleMediaClick(media)}
                >
                  <ZoomInIcon
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <IconButton onClick={scrollLeft}>
          <ArrowBackIosNewIcon sx={{ color: "#990304", fontSize: "3rem" }} />
        </IconButton>
        <IconButton onClick={scrollRight}>
          <ArrowForwardIosIcon sx={{ color: "#990304", fontSize: "3rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ overflowX: "hidden" }}>
        <Box
        ref={containerRef2}
          sx={{
            marginY: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "nowrap",
            paddingX: "5px",
            gap: containerWidth === "inital" ? "60px" : "80px",
            marginX: containerWidth === "inital" ? "7rem" : "14rem",
            width: containerWidth === "inital" ? "85%" : "45%",
            overflowX: "auto",
            maxHeight: "90%", // Adjust this value according to your needs
            WebkitOverflowScrolling: "touch", // Enable smooth scrolling on iOS
            "&::-webkit-scrollbar": {
              // Hide scrollbar
              display: "none",
            },
          }}
        >
          {mediaLinks.map((media, index) => (
            <Box
              key={index}
              sx={{
                flex: "0 0 auto",
                marginRight: "0px",
                position: "relative",
                transition: "transform 0.3s",
                ":hover": {
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1,
                    cursor: "pointer",
                  },
                  transform: "scale(1.1)",
                },
              }}
              onMouseEnter={() => setHoveredMedia(media)}
              onMouseLeave={() => setHoveredMedia(null)}
              onClick={() => handleMediaClick(media)}
            >
              {media && media.includes("youtube.com") ? (
                <iframe
                  width="400"
                  height="300"
                  src={media}
                  frameBorder="0"
                  allowFullScreen
                  title={`Video ${index + 1}`}
                />
              ) : (
                <img
                  src={media as string}
                  alt={`Media ${index + 1}`}
                  style={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "200px",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
              )}
              {hoveredMedia === media && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 2,
                  }}
                  onClick={() => handleMediaClick(media)}
                >
                  <ZoomInIcon
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "3rem",
                    }}
                  />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      {selectedMedia && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            borderRadius: "12px",
          }}
          onClick={handleCloseMedia}
        >
          {selectedMedia.includes("youtube.com") ? (
            <iframe
              width="90%"
              height="90%"
              src={selectedMedia}
              frameBorder="0"
              allowFullScreen
              title="Selected Video"
            />
          ) : (
            <img
              src={selectedMedia}
              alt="Selected Media"
              style={{
                maxWidth: "90%",
                maxHeight: "90%",
                objectFit: "contain",
              }}
            />
          )}
        </Box>
      )}
      {portfolioLink && (
        <Box sx={{ textAlign: "center", marginY: "2rem" }}>
          <Link
            component={RouterLink}
            to={portfolioLink}
            underline="hover"
            sx={{
              color: "#990304",
              fontSize: "2rem",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Visit Our Portfolio
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default OverviewGallery;
