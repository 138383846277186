import React, { useEffect, useState } from "react";
import OverviewSection from "../../components/OverviewSection";
import OverviewBenefits from "../../components/OverviewBenefits";
import OverViewDetails from "../../components/OverViewDetails";
import OverviewGallery from "../../components/OverviewGallery";
import { Box, Typography } from "@mui/material";
import SelectUs from "../../components/selectUs";
import { Navigate, useNavigate } from "react-router-dom";
import Brosheres from "../../components/Brosheres";
import LeadDesignOverviewPack from "../../components/LeadDesignOverviewPack";
import OverviewLogoDesignStepper from "../../components/OverviewLogoDesignStappers";
import MoreIndustriesOverview from "../../components/MoreIndustriesOverview";
import Reviews from "../../components/Reviews";
import RegistrationForm from "../../components/RegistrationForm";
import benafitImg01 from "..//..//Assets/benefitsImages/logo-pic-01.png"
import benafitImg02 from "..//..//Assets/benefitsImages/logo-pic-02.png"
import benafitImg03 from "..//..//Assets/benefitsImages/logo-pic-03.png"
import brosherImg01 from "../..//Assets/keyFeatures/guarantee.png"
import keyFeatureImg01 from "../../Assets//keyFeatures/guarantee.png"
import keyFeatureImg02 from "../../Assets//keyFeatures/money-back-guarantee.png"
import keyFeatureImg03 from "../../Assets//keyFeatures/think-outside-the-box.png"
import keyFeatureImg04 from "../../Assets//keyFeatures/video-call.png"
import keyFeatureImg05 from "../../Assets//keyFeatures/trophy.png"
import step01 from "../../Assets/stappers/stappers1.gif"
import step02 from "..//..//Assets/stappers/stappers2.gif"
import step03 from "../../Assets/stappers/stappers3.gif"
import step04 from "../../Assets/stappers/stappers4.gif"
import step05 from "../../Assets/stappers/stappers5.gif"
import step06 from "../../Assets/stappers/stappers6.gif"
import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"

const OverviewBranding = () => {
  const cardsData = [
    {
      title: "Stationary Design",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      buttonText: "",
    },
    {
      title: "Adeversemnt",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      buttonText: "",
    },
    {
      title: "Print and Packaging",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      buttonText: "",
    },
    {
      title: "Social Media Design",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      buttonText: "",
    },
  ];
  const handleButtonClick = () => {
    // history.push("");
  };

  //image for gallery

  const imageGallery = [
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/07.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/05.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/06.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/08.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/11.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/02.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/03.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/04.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/branding/12.jpg",
  ];

  //for packages render
  const Navigate = useNavigate();
  const handlePackages = () => {
    Navigate("/branding/packages");
  };

  //for Brosher and packeges
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const box = document.getElementById("animated-box");
      if (box) {
        const rect = box.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight - 100;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //for stapper
  const steps = [
    {
      step: "Research",
      content:
        "Based on the client`s requisitions, our team of highly specialized professionals gleans and researches the trending norms of the industry. The extensive research regime allows our professional logo designers to curate, and handpick the very essential, yet subtle, elements necessary to transmute the particulars into unique and creative logo designs.",
      imageUrl: step01
    },
    {
      step: "Ideate",
      content:
        "With the content stash full, our professional logo designers conceive a couple of logo design concepts to share with the client. Once scrutinized, the concepts are narrowed down to a single logo design, which is then worked upon throughout the process.",
      imageUrl: step02
    },
    {
      step: "Create",
      content:
        "With the particulars decided, our team of professional logo designers proceeds to craft a preliminary design draft. The draft is then shared with the client, revised if necessary, and later perfected, to create the awe-inspiring logo design that sets the foundation of your marketing stratagems.",
      imageUrl: step03
    },
    {
      step: "Color",
      content:
        "Colors impact emotions and our specialized logo designers make sure that impact lasts an eternity. Meticulously selecting a number of unique color systems as per the brand`s identity, our professional logo designers add life to the client`s logo design",
      imageUrl: step04
    },
    {
      step: "Revision",
      content:
        "Perfection escapes humanity, and thus the revision comes in. If the client doesn’t get completely satisfied with the custom logo design, Uptown Logo Design upholds its promise, and allows tweaking of the logo design elements, until 100% client-satisfaction is achieved.",
      imageUrl: step05
    },
    {
      step: "Finalize",
      content:
        "With the logo finalized, the revisions done (if any), and 100% client-satisfaction achieved, the team moves towards finalizing the entire project. The royalty-free custom logo design is delivered as per the agreed terms, and the process of meticulously crafting the client`s custom logo design comes to an end.",
      imageUrl: step06
    },
  ];

  //for more industriers

  const topics = [
    "Enterprise",
    "B2B",
    "B2C",
    "E-commerce",
    "Education",
    "N0-Profit",
    "Startups",
  ];
  
  //for Reviews

  const ReviewsSlidingimages = [
    // "/assets/BrandImg/01.png",
    "/assets/BrandImg/02.png",
    "/assets/BrandImg/03.png",
    "/assets/BrandImg/04.png",
    "/assets/BrandImg/05.png",
    "/assets/BrandImg/06.png",
    "/assets/BrandImg/07.png",
    "/assets/BrandImg/08.png",

    // Add more logo URLs as needed
  ];

  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "initial";

  return (
    <>
      <Box style={{ width: "100%", height: "100%", marginTop: "160px" }}>
        <OverviewSection
          title1="Elevate Your Brand"
          subTitle="With Unique And Trendy Designs."
          listItems={[
            "100% Ownership Rights",
            "Brochure Design",
            "Stationery Design",
            "Social Media Banners",
            "T-Shirt Design",
          ]}
          reverseLayout={true}
          bgImage="https://www.uptownlogodesign.com/assets/images/banners/branding.jpg"
        />
        <OverviewBenefits
          title="Branding Benefits"
          subtitles={["Subtitle 1", "Subtitle 2", "Subtitle 3"]}
          paragraphs={[
            " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
          ]}
          images={[
            benafitImg01,
            benafitImg02,
            benafitImg03,
          ]}
        />
        <OverViewDetails
          mainHeading="Type Of Branding Design"
          mainSubheading="We Craft A Strategy Which Seeks Attention And Leaves An Impact On The Target Audience."
          cardsData={cardsData}
          onButtonClick={handleButtonClick}
        />

        <OverviewGallery
          title={"A Glimpse Into Our Award-Winning Logo Portfolio"}
          subtitle={
            "Having Spent Years In The Industry, We Have A Wide Portfolio To Talk About"
          }
          portfolioLink={"/branding/portfolio"}
          mediaLinks={imageGallery}
          backgroundColor="#f1f1f1"
        />

        <Box
          sx={{
            boxShadow: {
              xs: "none",
              md: "0px 1px 20px rgba(0, 0, 0.4, 0.4)",
            },
          }}
        >
          <SelectUs
            buttonText1={"Select Package"}
            buttonText2={"Live chat"}
            onClickVoid={handlePackages}
            title={"Ready To Select Your Package ?"}
            subtitle={"Or Talk To Our Design Consultant"}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f1f1f1",
            width:containerWidth,
            
          }}
        >
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              Maxwidth: "100%",
              marginTop: "50px",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#990304",
                fontSize: "2rem",
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "10px",
                fontFamily: "Poppins",
              }}
            >
              Wide Range Of Affordable Logo Design Packages
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: "#292323",
                textAlign: "center",
                marginBottom: "20px",
                fontFamily: "Poppins",
                fontSize: "1rem",
              }}
            >
              Premium branding packages designed to meet your needs.
            </Typography>
            <Box
              style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%"  }}
            >
              {/* <ArrowBackIosIcon
              onClick={handleScrollBack}
              style={{ cursor: "pointer", fontSize: "5rem", color: "#3eb489" }}
            /> */}
              <div
                style={{
                  display: "flex",
                  gap: "0px", // Add some space between items
                  overflowX: "auto", // Enable horizontal scrolling
                  maxWidth: containerWidth,
                  marginTop: "-3rem",
                  paddingTop: "3rem",
                  paddingBottom: "3rem",
                }}
                // onMouseEnter={ScrollMouseEnter}
                // onMouseLeave={ScrollMouseLeave}
                // ref={brosheresRef}
              >
                {/* Container for the first set of three Brosheres */}
                <div
                  style={{ display: "flex", flexWrap: "nowrap", gap: "25px", justifyContent: "center", paddingLeft: "1rem" }}
                >
                  <>
                    <Box
                      width={{
                        xs: "100%",
                        sm: "calc(30% - 10px)",
                        md: "calc(33.33% - 10px)",
                        lg: "calc(33.33% - 10px)",
                      }}
                      mb={"1rem"}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Brosheres
                        title1="Best seller"
                        title2="Smart design solution"
                        listItems={[
                          "List Item 1",
                          "List Item 2",
                          "List Item 3",
                          "List Item 4",
                          "List Item 5",
                        ]}
                        amount="$39"
                        imageSrc= {brosherImg01}
                      />
                    </Box>
                    <Box
                      width={{
                        xs: "100%",
                        sm: "calc(50% - 10px)",
                        md: "calc(33.33% - 10px)",
                        lg: "calc(33.33% - 10px)",
                      }}
                      mb={"1rem"}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Brosheres
                        title1="The Ultimate"
                        title2="Cutting-edge Design"
                        listItems={[
                          "List Item 1",
                          "List Item 2",
                          "List Item 3",
                          "List Item 4",
                          "List Item 5",
                        ]}
                        amount="$68"
                        imageSrc= {brosherImg01}
                      />
                    </Box>

                    <Box
                      width={{
                        xs: "100%",
                        sm: "calc(50% - 10px)",
                        md: "calc(33.33% - 10px)",
                        lg: "calc(33.33% - 10px)",
                      }}
                      mb={"1rem"}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Brosheres
                        title1="Business Plus"
                        title2="Ideal design solutions"
                        listItems={[
                          "List Item 1",
                          "List Item 2",
                          "List Item 3",
                          "List Item 4",
                          "List Item 5",
                        ]}
                        amount="$119"
                        imageSrc= {brosherImg01}
                      />
                    </Box>
                  </>
                </div>
              </div>
              {/* <ArrowForwardIosIcon
              onClick={handleScrollForward}
              style={{ cursor: "pointer", fontSize: "5rem", color: "#3eb489" }}
            /> */}
            </Box>

            {/* Add custom scrollbar styling */}
            <style>
              {`
        ::-webkit-scrollbar {
          display: none; /* Hide scrollbar */
        }
      `}
            </style>

            <Box
              justifyContent={"center"}
              sx={{
                marginTop: "-30px",
                display: {
                  xs: "none",
                  sm: "flex",
                  lg: "flex",
                  xl: "flex",
                  md: "flex",
                },
              }}
              color={"#990304"}
              fontSize={"1.2rem"}
              fontFamily={"Poppins"}
            >
              {" "}
              Key Feature
            </Box>
            <Box
              id="animated-box"
              justifyContent={"center"}
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  lg: "flex",
                  xl: "flex",
                  md: "flex",
                },
                marginTop: "20px",
                color: "#990304",
                fontSize: "0.8rem",
                position: "relative",
                paddingBottom: "20px",
                left: isVisible ? "0" : "-100%", // Initial position outside the viewport
                transition: "left 0.5s ease", // Animation transition
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "8rem",
                }}
              >
                <img
                  src= {keyFeatureImg01}
                  alt="Image 1"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  100% Setisfaction
                  Gurentee
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "8rem",
                }}
              >
                <img
                  src= {keyFeatureImg03}
                  alt="Image 2"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Unique logo
                  design
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "8rem",
                }}
              >
                <img
                  src= {keyFeatureImg02}
                  alt="Image 3"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  100% money Back 
                  Gurentee
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "8rem",
                }}
              >
                <img
                  src= {keyFeatureImg04}
                  alt="Image 3"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  24/4 Design 
                  Consultancy
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "0",
                }}
              >
                <img
                  src= {keyFeatureImg05}
                  alt="Image 3"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textOrientation: "mixed",
                    textAlign: "center",
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Award Winning
                  Designers
                </Typography>
              </div>
              {/* Repeat this structure for additional image and quote combinations */}
            </Box>
          </Box>
        </Box>
        <LeadDesignOverviewPack
          title={"Lead Design All in one"}
          subtitle={"The Comprehensive Branding Solution"}
          paragraph={
            "Starting from scratch or revamping your brand`s existence? Uptown Logo Design brings forth the very package that gets your business rolling seamlessly. From providing a custom logo design to asserting your brand`s digital presence across all the major platforms, the “All in One” proves to be the singularly comprehensive solution to all your branding predicaments"
          }
          list={[
            "List Item 1",
            "List Item 2",
            "List Item 3",
            "List Item 4",
            "List Item 5",
            "List Item 6",
          ]}
          amount="$100"
        />

        <Box width={"99vw"}>
          <OverviewLogoDesignStepper
            title={"Our Seamless Logo Designing Process"}
            subtitle={"You Relax, While We Do All The Work. It`S This Simple."}
            steps={steps}
          />
        </Box>
        <MoreIndustriesOverview
          title="Over 200 Industries Served And Growing"
          subtitle="Through Our Dynamic Logo Design Services, We Have Meritoriously Served Clients Emanating From Every Industry"
          topics={topics}
          listItems={[
            "Accounting",
            "Agriculture & Construction",
            "Automative & Transportation",
            "Clearing & Maintainance",
            "Computer & Technology",
            "Education",
            "Fashion",
            "Industrial Logo Design",
            "Photography",
            "Restaurants",
            "Sports",
            "Technology",
            "Argricultural",
            "Art & Culture",
            "Bussiness Consulting",
            "Communications",
            "Constructing tools",
            "Entertainment",
            "Floral Plans",
            "Landscaping Logo Design",
            "Physical Fitness",
            "Real Estate",
            "Spa-ethistic",
            "Wedding Services",
            "Animal & Pets",
            "Attorney & Law",
            "Child Care",
            "Community",
            "Cosmatics & Beauty",
            "Food & Beverages",
            "Medical & Phrmaceutical",
            "Security",
            "Traval % Hotel",
            "Enviromental",
            "Real State & Mortgage",
            "Food & Drinks",
          ]}
        />

        <Reviews
          subtitle={
            "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
          }
          heading={"Look What Our Customers Have Said About Us"}
          paragraphs={[
            "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
            "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
          ]}
          reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}  
      />
        <RegistrationForm />
      </Box>
    </>
  );
};

export default OverviewBranding;
