import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewSection from "../../components/OverviewSection";
import DownloadGuide from "../../components/DownloadGuide";
import SelectUs from "../../components/selectUs";

const Logo101LogoDesign = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://www.uptownlogodesign.com/assets/images/choose-slide1.png",
    "https://www.uptownlogodesign.com/assets/images/choose-slide2.jpg",
    "https://www.uptownlogodesign.com/assets/images/choose-slide3.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handlePackages = () => {
    window.location.href = "logo-design/packages";
  };

  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "180%" : "initial";

  return (
    <Box
      width="100%"
      height="100%"
      marginTop={containerWidth === "initial" ? "160px" : "0"}
    >
      <OverviewSection
        title1="Empower Your Brand With Custom Logo Designs"
        subTitle="Build Your Brand And Your Business - With The
        Right Website!"
        subTitleColor="#990304"
        listItems={[
          "Logo Design",
          "Business Cards",
          "Business Logos",
          "Company Logos",
        ]}
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/logo-guide-101.jpg"
        reverseLayout={true}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"3rem"}
        width={containerWidth}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography
            variant="h1"
            color={"#990304"}
            fontWeight={"600"}
            fontSize={"2rem"}
            margin={"20px"}
            fontFamily="Poppins"
          >
            Who Needs A Logo?
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          padding={"1rem"}
        >
          <Box flex={"1"}>
            {/* Left side for typography */}
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"400"}
              marginBottom={"3rem"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "0",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1rem" : "1.3rem",
              }}
            >
              The primary visual element of the overall brand identity. Logo is
              considered to be the face of the brand which is the key to get
              your business noticed.
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"400"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "0",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1rem" : "1.3rem",
              }}
            >
              Too often, small business owners think a professionally designed
              logo is an expensive project, but a perfect logo can be affordably
              designed making it look better in the eyes of the audience – and
              is a must for every business.
            </Typography>
            <Typography
              variant="h1"
              color={"#990304"}
              fontWeight={"500"}
              fontSize={"1.2rem"}
              marginTop={"2.5rem"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "0",
                width: containerWidth === "initial" ? "70%" : "100%",
              }}
            >
              HOW DOES A GREAT LOGO BENEFIT MY BUSINESS
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"400"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "0",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1rem" : "1.3rem",
              }}
            >
              <ul style={{ listStyleType: "disc" }}>
                <li style={{ marginBottom: "10px" }}>
                  it is impactful and leaves a positive impression on the
                  customer.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  It is considered to be the identity of the company.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  It distinct and differentiates your brand from competitors.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  It's versatility in terms of brand recall for loyal customers.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  It sends a message of professionalism.
                </li>
              </ul>
            </Typography>
          </Box>
          <Box flex={"1"} sx={{ display: { xs: "none", md: "block" } }}>
            {/* Right side for image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/logo101-img1.png"
              alt="Your Image"
              style={{ width: "80%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "50px",
          backgroundColor: "#F1F1F1",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
          <Typography
            variant="h6"
            sx={{
              color: "#990304",
              fontSize: { xs: "2rem", sm: "2rem", md: "2rem" }, // Adjust font size for different screen sizes
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Why Do I Need A Strong Brand?
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#292323",
              fontFamily: "Poppins",
              fontSize: { xs: "1.5rem", sm: "1rem", md: "1.2rem" },
              fontWeight: "300",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", sm: "space-around" }, // Center on XS, space around on other sizes
              alignItems: "center",
              paddingX: "1rem", // Adjust padding for different screen sizes
              paddingBottom: "50px",
            }}
          >
            Branding creates a memorable impression of your business that builds
            trust, motivates employees and helps in acquiring new customers in
            no time
          </Typography>
        </Box>
        <Box sx={{ paddingBottom: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              paddingX: { xs: "10px", sm: "50px" },
              paddingY: "50px",
              gap: "50px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-1.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                IMPROVE RECOGNITION
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Your brand is the “face” of your company. Make it memorable —
                and strong enough to leave the desired impression.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-2.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                BUILD FINANCIAL VALUE
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Developing a strategic plan for the company can help in
                achieving long-term financial gain. To optimize the ROI,
                increase the company’s value and ensure the company meets its
                financial objectives.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-3.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                CREATE TRUST
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                A professional appearance builds credibility and trust. People
                are more likely to engage with a business that appears polished
                and legitimate.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              paddingX: { xs: "10px", sm: "50px" },
              paddingY: "50px",
              gap: "50px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-4.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />

              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                INSPIRE EMPLOYEES
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                When employees understand your mission, they feel the same pride
                and will work to achieve your shared goals. As it is said
                “Interesting things happen when the creative impulse is
                cultivated with curiosity, freedom and intensity.”
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-5.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                GENERATE NEW CUSTOMERS
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Branding enables your company to get referral business. Could
                you tell a friend about the new shoes you love if you can’t
                remember the brand? Word of mouth referrals are only possible
                after you’ve delivered a memorable experience.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"3rem"}
          sx={{ backgroundColor: "#F1F1F1" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"01rem"}
            boxShadow={"0 0 15px #990304"}
            sx={{ backgroundColor: "#F1F1F1" }}
            width={containerWidth}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                variant="h1"
                color={"#990304"}
                fontWeight={"600"}
                fontSize={"2rem"}
                margin={"20px"}
                fontFamily="Poppins"
              >
                What Makes A Great Logo?
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              padding={"1rem"}
            >
              <Box flex={"2"}>
                {/* Left side for typography */}
                <Typography
                  variant="h1"
                  color={currentImageIndex === 0 ? "#990304" : "#292323"}
                  fontWeight={"bold"}
                  fontSize={"1.2rem"}
                  marginLeft={"5rem"}
                  width={"60%"}
                  fontStyle={"italic"}
                  fontFamily="Poppins"
                >
                  MeaningFull Text
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={currentImageIndex === 0 ? "#990304" : "#292323"}
                  fontWeight={"normal"}
                  fontSize={"0.8rem"}
                  marginLeft={"5rem"}
                  width={"60%"}
                  marginBottom={"3rem"}
                  fontFamily="Poppins"
                >
                  The primary visual element of the overall brand identity. Logo
                  is considered to be the face of the brand which is the key to
                  get your business noticed.
                </Typography>
                <Typography
                  variant="h1"
                  color={currentImageIndex === 1 ? "#990304" : "#292323"}
                  fontWeight={"bold"}
                  fontSize={"1.2rem"}
                  marginLeft={"5rem"}
                  width={"60%"}
                  fontStyle={"italic"}
                  fontFamily="Poppins"
                >
                  Eye catching color
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={currentImageIndex === 1 ? "#990304" : "#292323"}
                  fontWeight={"normal"}
                  fontSize={"0.8rem"}
                  marginLeft={"5rem"}
                  width={"60%"}
                  marginBottom={"3rem"}
                  fontFamily="Poppins"
                >
                  Too often, small business owners think a professionally
                  designed logo is an expensive project, but a perfect logo can
                  be affordably designed making it look better in the eyes of
                  the audience – and is a must for every business.
                </Typography>
                <Typography
                  variant="h1"
                  color={currentImageIndex === 2 ? "#990304" : "#292323"}
                  fontWeight={"bold"}
                  fontSize={"1.2rem"}
                  marginLeft={"5rem"}
                  width={"60%"}
                  fontStyle={"italic"}
                  fontFamily="Poppins"
                >
                  Graphical Presentation
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={currentImageIndex === 2 ? "#990304" : "#292323"}
                  fontWeight={"normal"}
                  fontSize={"0.8rem"}
                  marginLeft={"5rem"}
                  width={"60%"}
                  marginBottom={"3rem"}
                  fontFamily="Poppins"
                >
                  The primary visual element of the overall brand identity. Logo
                  is considered to be the face of the brand which is the key to
                  get your business noticed.
                </Typography>
              </Box>
              <Box flex={"2"} mt={2}>
                {/* Right side for image */}
                <img
                  src={images[currentImageIndex]}
                  alt="Your Image"
                  style={{ width: "80%", height: "auto", borderRadius: "10px" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ width: containerWidth }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#990304",
              fontWeight: "600",
              fontSize: "2rem",
              padding: "3rem",
              fontFamily: "Poppins",
            }}
          >
            Which Type Of Logo Is Right For My Business?
          </Typography>
        </Box>
        <img
          src="https://www.uptownlogodesign.com/assets/images/logo101-img3.jpg"
          alt="Your Image"
          style={{ width: "60%", height: "auto", borderRadius: "10px" }}
        />
      </Box>
      <Typography
        variant="body1"
        sx={{
          color: "#333",
          fontWeight: "400",
          fontSize: { xs: "1rem", sm: "1rem", md: "1rem" },
          wordSpacing: "5px",
          padding: "3rem",
          textAlign: "center",
          // paddingX: "15rem",
          fontFamily: "Poppins",
          width: containerWidth,
        }}
      >
        <strong style={{ color: "#990304" }}>01. Word marks</strong> are
        trademarks containing words. You can put in as many words as you want
        but make sure the words are written in plain characters. They protect
        how the words look like no matter they are in any font, size or color.
        It is good for companies and brands to create a wordmark logo to
        communicate the customers what the brand is all about as well as cost
        effective. The logo designs can also be used when a brand is rebranding
        with a new logo and brand identity.
        <strong style={{ color: "#990304" }}> 02. Lettermarks</strong>
        rely on initials to represent the brand. It acts as a monogram for your
        business. Use lettermarks to create a visual link between parent
        companies and subsidiaries. Lettermark logos are quite known in
        rebranding campaigns. But you might need to spend time in explaining the
        customers what your brand offers.
        <strong style={{ color: "#990304" }}> 03. Brandmark</strong> is a logo
        which represents the brand with or without the company or brand name.
        Images are more memorable than words that is why these logos are highly
        popular among small as well as large companies. Although it takes time
        to recall the brand without the name but for the companies that do it
        successfully the logo becomes a great asset.
        <strong style={{ color: "#990304" }}>04. Iconic</strong> logos
        represents the brand through icons and symbols and are quite compelling.
        These logos represents an abstract or a literal representation of your
        brand or the company. They prove to be very effective for small
        businesses and startups whose name may be distinctive but not yet widely
        recognized. An iconic logo is very effective in communicating a brands
        identity
      </Typography>

      <Box
        sx={{
          boxShadow:
            containerWidth === "initial"
              ? "0px 1px 5px rgba(0, 0, 0.4, 0.4)"
              : "none",
        }}
      >
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
      <Box
        display={{ xs: "flex", md: "block" }}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"3rem"}
        sx={{ backgroundColor: "#F1F1F1" }}
        width={containerWidth}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography
            variant="h1"
            color={"#990304"}
            fontWeight={"600"}
            fontSize={"2rem"}
            margin={"10px"}
            fontFamily="Poppins"
            textAlign={"center"}
          >
            What Colors Should I Use In My Logo?
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          padding={"2rem"}
        >
          <Box
            flex={"1"}
            marginLeft={containerWidth !== "initial" ? "0rem" : "5rem"}
          >
            {/* Left side for typography */}
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"normal"}
              fontSize={"1rem"}
              marginBottom={"1rem"}
              fontFamily="Poppins"
            >
              Color is a key consideration for all visual materials
            </Typography>
            <Typography
              variant="h3"
              color={"#990304"}
              fontWeight={"500"}
              fontSize={"1.2rem"}
              // marginLeft={"15rem"}
              marginBottom={"1rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              Keep it simple
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"normal"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"3rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              The most successful logos are simple in terms of colors. In fact,
              two of the most basic colors — black and red — are used most
              frequently. Think of some of the world’s most successful logos,
              such as Pepsi, Coke and Starbucks, which use just one or two
              colors each.
            </Typography>
            <Typography
              variant="h3"
              color={"#990304"}
              fontWeight={"500"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"1.2rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              KNOW WHAT EMOTIONS YOU WANT TO EVOKE, AND WHICH COLORS WILL DO
              THAT
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"normal"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"3rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              Identify the key emotional message you want your logo to
              communicate and choose colors to convey that emotion:
            </Typography>
            <Typography
              variant="h3"
              color={"#990304"}
              fontWeight={"500"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"1.2rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              KNOW YOUR TARGET AUDIENCE AND WHAT’S LIKELY TO RESONATE WITH THEM
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"normal"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"1rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              Color relatability can be generational and gender-specific. Both
              men and women say blue is their favorite color and brown their
              least favorite, but women also like purple, whereas men don’t care
              for it at all. On the other hand, men are slightly more partial to
              black.
            </Typography>
          </Box>
          <Box
            flex={"1"}
            marginLeft={"2rem"}
            display={{ xs: "none", md: "flex" }}
          >
            {/* Right side for image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/logo101-img4.png"
              alt="Your Image"
              style={{ width: "90%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"#292323"}
        padding={"2rem"}
        width={containerWidth}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h1"
            color={"white"}
            fontWeight={"500"}
            fontSize={"2rem"}
            fontFamily="Poppins"
          >
            What Fonts Should I Use For My Logo?
          </Typography>
          <Typography
            variant="subtitle1"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"1.2rem"}
            marginY={"1rem"}
            fontFamily="Poppins"
          >
            Typefaces are as important as color. Consider what the look of your
            text says to clients and potential customers:
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"2rem"}
            fontFamily={"Brush Script MT"}
            marginY={"5px"}
          >
            Script fonts: elegant, affectionate, creative
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"2rem"}
            fontFamily={"Serif "}
            marginY={"5px"}
          >
            Serif fonts: traditional, reliable
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"bold"}
            fontSize={"2rem"}
            fontFamily={"Sans serif "}
            marginY={"5px"}
          >
            Sans serif: stable, steady, clean
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"2rem"}
            fontFamily={"Modern"}
            marginY={"5px"}
          >
            Modern: strong, stylish
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"2rem"}
            fontFamily={"Display"}
            marginY={"5px"}
          >
            Display: friendly, unique
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "50px", width: containerWidth }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#990304",
                fontSize: "2rem",
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              What’s A Style Guide? Do I Need One?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#292323",
                paddingX: containerWidth !== "initial" ? "0rem" : "5rem",
                fontFamily: "Poppins",
                fontWeight: 400,
                marginBottom: "20px",
                fontSize: "1rem",
              }}
            >
              Yes! It’s important to have a style guide laying out standards for
              the visuals and copy associated with your brand. A style guide
              maintains consistency across your organization — no matter how
              large or small. Here are six elements to think about when
              developing your guide. After the guide is complete, share it with
              your employees and revisit it annually to see if updates are
              needed.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "50px", width: "100%" }}>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            paddingX={containerWidth !== "initial" ? "0rem" : 5}
          >
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#F1F1F1",
                  boxShadow: "0 0 50px #EFEFEF",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="bold"
                  fontFamily="Poppins"
                >
                  ①
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#990304",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  BUSINESS MISSION OR ESSENCE
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#292323",
                    textAlign: "center",
                    fontSize: "1rem",
                    paddingX: "50px",
                    fontFamily: "Poppins",
                  }}
                >
                  To study the mission is very important. Research on the
                  history of the company to get details and explain in clear
                  words, what the company is all about?
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#F1F1F1",
                  boxShadow: "0 0 50px #f1f1f1",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="bold"
                  fontFamily="Poppins"
                >
                  ②
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#990304",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  LOGO
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#292323",
                    textAlign: "center",
                    fontSize: "1rem",
                    paddingX: "50px",
                    fontFamily: "Poppins",
                  }}
                >
                  Your style guide should include your logo in various sizes and
                  file formats, and guidelines dictating how and where the logo
                  may be used for making look better.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#F1F1F1",
                  boxShadow: "0 0 50px #f1f1f1",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="bold"
                  fontFamily="Poppins"
                >
                  ③
                </Typography>

                <Typography
                   variant="h5"
                   sx={{
                     color: "#990304",
                     fontWeight: "500",
                     fontFamily: "Poppins",
                   }}
                 >
                  FONTS
                </Typography>
                <Typography
                   variant="subtitle1"
                   sx={{
                     color: "#292323",
                     textAlign: "center",
                     fontSize: "1rem",
                     paddingX: "50px",
                     fontFamily: "Poppins",
                   }}
                 >
 
                  Select fonts and sizes according to the company’s standard. A
                  font does everything from representing the brand name to the font you want
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            paddingX={containerWidth !== "initial" ? "0rem" : 5}
          >
            <Grid xs={12} sm={4} md={4} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                marginTop={"4rem"}
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#f1f1f1",
                  boxShadow: "0 0 50px #EFEFEF",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="500"
                  fontFamily="Poppins"
                >
                  ④
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#990304",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  VOICE
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "292323",
                    fontSize: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  The most important thing for the marketing professionals and
                  copywriters who are working on your brand is voice guidelines.
                  Who is speaking? What is the voice of the company? 
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                marginTop={"3rem"}
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#f1f1f1",
                  boxShadow: "0 0 50px #EFEFEF",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="500"
                  fontFamily="Poppins"
                >
                  ⑤
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#990304",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  COLORS
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#292323",
                    fontSize: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Some brands are instantly recognizable by the color of their
                  logos. These colors are forever associated with the companies.
                  Careful selection of colors will help in recalling the brand
                  easily.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                marginTop={"3rem"}
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#f1f1f1",
                  boxShadow: "0 0 50px #EFEFEF",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "4rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="bold"
                  fontFamily="Poppins"
                >
                  ⑥
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#990304",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  IMAGES
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#292323",
                    fontSize: "0.9rem",
                    paddingX: "50px",
                    fontFamily: "Poppins",
                  }}
                >
                  Are there certain types of imagery that fit with your brand
                  style? Provide clear guidelines to simplify selecting photos
                  for creative materials. Ensure that your images  are consistent and relavent that you want
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DownloadGuide
        pdfUrl={"https://clickdimensions.com/links/TestPDFfile.pdf"}
      />
    </Box>
  );
};

export default Logo101LogoDesign;

{
  /* <DownloadGuide pdfUrl={"https://clickdimensions.com/links/TestPDFfile.pdf"} /> */
}
