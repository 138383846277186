import { Box, Button, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import Brosheres from "../components/Brosheres";
import LeadDesignOverviewPack from "../components/LeadDesignOverviewPack";
import Reviews from "../components/Reviews";
import brosherImg from "../Assets/keyFeatures/trophy.png"
import reviewDefultImg01 from "../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../Assets/BrandImg/10.png"
import rattingImg from "../Assets/reviwesImg/rating.png"
import reviewImg01 from "../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../Assets/reviwesImg/topReview3.jpg"


interface Broshere {
  title1: string;
  title2: string;
  listItems: string[];
  amount: string;
  imageSrc: string;
}

const Packages = () => {
  const [activeButton, setActiveButton] = useState<
    | "logo"
    | "website"
    | "Branding"
    | "Brochure"
    | "Stationery"
    | "SEO"
    | "SocialMedia"
  >("logo");

  const handleBrosheresButtonClick = (
    brosherButtonName:
      | "logo"
      | "website"
      | "Branding"
      | "Brochure"
      | "Stationery"
      | "SEO"
      | "SocialMedia"
  ) => {
    setActiveButton(brosherButtonName);
  };

  const headingStyles = {
    logo: {
      text: "Carefully Crafted LOGO Packages For New And Existing Businesses",
    },
    website: {
      text: "Carefully Crafted WEBSITE Packages For New And Existing Businesses",
    },
    Branding: {
      text: "Carefully Crafted BRANDING Packages For New And Existing Businesses",
    },
    Brochure: {
      text: "Carefully Crafted BROCHURE Packages For New And Existing Businesses",
    },
    Stationery: {
      text: "Carefully Crafted STATIONAERY Packages For New And Existing Businesses",
    },
    SEO: {
      text: "Carefully Crafted SEO Packages For New And Existing Businesses",
    },
    SocialMedia: {
      text: "Carefully Crafted SOCIAL MEDIA Packages For New And Existing Businesses",
    },
  };

  const brosheresData: Record<string, Broshere[]> = {
    logo: [
      {
        title1: "Best seller",
        title2: "Smart design solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
      {
        title1: "The Ultimate",
        title2: "Cutting-edge Design",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$68",
        imageSrc: brosherImg
      },
      {
        title1: "Business Plus",
        title2: "Ideal design solutions",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
      {
        title1: "Enterprise",
        title2: "Perfect design solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
      {
        title1: "Enterprise Plus",
        title2: "Value added design solutions",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
      {
        title1: "The Corporate",
        title2: "Complete design solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
      {
        title1: "Illustrative Starter",
        title2: "Illustrative/Mascot Logo",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
      {
        title1: "Illustrative Budget",
        title2: "Illustrative/Mascot Logo",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
      {
        title1: "Illustrative Ultimate",
        title2: "Illustrative/Mascot Logo",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
      {
        title1: "3D Special",
        title2: "Perspective Brand Identity",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$100",
        imageSrc: brosherImg
      },
    ],
    website: [
      {
        title1: "Best Seller",
        title2: "Hassle Free Design",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$250",
        imageSrc: brosherImg
      },
      {
        title1: "The Ultimate",
        title2: "Smart design solutions",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$250",
        imageSrc: brosherImg
      },
      {
        title1: "Bussiness Plus",
        title2: "Ideal design solutions",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$250",
        imageSrc: brosherImg
      },
      {
        title1: "Enterprise",
        title2: "Brosheres",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$250",
        imageSrc: brosherImg
      },
      {
        title1: "Enterprise",
        title2: "Perfect design solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$250",
        imageSrc: brosherImg
      },
      {
        title1: "Enterprise Plus",
        title2: "Complete design solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$250",
        imageSrc: brosherImg
      },
    ],
    Branding: [
      {
        title1: "Budget",
        title2: "Hassle Free Design",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$350",
        imageSrc: brosherImg
      },
      {
        title1: "Best Seller",
        title2: "Smart design Solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$500",
        imageSrc: brosherImg
      },
      {
        title1: "The Ultimate",
        title2: "Cutting-edge Design Solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$650",
        imageSrc: brosherImg
      },
    ],
    Brochure: [
      {
        title1: "Budget",
        title2: "Hassle Free Design",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$45",
        imageSrc: brosherImg
      },
      {
        title1: "Best Seller",
        title2: "Smart design Solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$90",
        imageSrc: brosherImg
      },
      {
        title1: "The Ultimate",
        title2: "Cutting-edge Design Solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$199",
        imageSrc: brosherImg
      },
    ],
    Stationery: [
      {
        title1: "Budget",
        title2: "Hassle Free Design",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$125",
        imageSrc: brosherImg
      },
      {
        title1: "Best Seller",
        title2: "Smart design Solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$250",
        imageSrc: brosherImg
      },
      {
        title1: "The Ultimate",
        title2: "Cutting-edge Design Solution",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$450",
        imageSrc: brosherImg
      },
    ],
    SEO: [
      {
        title1: "Budget",
        title2: "3 Month SEO Package",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$550",
        imageSrc: brosherImg
      },
      {
        title1: "Best Seller",
        title2: "6 Month SEO Package",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$500",
        imageSrc: brosherImg
      },
      {
        title1: "The Ultimate",
        title2: "12 Month SEO Package",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$450",
        imageSrc: brosherImg
      },
    ],
    SocialMedia: [
      {
        title1: "Budget",
        title2: "1 Month Plan",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$550",
        imageSrc: brosherImg
      },
      {
        title1: "Best Seller",
        title2: "3 Month Plan",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$500",
        imageSrc: brosherImg
      },
      {
        title1: "The Ultimate",
        title2: "6 Month Plan",
        listItems: [
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
        ],
        amount: "$450",
        imageSrc: brosherImg
      },
    ],
  };

  const textArray = headingStyles[activeButton]?.text.split(" ");



  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "170%" : "inital";



  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        marginTop: "100px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#f1f1f1",
          padding: "70px",
          textAlign: "center",
          // whiteSpace: "nowrap",
          paddingBottom: "100px",
          // boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.8)",
          zIndex: -1,
          width: containerWidth,
          
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            color: "#990304",
            fontWeight: "600",
            textAlign: { xs: "center" },
            fontSize: { xs: "2.5rem" ,sm: "2rem", md: "2rem" },
            fontFamily: "Poppins",
          }}
        >
          {textArray.map((word, index) => (
            <span
              key={index}
              style={{
                color: index === 2 ? "#71706E" : "inherit",
                fontFamily: index === 2 ? "cursive" : "inherit",
                fontWeight: index === 2 ? "bold" : "inherit",
              }}
            >
              {word}
              {index !== textArray.length - 1 ? " " : ""}
            </span>
          ))}
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ color: "#292323",fontFamily: "Poppins", }}>
          We Provide Highly Flexible And Affordable Design Options
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={{ xs: "row", sm: "row" }}
          gap={{ xs: 2, sm: 2, md: 2 }}
          mt={10}
          mb={5}
        >
          <Grid container spacing={2} gap={{ xs: 4, sm: 4, md: 4 }}> 
      <Grid xs={12} sm={12} md={12}> 
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "1.2rem",
              fontFamily: "Poppins",
              borderColor: activeButton === "logo" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "logo" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "logo" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "3rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304",
              },
            }}
            onClick={() => handleBrosheresButtonClick("logo")} // Call handleButtonClicked with button name
          >
            Logo
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              marginX: "1rem",
              fontSize: "1.2rem",
              fontFamily: "Poppins",
              borderColor: activeButton === "website" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "website" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "website" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "3rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("website")} // Call handleButtonClicked with button name
          >
            Website
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "1.2rem",
              fontFamily: "Poppins",
              borderColor: activeButton === "Branding" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "Branding" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "Branding" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "3rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("Branding")} // Call handleButtonClicked with button name
          >
            Branding
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "1.2rem",
              marginX: "1rem",
              marginY: "1rem",
              fontFamily: "Poppins",
              borderColor: activeButton === "Brochure" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "Brochure" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "Brochure" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "3rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("Brochure")} // Call handleButtonClicked with button name
          >
            Brochure
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "1.2rem",
              fontFamily: "Poppins",
              borderColor:
                activeButton === "Stationery" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "Stationery" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "Stationery" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "3rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("Stationery")} // Call handleButtonClicked with button name
          >
            Stationery
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "1.2rem",
              marginX: "1rem",
              marginY: "1rem",
              fontFamily: "Poppins",
              borderColor: activeButton === "SEO" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "SEO" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "SEO" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "3rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("SEO")} // Call handleButtonClicked with button name
          >
            SEO
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              fontSize: "1.2rem",
              fontFamily: "Poppins",
              borderColor:
                activeButton === "SocialMedia" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "SocialMedia" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "SocialMedia" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "3rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("SocialMedia")} // Call handleButtonClicked with button name
          >
            Social Media
          </Button>
          </Grid>
          </Grid>
        </Box>
        
        
        <Grid container spacing={-25} justifyContent="center">
          {brosheresData[activeButton].map(
            (broshere: Broshere, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Brosheres
                  title1={broshere.title1}
                  title2={broshere.title2}
                  listItems={broshere.listItems}
                  amount={broshere.amount}
                  imageSrc={broshere.imageSrc}
                />
              </Grid>
            )
          )}
        </Grid>
      </Box>
      <LeadDesignOverviewPack
        title="Lead Design All in one"
        subtitle="The Comprehensive Branding Solution"
        paragraph="Starting from scratch or revamping your brand`s existence? Uptown Logo Design brings forth the very package that gets your business rolling seamlessly. From providing a custom logo design to asserting your brand`s digital presence across all the major platforms, the “All in One” proves to be the singularly comprehensive solution to all your branding predicaments"
        list={[
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
          "List Item 6",
        ]}
        amount="$100"
      />

      <Reviews
        subtitle={
          "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
        }
        heading={"Look What Our Customers Have Said About Us"}
        paragraphs={[
          "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
          "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]}
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}
      />
    </Box>
  );
};

export default Packages;
