import React from 'react'
import SelectUs from '../../components/selectUs';
import PortfolioSelector from '../../components/PortfolioSelector';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OverviewSection from '../../components/OverviewSection';

const PortfolioBranding = () => {
  
  const menuItemData = [
    {
      label: "Branding Industry",
      value: "",
      images: [
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/01.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/02.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/03.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/04.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/05.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/06.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/07.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/08.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/09.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/10.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/11.jpg",
        "https://www.uptownlogodesign.com/assets/images/portfolio/branding/thmb/12.jpg"

      ],
    },
    // Add more menu items as needed
  ];

  // for select us
  const Navigate = useNavigate();
  const handlePackages = () => {
    Navigate("/logo-design/packages");
  };
  return (
  <div  style={{ width: "100%", height: "100%", marginTop: "160px" }}>
      <OverviewSection
        title1="A Quick Look
        At Our Portfolio"
        subTitle="The Projects We Created For Our Clients Are A Source Of Pride And Motivation For Us."
        listItems={[
          "100% Ownership Rights",
          "Brochure Design",
          "Stationery Design",
          "Social Media Banners",
          "T-Shirt Design",
        ]}

        // imageUrl="/assets/MainContentImg/workspace_04.jpg"
        // imageUrl2="/assets/MainContentImg/workspace_04.jpg"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/portfolio.jpg"
        reverseLayout={true}
      />
      <Box sx={{ padding: "70px" }}>
      <PortfolioSelector menuItemData={menuItemData} />
      </Box>
      <Box
        sx={{
          boxShadow: {
            xs: "none", 
            md: "0px 5px 20px rgba(0, 0, 0.4, 0.4)" 
          }
        }}
      >
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
    </div>
  )
}


export default PortfolioBranding