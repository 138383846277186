import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import OverviewSection from "../../components/OverviewSection";
import SelectUs from "../../components/selectUs";
import Reviews from "../../components/Reviews";

import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"


const OurGuaranteeLogoDesign = () => {
  const handlePackages = () => {
    window.location.href = "logo-design/packages";
  };



  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "200%" : "initial";

  return (
    <Box width="100%" height="100%" marginTop={containerWidth === "initial" ? "160px" : "0"}>
      <OverviewSection
        title1="We Deliver
        On Our Promises"
        subTitle="To Help You Boost Your Brand Affinity"
        subTitleColor="#990304"
        listItems={[
          "Logo Design",
          "Business Cards",
          "Business Logos",
          "Company Logos",
        ]}
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/our-guarrantee.jpg"
        reverseLayout={true}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          paddingY: "7rem",
          // paddingBottom: "3rem",
          // boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 8px inset",
          width: containerWidth,
        }}
      >
        <Grid container spacing={3} sx={{ width: "80%" }}>
          {/* First Grid Item */}
          <Grid item xs={0} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/guarantee-1.png"
              alt="Launch Image"
              style={{ width: "60%", borderRadius: "8px" }}
            />
          </Grid>
          {/* Second Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "3rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  fontFamily: "Poppins",
                }}
              >
                WE GUARANTEE COMPLETE SATISFACTION
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#292323",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                  fontWeight: "300",
                }}
              >
                Uptown Logo Design is committed to bringing you guaranteed
                satisfaction. You can rest assured that we will work tirelessly
                and efficiently to bring you the satisfaction you expect. Just
                in case you are not satisfied with the initial concepts you can
                always get in touch with our design consultants and request for
                a revision.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "80%" }}>
          {/* Third Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  fontFamily: "Poppins",
                }}
              >
                WE GUARANTEE UNIQUE DESIGN ELEMENT
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#292323",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                  fontWeight: "300",
                }}
              >
                We strive to bring you unique and diversified designs that
                spruce up your business image. At Uptown Logo Design we ensure
                that each design that we create is unique in all aspects in
                order to give your business a very strong and impeccable
                identity. Be it a logo, website, branding design or any other
                service, we assure you complete creativity.
              </Typography>
            </Box>
          </Grid>
          {/* Fourth Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/guarantee-2.png"
              alt="About Uptown Image"
              style={{ width: "60%", borderRadius: "8px", marginTop: "10rem" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "80%" }}>
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/guarantee-3.png"
              alt="About Uptown Image"
              style={{ width: "60%", borderRadius: "8px", marginTop: "10rem" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  fontFamily: "Poppins",
                }}
              >
                WE GUARANTEE RAPID DELIVERY
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#292323",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                  fontWeight: "300",
                }}
              >
                The team at Uptown Logo design is efficient. We work tirelessly
                round the clock to bring you the designs on time, but we
                guarantee that we will make no rush but instead give our best to
                give your perfection you deserve. We pride ourself on the
                impressive revision process to check and make appropriate
                amendments in a quickest manner.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "80%" }}>
          {/* Third Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  fontFamily: "Poppins",
                }}
              >
                MONEY BACK GUARANTEE
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#292323",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                  fontWeight: "300",
                }}
              >
                We guarantee you exceptional customer service, unique design
                concepts, and seamless interface. We provide you complete
                satisfaction for all your branding requirements. Just in case
                you are left unsatisfied due to any issue regarding our
                services, we provide you 100% money back guarantee.
              </Typography>
            </Box>
          </Grid>
          {/* Fourth Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/guarantee-4.png"
              alt="About Uptown Image"
              style={{ width: "60%", borderRadius: "8px", marginTop: "10rem" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          boxShadow:
            containerWidth === "initial"
              ? "0px 1px 5px rgba(0, 0, 0.4, 0.4)"
              : "none",
        }}
      >
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Looking For Tailored Logo Design Packages?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
      <Reviews
        subtitle={
          "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
        }
        heading={"Look What Our Customers Have Said About Us"}
        paragraphs={[
          "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
          "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]}
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}   
      />
    </Box>
  );
};

export default OurGuaranteeLogoDesign;
