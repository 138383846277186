import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  Box,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import ComponentBar from "./ComponentBar";
import logo from "../Assets/main logo/leaguelogo.png"
export default function NavBar() {
  const location = useLocation();

  const isLogoDesignOrChild = location.pathname.startsWith("/logo-design");
  const isWebsiteOrChild = location.pathname.startsWith("/website");
  const isBrandingOrChild = location.pathname.startsWith("/branding");

  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const st = window.scrollY || window.pageYOffset;
      setIsScrollingDown(st > lastScrollTopRef.current);
      lastScrollTopRef.current = st <= 0 ? 0 : st;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const lastScrollTopRef = useRef(0);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AppBar position="fixed" style={{ backgroundColor: "#292323" }}>
      <Toolbar>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ mx: { xs: 2, sm: 6, md: 12 }, my: "4px" }}
        >
          {/* <Grid item xs={12} sm={2} spacing={1}> */}
            <Box width={"40%"} height={"40%"}>
            <img
                src={logo}
                alt="Logo"
                style={{ width: "35%", height: "30%" }}
              />
            </Box>
          {/* </Grid> */}
          {!isScrollingDown && (
            <Grid
              item
              xs={12}
              sm={4}
              md={6}
              container
              justifyContent="flex-end"
              marginBottom={"10px"}
              sx={{ display: { xs: "none", sm: "flex" }, mt:{ xs: "0", sm: "-120px", md: "-45px"}}}
            >
              <Button
                variant="text"
                sx={{
                  fontWeight: location.pathname === "/about" ? "bold" : "bold",
                  color: location.pathname === "/about" ? "white" : "white",
                  fontFamily: "Poppins",
                  backgroundColor:
                    location.pathname === "/about" ? "#990304" : undefined,
                  fontSize: location.pathname === "/about" ? "16px" : "14px",
                }}
                component={Link}
                to="/about"
              >
                About
              </Button>
              <Button
                variant="text"
                sx={{
                  fontWeight:
                    location.pathname === "/contact" ? "bold" : "bold",
                  color:
                    location.pathname === "/contact" ? "white" : "white",
                  fontFamily: "Poppins",
                  backgroundColor:
                    location.pathname === "/contact" ? "#990304" : undefined,
                  fontSize: location.pathname === "/contact" ? "16px" : "14px",
                }}
                component={Link}
                to="/contact"
              >
                Contact
              </Button>
              <Button
                variant="text"
                sx={{
                  fontWeight:
                    location.pathname === "/reviews" ? "bold" : "bold",
                  color:
                    location.pathname === "/reviews" ? "white" : "white",
                  fontFamily: "Poppins",
                  backgroundColor:
                    location.pathname === "/reviews" ? "#990304" : undefined,
                  fontSize: location.pathname === "/reviews" ? "16px" : "14px",
                }}
                component={Link}
                to="/reviews"
              >
                Review
              </Button>
              <Button
                variant="text"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  width: "1px",
                }}
                disabled
              >
                |
              </Button>
              <Button
                variant="text"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "Arial",
                }}
              >
                Live Chat
              </Button>
              <Button
                variant="text"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "Arial",
                }}
              >
                123-456-7890
              </Button>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            sx={{ display: { xs: "flex", sm: "none" }, alignContent: "center", alignItems: "center" }} 
          >
            <IconButton onClick={toggleDrawer} sx={{ mr: 1, color: "#990304" }}>
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            alignContent={"center"}
            alignItems="center"
            sx={{ display: { xs: "none", sm: "flex" }, mt: "-70px" }}
          >
            <Button
              variant="text"
              component={Link}
              to="/logo-design/overview"
              sx={{
                fontWeight: isLogoDesignOrChild ? "bold" : "bold",
                color: isLogoDesignOrChild ? "white" : "white",
                fontFamily: "Poppins",
                backgroundColor: isLogoDesignOrChild ? "#990304" : undefined,
                fontSize: isLogoDesignOrChild ? "16px" : "14px",
              }}
            >
              Logo Design
            </Button>
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/website/overview"
              sx={{
                fontWeight: isWebsiteOrChild ? "bold" : "bold",
                color: isWebsiteOrChild ? "white" : "white",
                fontFamily: "Poppins",
                backgroundColor: isWebsiteOrChild ? "#990304" : undefined,
                fontSize: isWebsiteOrChild ? "16px" : "14px",
              }}
            >
              Website
            </Button>
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/branding/overview"
              sx={{
                fontWeight: isBrandingOrChild ? "bold" : "bold",
                color: isBrandingOrChild ? "white" : "white",
                fontFamily: "Poppins",
                backgroundColor: isBrandingOrChild ? "#990304" : undefined,
                fontSize: isBrandingOrChild ? "16px" : "14px",
              }}
            >
              Branding
            </Button>
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/animations"
              sx={{
                fontWeight:
                  location.pathname === "/animations" ? "bold" : "Bold",
                color: location.pathname === "/animations" ? "white" : "white",
                fontFamily: "Poppins",
                backgroundColor:
                  location.pathname === "/animations" ? "#990304" : undefined,
                fontSize: location.pathname === "/animations" ? "16px" : "14px",
              }}
            >
              Animations
            </Button>
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/mobile-apps"
              sx={{
                fontWeight:
                  location.pathname === "/mobile-apps" ? "bold" : "bold",
                color: location.pathname === "/mobile-apps" ? "white" : "white",
                fontFamily: "Poppins",
                backgroundColor:
                  location.pathname === "/mobile-apps" ? "#990304" : undefined,
                fontSize:
                  location.pathname === "/mobile-apps" ? "16px" : "14px",
              }}
            >
              Mobile Apps
            </Button>
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/packages"
              sx={{
                fontWeight: location.pathname === "/packages" ? "bold" : "bold",
                color: location.pathname === "/packages" ? "white" : "white",
                fontFamily: "Poppins",
                backgroundColor:
                  location.pathname === "/packages" ? "#990304" : undefined,
                fontSize: location.pathname === "/packages" ? "16px" : "14px",
              }}
            >
              Packages
            </Button>
            {/* <Button variant="text"  sx={{ fontWeight: "bold",color: "#edf5e1" ,fontFamily: "Poppins",}}>
              Portfolio
            </Button> */}
          </Grid>
          {/* Render ComponentBar for logo-design and website routes */}
          {(isLogoDesignOrChild || isWebsiteOrChild || isBrandingOrChild) && (
            <Grid item md={12} sm={12}   display={{ xs: "none", md: "flex" , sm: "flex" }} justifyContent="center" alignItems="center" mt={"-15px"}>
              <ComponentBar />
            </Grid>
          )}
        </Grid>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <List sx={{ width: 250 }} onClick={toggleDrawer}>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              component={Link}
              to="/logo-design"
              sx={{
                fontWeight: isLogoDesignOrChild ? "bold" : "normal",
                color: isLogoDesignOrChild ? "#3EB489" : undefined,
                fontSize: isLogoDesignOrChild ? "16px" : "inherit",
              }}
            >
              Logo Design
            </Button>

            {isLogoDesignOrChild && (
              <Grid item xs={12}>
                <ComponentBar />
              </Grid>
            )}
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/website"
              sx={{
                fontWeight: isWebsiteOrChild ? "bold" : "normal",
                color: isWebsiteOrChild ? "#3EB489" : undefined,
                fontSize: isWebsiteOrChild ? "16px" : "inherit",
              }}
            >
              Website
            </Button>
            {isWebsiteOrChild && (
              <Grid item xs={12}>
                <ComponentBar />
              </Grid>
            )}
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/branding"
              sx={{
                fontWeight: isBrandingOrChild ? "bold" : "normal",
                color: isBrandingOrChild ? "#3EB489" : undefined,
                fontSize: isBrandingOrChild ? "16px" : "inherit",
              }}
            >
              Branding
            </Button>
            {isBrandingOrChild && (
              <Grid item xs={12}>
                <ComponentBar />
              </Grid>
            )}
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/animations"
              sx={{
                fontWeight:
                  location.pathname === "/animations" ? "bold" : "normal",
                color:
                  location.pathname === "/animations" ? "#3EB489" : undefined,
                fontSize:
                  location.pathname === "/animations" ? "16px" : "inherit",
              }}
            >
              Animations
            </Button>
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/mobile-apps"
              sx={{
                fontWeight:
                  location.pathname === "/mobile-apps" ? "bold" : "normal",
                color:
                  location.pathname === "/mobile-apps" ? "#3EB489" : undefined,
                fontSize:
                  location.pathname === "/mobile-apps" ? "16px" : "inherit",
              }}
            >
              Mobile Apps
            </Button>
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          ></ListItem>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button variant="text" color="primary" sx={{ fontWeight: "bold" }}>
              Packages
            </Button>
          </ListItem>
          <ListItem></ListItem>
          <ListItem
            button
            component={Link}
            to="/about"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>About</Typography>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/contact"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>Contact</Typography>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reviews"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>Review</Typography>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/blog"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>Blog</Typography>
          </ListItem>
          <ListItem
            button
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>My Account</Typography>
          </ListItem>
          <ListItem
            button
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>Live Chat</Typography>
          </ListItem>
          <ListItem
            button
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>123-456-7890</Typography>
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
}
