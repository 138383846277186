import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";

interface ReviewsProps {
  subtitle: string;
  images: string[];
  heading: string;
  paragraphs: string[];
  reviewImages: string[];
  ratting: string; // Updated interface to accept an array of review images
}

const Reviews: React.FC<ReviewsProps> = ({
  subtitle,
  images,
  heading,
  paragraphs,
  reviewImages,
  ratting,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % reviewImages.length
      );
    }, 5000);

    const paragraphInterval = setInterval(() => {
      setCurrentParagraphIndex(
        (prevIndex) => (prevIndex + 1) % paragraphs.length
      );
    }, 5000); // Change paragraph every 1 second

    return () => {
      clearInterval(imageInterval);
      clearInterval(paragraphInterval);
    };
  }, [reviewImages.length, paragraphs.length]);

  


  const containerWidth =
    window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "inital";

  return (
    <Box sx={{ width: containerWidth, bgcolor: "#F1F1F1" }}>
      <Grid
        container
        spacing={3}
        sx={{ padding: "2rem", paddingBottom: "5rem" }}
      >
        {/* Empty grid item */}
        <Grid item xs={2} sx={{ display: { xs: "none", md: "block" } }} />

        {/* Left part */}
        <Grid item xs={0} sm={4} sx={{ display: { xs: "none", md: "block" } }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginY={3}
            padding={5}
          >
            {/* Subtitle */}
            <Typography
              variant="h3"
              gutterBottom
              fontWeight="400"
              textAlign="left"
              fontFamily="Poppins"
              sx={{ color: "#484848" }}
              fontSize={"1.5rem"}
            >
              {subtitle}
            </Typography>
            {/* Images */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="20%"
              overflow="hidden"
            >
              {/* Display images from props */}
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index + 1}`}
                  style={{ width: "30%", marginRight: "1rem" }}
                />
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Middle part */}
        <Grid item xs={12} sm={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding={0}
          >
            {/* Heading */}
            <Typography
              variant="h3"
              gutterBottom
              fontWeight="600"
              fontSize={"1.8rem"}
              fontFamily="Poppins"
              textAlign="left"
              sx={{ color: "#990304" }}
              marginY={1}
            >
              {heading}
            </Typography>
            {/* Gray box */}
            <Box
              bgcolor="#ebecee"
              width={500} // Set a fixed width, adjust as needed
              height={250} // Set a fixed height, adjust as needed
              p={2}
              position="relative"
              marginRight={1}
              overflow={"hidden"}
            >
              <Grid item xs={12} sm={6}>
                {/* Another grid for additional content */}
                <Grid container spacing={2}>
                  {reviewImages.map((image, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      key={index}
                      style={{
                        display: index === currentImageIndex ? "block" : "none",
                      }}
                    >
                      <img
                        src={image}
                        alt={`Additional Image ${index + 1}`}
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 15,
                          width: "80px", // Adjust the width as needed
                          height: "80px", // Adjust the height as needed
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                      
                    </Grid>
                  ))}
                  {/* Add more content if needed */}
                </Grid>
              </Grid>
              <Box
                sx={{
                  position: "absolute",
                  top: 15,
                  right: 2,
                  width: "30%",
                  height: "120px",
                }}
              >
                <img
                  src={ratting}
                  alt="Additional Image"
                  style={{
                    width: "65%",
                    height: "60%",
                    objectFit: "cover",
                    marginTop: "3.5rem",
                    marginLeft: "3.5rem",
                  }}
                />
              </Box>
              {/* Paragraph */}
              <Typography
                variant="body1"
                color="textSecondary"
                fontSize={"1rem"}
                fontFamily="Poppins"
                sx={{ marginTop: "4rem", padding: "2rem" }}
              >
                <h1
                  style={{
                    color: "#5cdb95",
                    textAlign: "start",
                    margin: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  “
                </h1>
                {paragraphs[currentParagraphIndex]}
                <h1
                  style={{
                    color: "#5cdb95",
                    textAlign: "end",
                    margin: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  ”
                </h1>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reviews;
