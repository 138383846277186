// Website.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OverviewWebsite from './componentsPage/OverviewWebsite';
import WhyChooseUsWebsite from './componentsPage/WhyChooseUsWebsite';
import WebsiteProcessWebsite from './componentsPage/WebsiteProcessWebsite';
import PortfolioWebsite from './componentsPage/PortfolioWebsite';
import PackagesWebsite from './componentsPage/PackagesWebsite';
import ReviewsWebsite from './componentsPage/ReviewsWebsite';
import FaqsWebsite from './componentsPage/FaqsWebsite';
import Logo101Website from './componentsPage/Logo101Website';
import OurGuaranteeWebsite from './componentsPage/OurGuaranteeWebsite';

const Website = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<OverviewWebsite />} />
        <Route path="overview" element={<OverviewWebsite />} />
        <Route path="why-choose-us" element={<WhyChooseUsWebsite />} />
        <Route path="website-process" element={<WebsiteProcessWebsite />} />
        <Route path= "portfolio" element={<PortfolioWebsite />} />
        <Route path="packages" element={<PackagesWebsite />} />
        <Route path="reviews" element={<ReviewsWebsite />} />
        <Route path="faqs" element={<FaqsWebsite />} />
        <Route path="website-101" element={<Logo101Website />} />
        <Route path="our-guarantee" element={<OurGuaranteeWebsite />} />
      </Routes>
    </>
  );
};

export default Website;
