import React from "react";
import { AppBar, Toolbar, Button, createTheme, ThemeProvider, Grid } from "@mui/material";
import { useLocation, Link } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#990304',
        },
      },
    },
  },
});

const ComponentBar = () => {
  const location = useLocation();
  const isLogoDesignPage = location.pathname.startsWith("/logo-design");
  const isLogoDesignChildRoute = location.pathname.includes("/logo-design/");
  const isWebsitePage = location.pathname.startsWith("/website");
  const isWebsiteChildRoute = location.pathname.includes("/website/");
  const isBrandingPage = location.pathname.startsWith("/branding");
  const isBrandingChildRoute = location.pathname.includes("/branding/");

  const pageNames = isLogoDesignPage
    ? [
        "Overview",
        "Why Choose Us",
        "Logo Process",
        "Portfolio",
        "Packages",
        "Reviews",
        "FAQs",
        "Logo 101",
        "Our Guarantee",
      ]
    : isWebsitePage
    ? [
        "Overview",
        "Why Choose Us",
        "Website Process",
        "Portfolio",
        "Packages",
        "Reviews",
        "FAQs",
        "Website 101",
        "Our Guarantee",
      ]
    : isBrandingPage
    ? [
        "Overview",
        "Why Choose Us",
        "Branding Process",
        "Portfolio",
        "Packages",
        "Reviews",
        "FAQs",
        "Branding 101",
        // "Our Guarantee",
    ]
    : [];

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ borderRadius: '5px', marginBottom: '2px', height: '50px'   }}>
        <Toolbar>
          <Grid container spacing={1} justifyContent="center" alignItems="center" display="flex">
            {pageNames.map((page, index) => (
              <Grid item key={index}>
                <Button 
                  variant="text" 
                  size="small"
                  
                  sx={{ 
                    fontWeight: '600', 
                    fontFamily: 'Poppins',
                    // color:"#edf5e1", 
                    fontSize: (isLogoDesignChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) || (isWebsiteChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) || (isBrandingChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) ? '13px' : '14px',
                    color: (isLogoDesignChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) || (isWebsiteChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) || (isBrandingChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) ? 'white' : 'white',
                    // backgroundColor:(isLogoDesignChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) || (isWebsiteChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) || (isBrandingChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) ? '#05386B' : '',
                    borderBottom: (isLogoDesignChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) || (isWebsiteChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) || (isBrandingChildRoute && location.pathname.includes(page.toLowerCase().replace(/ /g, '-'))) ? '4px solid #292323' : 'none',
                  }}
                  component={Link}
                  to={isLogoDesignPage ? `/logo-design/${page.toLowerCase().replace(/ /g, '-')}` : isWebsitePage ? `/website/${page.toLowerCase().replace(/ /g, '-')}` : isBrandingPage ? `/branding/${page.toLowerCase().replace(/ /g, '-')}` : `/${page.toLowerCase().replace(/ /g, '-')}`}
                >
                  {page}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default ComponentBar;
