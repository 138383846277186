import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import moreIndustriesIcon01 from "../Assets/moreIndustriesIcon/briefcase.png"
import moreIndustriesIcon02 from "../Assets/moreIndustriesIcon/business.png"
import moreIndustriesIcon03 from "../Assets/moreIndustriesIcon/handshake.png" 
import moreIndustriesIcon04 from "../Assets/moreIndustriesIcon/mortarboard.png" 
import moreIndustriesIcon05 from "../Assets/moreIndustriesIcon/online-shop.png" 
import moreIndustriesIcon06 from "../Assets/moreIndustriesIcon/rocket.png" 
import moreIndustriesIcon07 from "../Assets/moreIndustriesIcon/wallet.png" 

interface MoreIndustriesOverviewProps {
  title: string;
  subtitle: string;
  topics: string[];
  listItems: string[]; // New array for list items
}

const MoreIndustriesOverview: React.FC<MoreIndustriesOverviewProps> = ({
  title,
  subtitle,
  topics,
  listItems,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };


  const images = [
    moreIndustriesIcon01,
    moreIndustriesIcon02,
    moreIndustriesIcon03,
    moreIndustriesIcon04,
    moreIndustriesIcon05,
    moreIndustriesIcon06,
    moreIndustriesIcon07,
  ];


  return (
    <Box
      flexDirection="column"
      alignItems="center"
      sx={{
        display: { xs: "none", md: "flex" },
        // backgroundImage: "linear-gradient(to bottom right, #eef2f3,#eef2f3)",
        backgroundColor: "#292323",
        textAlign: "center",
        color: "white",
        paddingX: { sm: "10px", md: "100px" }, 
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        paddingY: expanded ? "30px" : "30px",
        paddingBottom: expanded ? "70px" : "70px",
        transition: "padding-y 0.3s ease", // Transition for padding
      }}
    >
      <Typography
        variant="h3"
        color="#F1F1F1"
        gutterBottom
        fontSize={"2.2rem"}
        fontWeight={"600"}
        marginBottom="20px"
        fontFamily="Poppins"
      >
        {title}
      </Typography>
      <Typography variant="h5" color="#F1F1F1" marginBottom="45px" fontFamily="Poppins" fontSize="1.2rem">
        {subtitle}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          backgroundColor: "#F1F1F1",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          padding: "10px",
          paddingBottom: expanded ? "100px" : "25px",
          opacity: 0.9,
          transition: "padding-bottom 0.8s ease", // Transition for padding bottom
        }}
      >
        {topics.map((topic, index) => (
          <Box
            key={index}
            marginBottom={2}
            marginRight={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
            padding: {xs: "5px", sm: "15px", md: "30px"},
              textAlign: "center",
              ":hover": { cursor: "pointer", transform: "scale(1.1)" },
              width: "fit-content",
            }}
          >
            <img
              src={images[index]}
              alt="Topic Image"
              style={{ width: "40px", height: "40px", objectFit: "cover" }}
            />
            <Typography
              variant="h5"
              color="#292323"
              fontWeight={"500"}
              marginTop={2}
              fontSize={"1.2rem"}
              fontFamily="Poppins"
            >
              {topic}
            </Typography>
          </Box>
        ))}
        {expanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              color: "black",
              marginTop: "20px",
              transition: "margin-top 1s ease", // Transition for margin top
            }}
          >
            {[...Array(3)].map((_, colIndex) => (
              <Box
                key={colIndex}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  transition: "width 0.3s ease", // Transition for width
                }}
              >
                {listItems
                  .slice(colIndex * 12, (colIndex + 1) * 12)
                  .map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        border: "0.5px solid #990304",
                        borderRadius: "5px",
                        padding: "5px",
                        minWidth: {sm: "100px", md: "400px"},
                        textAlign: "center",
                        transition: "min-width 0.3s ease", // Transition for min-width
                      }}
                    >
                      <Typography
                        key={index}
                        variant="subtitle1"
                        sx={{
                          color: "#292323",
                          display: "flex",
                          fontSize: "1rem",
                          fontFamily:"Poppins",
                          alignItems: "center",
                          padding: "8px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          textAlign: "start",
                          transition: "background-color 0.3s, color 0.3s",
                          "&:hover": {
                            backgroundColor: "#990304",
                            color: "white",
                          },
                        }}
                      >
                        <PlayArrowIcon sx={{ marginRight: "1rem" }} />
                        {item}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Button
        variant="contained"
        onClick={handleExpand}
        sx={{
          bgcolor: "#990304",
          color: "white",
          borderRadius: "50px",
          fontWeight: "500",
          fontSize: "0.8rem",
          fontFamily:"Poppins",
          padding: "15px 50px",
          textAlign: "center",
          mt: -4,
          "& .MuiButton-label": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          "&:hover": {
            background: "#990304",
          },
        }}
      >
        {expanded ? "Collapse" : "Expand to view More"}
      </Button>
    </Box>
  );
};

export default MoreIndustriesOverview;
