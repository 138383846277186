import React from 'react'
import { useNavigate } from 'react-router-dom';
import OverviewSection from '../../components/OverviewSection';
import { Box, Grid, Typography } from '@mui/material';
import SelectUs from '../../components/selectUs';
import Reviews from "../../components/Reviews";
import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"


const WebsiteProcessWebsite = () => {

  // for select us
  const Navigate = useNavigate();
  const handlePackages = () => {
    Navigate("/website/packages");
  };

  //for Reviews

  const ReviewsData = [
    // "/assets/BrandImg/01.png",
    "/assets/BrandImg/02.png",
    "/assets/BrandImg/03.png",
    "/assets/BrandImg/04.png",
    "/assets/BrandImg/05.png",
    "/assets/BrandImg/06.png",
    "/assets/BrandImg/07.png",
    "/assets/BrandImg/08.png",

    // Add more logo URLs as needed
  ];

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "inital";


  return (
    <Box style={{ width: "100%", height: "100%", marginTop: containerWidth === "inital" ? "170px" : "0" }}>
      <OverviewSection
        title1="We Deliver Success"
        subTitle="Through Our Unique Design Solutions
        We Believe In Simple And Effective Process."
        listItems={[
          "Modern Logo Design Concepts",
          "Free Logo Revision Cycles",
          "Iconic Logos For 100% Satisfaction",
          "Affordable Logo Design Packages",
          "Logo Design Gurus At Work",
        ]}
        // imageUrl="/assets/MainContentImg/workspace_04.jpg"
        // imageUrl2="/assets/MainContentImg/workspace_04.jpg"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/how-we-work.jpg"
        reverseLayout={true}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          paddingY: "7rem",
          // paddingBottom: "3rem",
          boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 8px",
          width: containerWidth,
        }}
      >
        <Grid container spacing={3} sx={{ width: "80%" }}>
          {/* First Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/process-tab-1.gif"
              alt="Launch Image"
              style={{ width: "60%", borderRadius: "8px", }}
            />
          </Grid>
          {/* Second Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                RESEARCH
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#292323", fontSize: "1rem", marginTop: "1rem",fontFamily: "Poppins", }}
              >
                Our team of experts carries out a thorough study of the target
                audience and industrial norms. This helps us create ideas that
                help in the fulfillment of the objectives communicated by the
                client. To ensure best results, we follow an extensive research
                regime carried out by experienced professionals.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "80%" }}>
          {/* Third Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                IDEATE
              </Typography>

              <Typography
                variant="body1"
                sx={{ color: "#292323", fontSize: "1rem", marginTop: "1rem" ,fontFamily: "Poppins",}}
              >
                Based on the client’s brief and market research, our experts
                present multiple concepts for the project. These concepts are
                further communicated and discussed with the client to narrow
                down to one concept. The finalized concept prevails throughout
                the process.
              </Typography>
            </Box>
          </Grid>
          {/* Fourth Grid Item */}
          <Grid item xs={12} md={6} >
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/process-tab-2.gif"
              alt="About Uptown Image"
              style={{ width: "60%", borderRadius: "8px", marginTop: "10rem", marginLeft: "5rem" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "80%" }}>
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/process-tab-3.gif"
              alt="About Uptown Image"
              style={{ width: "60%", borderRadius: "8px", marginTop: "10rem" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                CREATE
              </Typography>

              <Typography
                variant="body1"
                sx={{ color: "#292323", fontSize: "1rem", marginTop: "1rem" ,fontFamily: "Poppins",}}
              >
                A draft of the project will be created by our team to provide
                the insight of the final project to the client. It can be
                assessed on the basis of the purpose it has to fulfill. This
                will allow our team and the client to make any required changes
                which will be implemented in the next stage.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "80%" }}>
          {/* Third Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                REVISION
              </Typography>

              <Typography
                variant="body1"
                sx={{ color: "#292323", fontSize: "1rem", marginTop: "1rem",fontFamily: "Poppins", }}
              >
                Once you review the product, you can share with our design
                consultants the tweaks you want in your design element. We make
                all the required changes as per your preference, including font
                style, color, or design among other. The quantity of revisions
                depends on your choice of package.
              </Typography>
            </Box>
          </Grid>
          {/* Fourth Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/process-tab-4.gif"
              alt="About Uptown Image"
              style={{ width: "60%", borderRadius: "8px", marginTop: "10rem", marginLeft: "5rem" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "80%" }}>
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/process-tab-5.gif"
              alt="About Uptown Image"
              style={{ width: "60%", borderRadius: "8px", marginTop: "10rem" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                FINALIZE
              </Typography>

              <Typography
                variant="body1"
                sx={{ color: "#292323", fontSize: "1rem", marginTop: "1rem",fontFamily: "Poppins", }}
              >
                After the client’s approval of the draft, we work towards
                completion of the project along with the adjustments (if there
                are any) and will be tested and delivered to the client in the
                decided manner. Our process ensures complete customization and
                result orientation of the projects.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "80%" }}>
          {/* Third Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15rem",
                marginLeft: "5rem",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                LAUNCH
              </Typography>

              <Typography
                variant="body1"
                sx={{ color: "#292323", fontSize: "1rem", marginTop: "1rem",fontFamily: "Poppins", }}
              >
                We launch your finalized product according to your preference.
                Whether making a website live or delivering the files in all
                formats requested. Our team would be more than happy to assist
                you throughout the project process. We hope you have a fantastic
                work experience with us!
              </Typography>
            </Box>
          </Grid>
          {/* Fourth Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/process-tab-6.gif"
              alt="About Uptown Image"
              style={{ width: "60%", borderRadius: "8px", marginTop: "10rem", marginLeft: "5rem" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          boxShadow: containerWidth === "inital" ? "0px 5px 20px rgba(0, 0, 0.2, 0.2)" : "none",
          marginBottom: "1rem",
          padding: "0rem",
        }}
      >
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
      <Reviews subtitle={'We Have Been Applauded And Recognized By Prestigious Entities Of The Industry'} 
       heading={'Look What Our Customers Have Said About Us'} 
       paragraphs={[
         "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
         "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]} 
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}
      />
      </Box>
  )
}

export default WebsiteProcessWebsite
