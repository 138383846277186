import React from "react";
import OverviewSection from "../../components/OverviewSection";
import SelectUs from "../../components/selectUs";
import { Box, Typography } from "@mui/material";
import Reviews from "../../components/Reviews";
import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"

const WhyChooseUsLogoDesign = () => {
  //for packages
  const handlePackages = () => {
    window.location.href = "logo-design/packages";
  };



  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginTop: "10rem",
        // overflowX: isMediumScreen ? "hidden" : "visible",
      }}
    >
      <OverviewSection
        title1="Why We Offer The Perfect Logo Design Service"
        subTitle="Phenomenal Concepts, Flawless Execution, And Seamless Service Delivery Is Just The Beginning At Uptown."
        listItems={[
          "Modern Logo Design Concepts",
          "Free Logo Revision Cycles",
          "Iconic Logos For 100% Satisfaction",
          "Affordable Logo Design Packages",
          "Logo Design Gurus At Work",
        ]}
        // imageUrl="/assets/MainContentImg/workspace_04.jpg"
        // imageUrl2="/assets/MainContentImg/workspace_04.jpg"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/why-us.jpg"
        reverseLayout={true}
      />

      <Box
        sx={{ padding: "50px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",  display: { xs: "none", sm: "block" }, backgroundColor: "#F1F1F1", }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ textAlign: "center", marginBottom: "20px", alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#990304",
                fontSize: { md: "2.2rem", sm: "2rem" },
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              Why We Are The Perfect Logo-Match
            </Typography>
            <Typography variant="h6"  sx={{ color: "#71706E", paddingX: "5rem" ,fontFamily: "Poppins", fontWeight: "400", fontSize:"1rem"}}>
              Housing a brand with prolific logo designers having mastery in the
              arcana of logo arts & designing, we at Uptown are one of the best
              providers of logo creation services, ascertaining that every
              designed logo at our canvas is nothing but a masterwork!
              Ruminating, ideating, researching, scheming, and, executing each
              concept seamlessly and with utmost dedication. We keep in mind our
              pledge to maintain the 100% customer satisfaction rate that the
              logo drafting fraternity so adores.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "50px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "50px",
              paddingX: "100px",
              paddingY: "10px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "1rem",
                paddingX: "50px",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
                
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-1.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "200px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "400" ,fontFamily: "Poppins",}}
              >
                Professionals
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600" ,fontFamily: "Poppins",}}
              >
                at Helm
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#71706E",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "2px",
                  fontFamily: "Poppins",
                }}
              >
                Housing professionals feted in the industry for their experience
                and expertise, Uptown provides professional logo creation
                services where every masterful stroke at the designing canvass
                is laid only by a professional.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "1rem",
                paddingX: "50px",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
                
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-2.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "200px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "400",fontFamily: "Poppins", }}
              >
                Reliable
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600" ,fontFamily: "Poppins",}}
              >
                Dealings
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#71706E",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "2px",
                  fontFamily: "Poppins",
                }}
              >
                Upholding the pledge to service every client devotedly and
                seamlessly, Uptown ascertains that every single commitment made
                by the company is fulfilled keeping, customer satisfaction at
                the forefront.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "1rem",
                paddingX: "50px",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
                
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-3.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "200px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "400",fontFamily: "Poppins", }}
              >
                Brains
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                Brewing Creativity
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#71706E",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "2px",
                  fontFamily: "Poppins",
                }}
              >
                Brains that work 24/7-365 to chart out ideas for logo sketching
                that is unconventional in their approach, Uptown provides
                creative custom drawings hailed down by some of the most
                brilliant minds working for the industry.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "50px",
              paddingX: "100px",
              paddingY: "10px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "15px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                paddingX: "50px",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
                
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-4.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "400" ,fontFamily: "Poppins", }}
              >
                Congruent
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600" , fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                Design Elements
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#71706E",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "2px",
                  fontFamily: "Poppins",
                }}
              >
                Extracting the uniqueness each disparate element brings to the
                logo drawing, Uptown ascertains that every style gleams
                exclusivity, while the singular elements work together to
                showcase inclusivity
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "15px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                paddingX: "50px",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
                
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-5.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "400",fontFamily: "Poppins", }}
              >
                Lucid-Transparent
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", }}
              >
                Process
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#71706E",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "2px",
                  fontFamily: "Poppins",
                }}
              >
                Implementing a stringent protocol of a 3 layered procedure –
                Ideation, Design, and Payment – “Uptown Logo Design” for its
                veritable clients keeps every transaction as transparent and
                seamless as possible.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "15px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                paddingX: "50px",
                backgroundColor: "#F1F1F1",
                borderRadius: "20px",
                border: "1px solid #990304",
                
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-6.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "400" ,fontFamily: "Poppins",}}
              >
                Satisfaction
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", }}
              >
                Guaranteed
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#71706E",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "2px",
                  fontFamily: "Poppins",
                }}
              >
                Focusing vehemently on the client`s outlook concerning the
                brand`s logo, Uptown sedulously undertakes the onus of designing
                iconic logos that guarantee nothing but 100% satisfaction
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{
    boxShadow: {
      xs: "none", 
      md: "0px 1px 20px rgba(0, 0, 0.4, 0.4)" 
    }
  }}>
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>

      <Reviews
        subtitle={
          "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
        }
        heading={"Look What Our Customers Have Said About Us"}
        paragraphs={[
          "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
          "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]}
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}  
      />
    </div>
  );
};

export default WhyChooseUsLogoDesign;
