import React, { useState } from "react";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

interface PortfolioSelectorProps {
  menuItemData?: MenuItemData[];
}

interface MenuItemData {
  label?: string;
  value?: string;
  images?: string[];
}

const PortfolioSelector: React.FC<PortfolioSelectorProps> = ({ menuItemData }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };
  
  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "280%" : "initial";


  const generateImageStyles = (index: number ) => ({
    width: containerWidth === "initial" ? "20%" : "45%", // Set width to 50% if container width is initial, otherwise 25%
    height: containerWidth === "initial" ? "20%" : "60%", // Set height to 50% if container width is initial, otherwise 25%
    border: "2px solid #990304",
    borderRadius: "5px",
    transition: "transform 0.3s ease",
    transform: hoveredIndex === index ? "scale(1.1)" : undefined,
    cursor: "pointer",
    filter: hoveredIndex === index ? "brightness(35%)" : "brightness(100%)",
  });


  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={containerWidth} >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
          <Typography
            variant="h3"
            sx={{
              color: "#990304",
              fontSize: { xs: "2rem", sm: "1.5rem", md: "1.8rem" },
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            A Glimpse Into Our Award-Winning Logo Portfolio
          </Typography>
          <Typography variant="h6" sx={{ color: "#292323"  ,fontFamily: "Poppins",}}>
            Having Spent Years In The Industry, We Have A Wide Portfolio To Talk
            About
          </Typography>
          <Box padding={5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Select an option" }}
        sx={{
          width: { xs: "100%", sm: "60%", md: "40%" }, // Adjust width based on screen size
          fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" }, // Adjust fontSize based on screen size
          fontWeight: "500",
          fontFamily: "Poppins",
          color: "#990304",
          marginBottom: "3rem",
          "&:hover": {
            borderColor: "#990304",
            // color: "#990304",
          },
        }} 
      >
        <MenuItem value="" disabled sx={{fontFamily: "Poppins"}}>
          Select Your Industry
        </MenuItem>
        {menuItemData && menuItemData.map((item, index) => (
          <MenuItem key={index} value={item.value} sx={{ fontSize: "1rem", fontWeight: "normal", color: "#990304",fontFamily: "Poppins", }}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
          {menuItemData&& menuItemData.map((menuItem, index) => (
            <Box
              key={index}
              sx={{
                display: selectedOption === menuItem.value ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",

              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex", xl: "flex" }, // Apply flex on all screen sizes
                  justifyContent: { xs: "flex-start", sm: "center", md: "center", lg: "center", xl: "center" }, // Start from left on XS, center on other sizes
                  flexWrap: { xs: "nowrap", sm: "wrap", md: "wrap", lg: "wrap", xl: "wrap" }, // Don't wrap on XS, wrap on other sizes
                  gap: "3rem",
                  marginBottom: "50px",
                  flexDirection: "row", // Horizontal direction
                  overflowX: "auto", // Enable horizontal scrolling
                  scrollBehavior: "smooth", // Smooth scrolling
                  "::-webkit-scrollbar": {
                    display: "none", // Hide the scrollbar for Webkit browsers (Chrome, Safari)
                  },
                  scrollbarWidth: "none", // Hide the scrollbar for Firefox
                  msOverflowStyle: "none", // Hide the scrollbar for IE/Edge
                }}
              >
                {menuItem.images&& menuItem.images.map((imageUrl, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={imageUrl}
                    style={generateImageStyles(imgIndex)}
                    onMouseEnter={() => handleMouseEnter(imgIndex)}
                    onMouseLeave={handleMouseLeave}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioSelector;
