import React, { useEffect, useRef, useState } from "react";
import OverviewSection from "../../components/OverviewSection";
import OverviewBenefits from "../../components/OverviewBenefits";
import { Box, Button, Typography } from "@mui/material";
import OverViewDetails from "../../components/OverViewDetails";
import SelectUs from "../../components/selectUs";
import { useNavigate } from "react-router-dom";
import OverviewGallery from "../../components/OverviewGallery";
import Brosheres from "../../components/Brosheres";
import LeadDesignOverviewPack from "../../components/LeadDesignOverviewPack";
import OverviewLogoDesignStappers from "../../components/OverviewLogoDesignStappers";
import MoreIndustriesOverview from "../../components/MoreIndustriesOverview";
import Reviews from "../../components/Reviews";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RegistrationForm from "../../components/RegistrationForm";
import bgimage from "../../components/assets/backgroundImages/BG1.png"
import mainImg01 from "../../Assets/background/workspace_04.jpg"
import mainImg02 from "../../Assets/background/workspace_04.jpg"
import benafitImg01 from "..//..//Assets/benefitsImages/logo-pic-01.png"
import benafitImg02 from "..//..//Assets/benefitsImages/logo-pic-02.png"
import benafitImg03 from "..//..//Assets/benefitsImages/logo-pic-03.png"
import brosherImg01 from "../..//Assets/keyFeatures/guarantee.png"
import  brosherAnimation1 from "../../Assets/broshersIcons/videoAnimation1.gif"
import  brosherAnimation2 from "../../Assets/broshersIcons/videoAnimation2.gif"
import  brosherAnimation3 from "../../Assets/broshersIcons/videoAnimation3.gif"
import  brosherAnimation4 from "../../Assets/broshersIcons/videoAnimation4.gif"
import keyFeatureImg01 from "../../Assets//keyFeatures/guarantee.png"
import keyFeatureImg02 from "../../Assets//keyFeatures/money-back-guarantee.png"
import keyFeatureImg03 from "../../Assets//keyFeatures/think-outside-the-box.png"
import keyFeatureImg04 from "../../Assets//keyFeatures/video-call.png"
import keyFeatureImg05 from "../../Assets//keyFeatures/trophy.png"
import step01 from "../../Assets/stappers/stappers1.gif"
import step02 from "../../Assets/stappers/stappers2.gif"
import step03 from "../../Assets/stappers/stappers3.gif"
import step04 from "../../Assets/stappers/stappers4.gif"
import step05 from "../../Assets/stappers/stappers5.gif"
import step06 from "../../Assets/stappers/stappers6.gif"
import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"







const OverviewLogoDesign = () => {
  const [activeButton, setActiveButton] = useState("logo");

  const handleBrosheresButtonClick = (brosherButtonName: string) => {
    setActiveButton(brosherButtonName);
  };
  //key feature animation
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const box = document.getElementById("animated-box");
      if (box) {
        const rect = box.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight - 100;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //for brosher scroll

  const brosheresRef = useRef<HTMLDivElement>(null);
  const originalRef = useRef<HTMLDivElement | null>(null); // Store the original ref
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    originalRef.current = brosheresRef.current; // Assign the original ref
    // Start scrolling automatically when the component mounts
    startAutoScroll();

    // Clear the interval when the component unmounts
    return () => {
      if (scrollInterval) clearInterval(scrollInterval);
    };
  }, []);

  const startAutoScroll = () => {
    if (originalRef.current) {
      // Set an interval to scroll every 3 seconds
      const interval = setInterval(() => {
        if (originalRef.current) {
          // Calculate the new scroll position
          const newScrollLeft =
            originalRef.current.scrollLeft * 2 +
            originalRef.current.offsetWidth;

          // Calculate the maximum scroll position
          const maxScrollLeft =
            originalRef.current.scrollWidth * 2 -
            originalRef.current.offsetWidth * 2;

          // Scroll to the new position with smooth behavior
          originalRef.current.scrollTo({
            left: newScrollLeft >= maxScrollLeft ? 0 : newScrollLeft,
            behavior: "smooth",
          });
        }
      }, 10000); // Adjust the interval time as needed

      setScrollInterval(interval);
    }
  };

  const handleMouseEnter = () => {
    // Set brosheresRef to null when mouse enters the div
    originalRef.current = null;
  };

  const handleMouseLeave = () => {
    // Restore brosheresRef to its original value when mouse leaves the div
    originalRef.current = brosheresRef.current;
  };

  const handleScrollBack = () => {
    if (originalRef.current) {
      originalRef.current.scrollTo({
        left: originalRef.current.scrollLeft - 1150, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const handleScrollForward = () => {
    if (originalRef.current) {
      originalRef.current.scrollTo({
        left: originalRef.current.scrollLeft + 1150, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  //image for gallery

  const imageGallery = [
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/11.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/07.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/03.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/05.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/06.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/08.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/thmb/09.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/10.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/11.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/12.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/thmb/13.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/14.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/15.jpg",
  ];

  //for overview Details
  const cardsData = [
    {
      title: "Iconic Logo Design",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      buttonText: "View More",
    },
    {
      title: "Typographic Logo Design",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      buttonText: "View More",
    },
    {
      title: "Illustrative Logo Design",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      buttonText: "View More",
    },
    {
      title: "Animative Logo Design",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      buttonText: "View More",
    },
  ];

  //Funtion for calling pkg
  const Navigate = useNavigate();
  const handlePackeages = () => {
    Navigate("/logo-design/packages");
  };

  //funtion for calling card details

  const handleCardDetails = () => {
    Navigate("/logo-design/iconic-logo-design");
  };

  //for stapper
  const steps = [
    {
      step: "Research",
      content:
        "Based on the client`s requisitions, our team of highly specialized professionals gleans and researches the trending norms of the industry. The extensive research regime allows our professional logo designers to curate, and handpick the very essential, yet subtle, elements necessary to transmute the particulars into unique and creative logo designs.",
      imageUrl: step01
    },
    {
      step: "Ideate",
      content:
        "With the content stash full, our professional logo designers conceive a couple of logo design concepts to share with the client. Once scrutinized, the concepts are narrowed down to a single logo design, which is then worked upon throughout the process.",
      imageUrl: step02
    },
    {
      step: "Create",
      content:
        "With the particulars decided, our team of professional logo designers proceeds to craft a preliminary design draft. The draft is then shared with the client, revised if necessary, and later perfected, to create the awe-inspiring logo design that sets the foundation of your marketing stratagems.",
      imageUrl: step03
    },
    {
      step: "Color",
      content:
        "Colors impact emotions and our specialized logo designers make sure that impact lasts an eternity. Meticulously selecting a number of unique color systems as per the brand`s identity, our professional logo designers add life to the client`s logo design",
      imageUrl: step04
    },
    {
      step: "Revision",
      content:
        "Perfection escapes humanity, and thus the revision comes in. If the client doesn’t get completely satisfied with the custom logo design, Uptown Logo Design upholds its promise, and allows tweaking of the logo design elements, until 100% client-satisfaction is achieved.",
      imageUrl: step05
    },
    {
      step: "Finalize",
      content:
        "With the logo finalized, the revisions done (if any), and 100% client-satisfaction achieved, the team moves towards finalizing the entire project. The royalty-free custom logo design is delivered as per the agreed terms, and the process of meticulously crafting the client`s custom logo design comes to an end.",
      imageUrl: step06
    },
  ];

  //for more industriers

  const topics = [
    "Enterprise",
    "B2B",
    "B2C",
    "E-commerce",
    "Education",
    "N0-Profit",
    "Startups",
  ];
  

  //for Reviews

  
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(window.innerWidth >= 960); // Assuming 960 is the breakpoint for md screen
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "170%" : "inital";


  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginTop: containerWidth === "inital" ? "10rem" : "0",
        overflowX: isMediumScreen ? "hidden" : "visible",
      }}
    >
      <Box > 
      <OverviewSection
        title1="Empower Your Brand With Custom Logo Designs"
        listItems={[
          "Modern Logo Design Concepts",
          "Free Logo Revision Cycles",
          "Iconic Logos For 100% Satisfaction",
          "Affordable Logo Design Packages",
          "Logo Design Gurus At Work",
        ]}
        imageUrl={mainImg01}
        imageUrl2={mainImg01}
        bgImage={bgimage}
        reverseLayout={true}
      />
      </Box>

      <OverviewBenefits
        title="Logo Design Benefits"
        subtitles={["Subtitle 1", "Subtitle 2", "Subtitle 3"]}
        paragraphs={[
          " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
          "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
          "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
        ]}
        images={[
          benafitImg01,
          benafitImg02,
          benafitImg03,
          
        ]}
      />
      <OverViewDetails
        mainHeading="Modern Logo Designs For Every Strategy"
        mainSubheading="Special Something For Your Brand? Professional Logo Designers At The Helm!"
        cardsData={cardsData}
        onButtonClick={handleCardDetails} // Pass the function as the onClick handler
      />

      <SelectUs
        buttonText1={"Select Package"}
        buttonText2={"Live chat"}
        onClickVoid={handlePackeages}
        title={"Ready To Select Your Package ?"}
        subtitle={"Or Talk To Our Design Consultant"}
      />

      <OverviewGallery
        mediaLinks={imageGallery}
        title={"A Glimpse Into Our Award-Winning Logo Portfolio"}
        subtitle={
          "Here's Why We Rank As The Leading Logo Design Website In USA"
        }
        portfolioLink={"/logo-design/portfolio"}
      />

      <SelectUs
        buttonText1={"Select Package"}
        buttonText2={"Live chat"}
        onClickVoid={handlePackeages}
        title={"Logo Design Packages For Every Venture"}
        subtitle={
          "From Startups To Leading Organizations, We Cater Every Brand`S Needs Accordingly"
        }
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#F1F1F1",
          padding: "40px",
          marginTop: "0px",
          overflowX: "hidden", // Enable horizontal scrolling
          whiteSpace: "nowrap",
          paddingBottom: "100px",
          Width: containerWidth === "inital" ? "100%" : "200%",
          // boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.8)",
          zIndex: -1,
          width: containerWidth
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            color: "#990304",
            fontWeight: "600",
            textAlign: { xs: "center" },
            fontSize: { xs: "1.5rem", sm: "1rem", md: "1.5rem" },
            fontFamily: "Poppins",
          }}
        >
          Wide Range Of Affordable Logo Design Packages
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ color: "#292323",fontFamily: "Poppins", }}>
          Premium Logo Designs Now Made Accessible To All Businesses
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={{ xs: 1, sm: 2, md: 2 }}
          mt={3}
          mb={3}
        >
          <Button
            variant="outlined"
            size="large"
            sx={{
              fontSize: "1rem",
              fontFamily: "Poppins",
              borderColor:  activeButton === "logo" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "logo" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "logo" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "6rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("logo")} // Call handleButtonClicked with button name
          >
            Logo
          </Button>
          <Button
            variant="outlined"
            size="large"
            sx={{
              fontSize: "1rem",
              fontFamily: "Poppins",
              borderColor:  activeButton === "website" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "website" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "website" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "6rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("website")} // Call handleButtonClicked with button name
          >
            Website
          </Button>
          <Button
            variant="outlined"
            size="large"
            sx={{
              fontSize: "1rem",
              fontFamily: "Poppins",
              borderColor:  activeButton === "videoAnimation" ? "#990304" : "#990304", // Set outline color to green
              color: activeButton === "videoAnimation" ? "#fff" : "#990304", // Set font color to white if button is active
              backgroundColor:
                activeButton === "videoAnimation" ? "#990304" : "transparent", // Set background color to green if button is active
              textTransform: "none", // Prevent text transformation
              paddingX: "6rem", // Add padding to the left and right sides
              "& .MuiButton-label": {
                justifyContent: "center",
                // Center align text horizontally
              },
              "&:hover": {
                backgroundColor: "#990304", // Change background color on hover to green
                color: "#fff", // Change font color on hover to white
                borderColor: "#990304", // Change border color on hover to green
              },
            }}
            onClick={() => handleBrosheresButtonClick("videoAnimation")} // Call handleButtonClicked with button name
          >
            Video Animation
          </Button>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", width: "90%" }}>
          <ArrowBackIosIcon
            onClick={handleScrollBack}
            style={{ cursor: "pointer", fontSize: "3rem", color: "#990304" }}
          />

          <div
            style={{
              display: "flex", // Add some space between items
              justifyContent: "space-between", alignItems:"flex-start" ,
              overflowX: "auto", // Enable horizontal scrolling
              // maxWidth: "100%",
              marginTop: "-3rem",
              paddingTop: "3rem",
              paddingBottom: "3rem",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={brosheresRef}
          >
            {/* Container for the first set of three Brosheres */}
            <div style={{ display: "flex", flexWrap: "nowrap", gap: "10px" ,}}>
              {activeButton === "logo" && (
                <>
                  <Brosheres
                    title1="Best seller"
                    title2="Smart design solution"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$100"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="The ultimade"
                    title2="cutting edge design"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$100"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Bussiness Plus"
                    title2="Ideal Design Solution"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$100"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Enterprise"
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Enterprise Plus"
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Corporate"
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Illurstative Stater"
                    title2="hello"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Illurstative Budget"
                    title2="hello"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Illurstative Ultimate"
                    title2="hello"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                </>
              )}
              {activeButton === "website" && (
                <>
                  <Brosheres
                    title1="Enterprise"
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Enterprise Plus"
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Corporate"
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Best seller"
                    title2="Smart design solution"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$100"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="The ultimade"
                    title2="cutting edge design"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$100"
                    imageSrc={brosherImg01}
                  />
                  <Brosheres
                    title1="Bussiness Plus"
                    title2="Ideal Design Solution"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$100"
                    imageSrc={brosherImg01}
                  />
                </>
              )}
              {activeButton === "videoAnimation" && (
                <>
                  <Brosheres
                    title1="Typography"
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherAnimation1}
                  />
                  <Brosheres
                    title1="Motion Graphgic" 
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc= {brosherAnimation2}
                  />
                  <Brosheres
                    title1="Explainer Video"
                    title2="Brosheres"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$250"
                    imageSrc={brosherAnimation3}
                  />
                  <Brosheres
                    title1="White Board"
                    title2="Smart design solution"
                    listItems={[
                      "List Item 1",
                      "List Item 2",
                      "List Item 3",
                      "List Item 4",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                      "List Item 5",
                    ]}
                    amount="$100"
                    imageSrc={brosherAnimation4}
                  />
                </>
              )}
            </div>
          </div>
          <ArrowForwardIosIcon
          onClick={handleScrollForward}
            style={{ cursor: "pointer", fontSize: "3rem", color: "#990304" }}
          />
        </Box>

        {/* Add custom scrollbar styling */}
        <style>
          {`
        ::-webkit-scrollbar {
          display: none; /* Hide scrollbar */
        }
      `}
        </style>

        <Box
          sx={{
            display: {xs: "none", md: "flex"},
            justifyContent: "center",
            marginTop: "-30px",
            color: "#990304",
            fontSize: "1.5rem",
            fontFamily: "Poppins",
            fontWeight: "500",
          }}
        >
          {" "}
          Key Feature
        </Box>
        <Box
          id="animated-box"
          justifyContent={"center"}
          sx={{
            display: { xs: "none", md: "flex" },
            marginTop: "20px",
            color: "#990304",
            fontSize: "1.5rem",
            position: "relative",
            left: isVisible ? "0" : "-100%", // Initial position outside the viewport
            transition: "left 0.5s ease", // Animation transition
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "10rem",
            }}
          >
            <img
              src= {keyFeatureImg01}
              alt="Image 1"
              style={{ width: "80px", height: "80px", marginBottom: "10px" }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              100% Setisfaction <br />
              Gurentee
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "10rem",
            }}
          >
            <img
              src={keyFeatureImg02}
              alt="Image 2"
              style={{ width: "80px", height: "80px", marginBottom: "10px" }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              Unique logo
              <br /> design
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "10rem",
            }}
          >
            <img
              src= {keyFeatureImg03}
              alt="Image 3"
              style={{ width: "80px", height: "80px", marginBottom: "10px" }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              100% money Back <br />
              Gurentee
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "10rem",
            }}
          >
            <img
              src={keyFeatureImg04}
              alt="Image 3"
              style={{ width: "80px", height: "80px", marginBottom: "10px" }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              24/4 Design <br />
              Consultancy
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "0",
            }}
          >
            <img
              src={keyFeatureImg05}
              alt="Image 3"
              style={{ width: "80px", height: "80px", marginBottom: "10px" }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              Award Winning <br />
              Designers
            </Typography>
          </div>
          {/* Repeat this structure for additional image and quote combinations */}
        </Box>
      </Box>

      <LeadDesignOverviewPack
        title="Lead Design All in one"
        subtitle="The Comprehensive Branding Solution"
        paragraph="Starting from scratch or revamping your brand`s existence? Uptown Logo Design brings forth the very package that gets your business rolling seamlessly. From providing a custom logo design to asserting your brand`s digital presence across all the major platforms, the “All in One” proves to be the singularly comprehensive solution to all your branding predicaments"
        list={[
          "List Item 1",
          "List Item 2",
          "List Item 3",
          "List Item 4",
          "List Item 5",
          "List Item 6",
        ]}
        amount="$100"
      />
      <Box width={"99vw"}>
        <OverviewLogoDesignStappers
          title={"Our Seamless Logo Designing Process"}
          subtitle={"You Relax, While We Do All The Work. It`S This Simple."}
          steps={steps}
        />
      </Box>

      <MoreIndustriesOverview
        title="Over 200 Industries Served And Growing"
        subtitle="Through Our Dynamic Logo Design Services, We Have Meritoriously Served Clients Emanating From Every Industry"
        topics={topics}
        
        listItems={[
          "Accounting",
          "Agriculture & Construction",
          "Automative & Transportation",
          "Clearing & Maintainance",
          "Computer & Technology",
          "Education",
          "Fashion",
          "Industrial Logo Design",
          "Photography",
          "Restaurants",
          "Sports",
          "Technology",
          "Argricultural",
          "Art & Culture",
          "Bussiness Consulting",
          "Communications",
          "Constructing tools",
          "Entertainment",
          "Floral Plans",
          "Landscaping Logo Design",
          "Physical Fitness",
          "Real Estate",
          "Spa-ethistic",
          "Wedding Services",
          "Animal & Pets",
          "Attorney & Law",
          "Child Care",
          "Community",
          "Cosmatics & Beauty",
          "Food & Beverages",
          "Medical & Phrmaceutical",
          "Security",
          "Traval % Hotel",
          "Enviromental",
          "Real State & Mortgage",
          "Food & Drinks",
        ]}
      />

      <Reviews
        subtitle={"We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."}

        heading={"Look What Our Customers Have Said About Us"}
        paragraphs={[
          "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary ",
          "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]}
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}      />
      <RegistrationForm />
    </div>
  );
};

export default OverviewLogoDesign;
