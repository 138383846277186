import React from "react";
import { Box, Typography } from "@mui/material";
import OverviewSection from "../../components/OverviewSection";
import SelectUs from "../../components/selectUs";
import Reviews from "../../components/Reviews";
import reviewDefultImg01 from "../../Assets/BrandImg/12.png"
import reviewDefultImg02 from "../../Assets/BrandImg/11.png"
import reviewDefultImg03 from "../../Assets/BrandImg/10.png"
import rattingImg from "../../Assets/reviwesImg/rating.png"
import reviewImg01 from "../../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../../Assets/reviwesImg/topReview3.jpg"

const WhyChooseUsBranding = () => {
  //for packages
  const handlePackages = () => {
    window.location.href = "website/packages";
  };

  //for Reviews


  return (
    <div style={{ width: "100%", height: "100%", marginTop: "160px" }}>
      <OverviewSection
        title1="What Makes Us The Best"
        subTitle="We’re A Passionate Lot, Known For Their Never-Ending Hunger For Perfection. Delivering Excellence Is Our Custom."
        listItems={[
          "100% Ownership Rights",
          "Brochure Design",
          "Stationery Design",
          "Social Media Banners",
          "T-Shirt Design",
        ]}
        // imageUrl="/assets/MainContentImg/workspace_04.jpg"
        // imageUrl2="/assets/MainContentImg/workspace_04.jpg"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/why-us.jpg"
        reverseLayout={true}
      />

      <Box
        sx={{ padding: "50px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",display: { xs: "none", sm: "block" }, }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#990304",
                fontSize: "2rem",
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              Why Choose Us
            </Typography>
            <Typography variant="h6" sx={{ color: "#666", paddingX: "5rem",fontFamily: "Poppins", fontWeight: "300", fontsize: "1rem" }}>
              We pride ourselves to create a smart and innovative logo which
              contributes to our client’s success. With a team of professional
              designers and developers, we bring our clients custom branding
              solutions. Uptown Logo Design has successfully accomplished 5000+
              projects marked with a 100% success rate. We enable our client’s
              with a right logo which attracts, engage and delights customers
              while building a relationship with their target audience.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "50px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "50px",
              paddingX: "100px",
              paddingY: "50px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-1.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "500",fontFamily: "Poppins",fontSize: "1.2rem", }}
              >
                We are
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins",fontSize: "1.5rem", }}
              >
                Experienced
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                We have been contributing to our client’s success since 2010.
                Our innovative and creative logos are designed smartly to serve
                each brand in a customized and result-oriented fashion.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-2.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "500",fontFamily: "Poppins", fontSize: "1.2rem", }}
              >
                We Are
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                Friendly
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                We encourage a friendly and optimistic culture which instigates
                creativity. Our eagerness, excitement, and originality are
                reflected through our tireless efforts to bring about
                outstanding results.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-3.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "500",fontFamily: "Poppins", fontSize: "1.2rem", }}
              >
                We Are
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                inovative
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                We use innovative technology to create the best designs
                possible. We are the only one to incorporate Chisel, a tool for
                setting up and developing front-end and WordPress projects in
                the industry.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "50px",
              paddingX: "100px",
              paddingY: "50px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-4.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#292323", fontWeight: "500",fontFamily: "Poppins", fontSize: "1.2rem", }}
              >
                We Are
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                Flexible
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                We adapt to the changes in trend, bringing our customers the
                best logo solution to match their specific needs. We analyze,
                propose and design a logo that projects the brand smartly to the
                target audience
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
                sx={{
                  gap: "5px",
                  alignItems: "center",
                  textAlign: "center",
                  paddingY: "3rem",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-5.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#990304", fontWeight: "500",fontFamily: "Poppins", fontSize: "1.2rem", }}
              >
                We Are
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#292323", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                Transparent
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Our design, production and payment process is simple and
                transparent. We work round the clock tirelessly to provide any
                queries our customers have. Details can also be tracked through
                the dedicated user portal.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-6.png"
                }
                alt="Feature"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: "#230304", fontWeight: "500",fontFamily: "Poppins",fontSize: "1.2rem", }}
              >
                We Are
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: "#990304", fontWeight: "600" ,fontFamily: "Poppins", fontSize: "1.5rem", }}
              >
                Different
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                We believe in building a relationship with every customer we
                provide our services. This helps our team to understand our
                client's challenges and ideas. Hence, working with us is more
                than just designing.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{  boxShadow: {
      xs: "none", 
      md: "0px 1px 20px rgba(0, 0, 0.4, 0.4)", 
      paddingBottom: "1rem",
    } }}>
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>

      <Reviews
        subtitle={
          "We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."
        }
        heading={"Look What Our Customers Have Said About Us"}
        paragraphs={[
          "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
          "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]}
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}  
      />
    </div>
  );
};

export default WhyChooseUsBranding;
