import React, { useEffect, useRef, useState } from "react";
import OverviewSection from "../components/OverviewSection";
import { Box, Grid, Typography } from "@mui/material";
import OverviewBenefits from "../components/OverviewBenefits";
import OverViewDetails from "../components/OverViewDetails";
import OverviewGallery from "../components/OverviewGallery";
import SelectUs from "../components/selectUs";
import { useNavigate } from "react-router-dom";
import Brosheres from "../components/Brosheres";
import LeadDesignOverviewPack from "../components/LeadDesignOverviewPack";
import OverviewLogoDesignStepper from "../components/OverviewLogoDesignStappers";
import MoreIndustriesOverview from "../components/MoreIndustriesOverview";
import Reviews from "../components/Reviews";
import RegistrationForm from "../components/RegistrationForm";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import animatedBG from "../Assets/animations/animation-video.gif"
import benafitImg01 from "../Assets/benefitsImages/logo-pic-01.png"
import benafitImg02 from "../Assets/benefitsImages/logo-pic-02.png"
import benafitImg03 from "../Assets/benefitsImages/logo-pic-03.png"
import brosherImg01 from "../Assets/keyFeatures/money-back-guarantee.png"
import keyFeatureImg01 from "../Assets//keyFeatures/guarantee.png"
import keyFeatureImg02 from "../Assets//keyFeatures/money-back-guarantee.png"
import keyFeatureImg03 from "../Assets//keyFeatures/think-outside-the-box.png"
import keyFeatureImg04 from "../Assets//keyFeatures/video-call.png"
import keyFeatureImg05 from "../Assets//keyFeatures/trophy.png"
import step01 from "../Assets/stappers/stappers1.gif"
import step02 from "../Assets/stappers/stappers2.gif"
import step03 from "../Assets/stappers/stappers3.gif"
import step04 from "../Assets/stappers/stappers4.gif"
import step05 from "../Assets/stappers/stappers5.gif"
import step06 from "../Assets/stappers/stappers6.gif"
import reviewDefultImg01 from "../components/assets/BrandImg/10.png"
import reviewDefultImg02 from "../components/assets/BrandImg/11.png"
import reviewDefultImg03 from "../components/assets/BrandImg/12.png"
import rattingImg from "../Assets/reviwesImg/rating.png"
import reviewImg01 from "../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../Assets/reviwesImg/topReview3.jpg"

const Animations = () => {
  //for overview Details
  const cardsData = [
    {
      title: "Explainer Videos",
      paragraph:
        "Explainer videos are ideal to deliver extensive information to your audience. If you cannot afford to let your audience’s attention waver, explainer videos are an ideal solution for you.",
      // buttonText: "View More",
    },
    {
      title: "Motion Graphics",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      // buttonText: "View More",
    },
    {
      title: "Typography Animations",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      // buttonText: "View More",
    },
    {
      title: "Whiteboard Animations",
      paragraph:
        "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
      // buttonText: "View More",
    },
  ];
  function handleButtonClick(): void {
    throw new Error("Function not implemented.");
  }

  //for gallery
  const imageGallery = [
    "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcjB0Y3M2eTBlZXR3NjZseDhmY3ZmMHpoanhqbnExYmVkc2c4MHBrdCZlcD12MV9naWZzX3NlYXJjaCZjdD1n/V5l5ZucxUc7kNTaXWK/giphy.gif",
    "https://media.giphy.com/media/H7CKd1GO6oiZQo7L5d/giphy.gif?cid=790b76115uwdueow6ntfkfst3rrq433524c1cjdfcgliosm4&ep=v1_gifs_search&rid=giphy.gif&ct=g",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/03.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/05.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/06.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/08.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/thmb/09.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/10.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/11.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/12.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/thmb/13.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/14.jpg",
    "https://www.uptownlogodesign.com/assets/images/portfolio/logo/15.jpg",
  ];


  // for select us packages render
  const Navigate = useNavigate();
  const handlePackages = () => {
    Navigate("/logo-design/packages");
  };

  //key feature animation
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const box = document.getElementById("animated-box");
      if (box) {
        const rect = box.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight - 100;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  const brosheresRef = useRef<HTMLDivElement>(null);
  const originalRef = useRef<HTMLDivElement | null>(null); // Store the original ref
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    originalRef.current = brosheresRef.current; // Assign the original ref
    // Start scrolling automatically when the component mounts
    startAutoScroll();

    // Clear the interval when the component unmounts
    return () => {
      if (scrollInterval) clearInterval(scrollInterval);
    };
  }, []);

  const startAutoScroll = () => {
    if (originalRef.current) {
      // Set an interval to scroll every 3 seconds
      const interval = setInterval(() => {
        if (originalRef.current) {
          // Calculate the new scroll position
          const newScrollLeft =
            originalRef.current.scrollLeft * 5 +
            originalRef.current.offsetWidth;

          // Calculate the maximum scroll position
          const maxScrollLeft =
            originalRef.current.scrollWidth * 5 -
            originalRef.current.offsetWidth * 5;

          // Scroll to the new position with smooth behavior
          originalRef.current.scrollTo({
            left: newScrollLeft >= maxScrollLeft ? 0 : newScrollLeft,
            behavior: "smooth",
          });
        }
      }, 10000); // Adjust the interval time as needed

      setScrollInterval(interval);
    }
  };

  const ScrollMouseEnter = () => {
    // Set brosheresRef to null when mouse enters the div
    originalRef.current = null;
  };

  const ScrollMouseLeave = () => {
    // Restore brosheresRef to its original value when mouse leaves the div
    originalRef.current = brosheresRef.current;
  };

  const handleScrollBack = () => {
    if (originalRef.current) {
      originalRef.current.scrollTo({
        left: originalRef.current.scrollLeft - 300, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const handleScrollForward = () => {
    if (originalRef.current) {
      originalRef.current.scrollTo({
        left: originalRef.current.scrollLeft + 300, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

   //for stapper
   const steps = [
    {
      step: "Research",
      content:
        "Based on the client`s requisitions, our team of highly specialized professionals gleans and researches the trending norms of the industry. The extensive research regime allows our professional logo designers to curate, and handpick the very essential, yet subtle, elements necessary to transmute the particulars into unique and creative logo designs.",
      imageUrl: step01
    },
    {
      step: "Ideate",
      content:
        "With the content stash full, our professional logo designers conceive a couple of logo design concepts to share with the client. Once scrutinized, the concepts are narrowed down to a single logo design, which is then worked upon throughout the process.",
      imageUrl: step02
    },
    {
      step: "Create",
      content:
        "With the particulars decided, our team of professional logo designers proceeds to craft a preliminary design draft. The draft is then shared with the client, revised if necessary, and later perfected, to create the awe-inspiring logo design that sets the foundation of your marketing stratagems.",
      imageUrl: step03
    },
    {
      step: "Color",
      content:
        "Colors impact emotions and our specialized logo designers make sure that impact lasts an eternity. Meticulously selecting a number of unique color systems as per the brand`s identity, our professional logo designers add life to the client`s logo design",
      imageUrl: step04
    },
    {
      step: "Revision",
      content:
        "Perfection escapes humanity, and thus the revision comes in. If the client doesn’t get completely satisfied with the custom logo design, Uptown Logo Design upholds its promise, and allows tweaking of the logo design elements, until 100% client-satisfaction is achieved.",
      imageUrl: step05
    },
    {
      step: "Finalize",
      content:
        "With the logo finalized, the revisions done (if any), and 100% client-satisfaction achieved, the team moves towards finalizing the entire project. The royalty-free custom logo design is delivered as per the agreed terms, and the process of meticulously crafting the client`s custom logo design comes to an end.",
      imageUrl: step06
    },
  ];


  //for more industriers
  const topics = [
    "Enterprise",
    "B2B",
    "B2C",
    "E-commerce",
    "Education",
    "N0-Profit",
    "Startups",
  ];




  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "200%" : "initial";



  return (
    <Box style={{ width: "100%", height: "100%", marginTop: "100px", }}>
      <OverviewSection
        title1="We Empower Your Digital Presence"
        subTitle="Enhance Your Brand With Our Professional Services "
        listItems={[
          "Free File Formats For Website",
          "Unlimited Revisions Until Satisfied",
          "100% Money Back Guarantee",
        ]}
        bgImage= {animatedBG}
        setPaddingY="10rem"
        reverseLayout={true}
        subTitleColor="black"
      />
      <OverviewBenefits
        title="Benefits of Animation"
        subtitles={[
          "Enhance marketing strategy",
          "A batter strategy for content",
          "Bring a consept of life",
        ]}
        paragraphs={[
          " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
          "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
          "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
        ]}
        images={[
          benafitImg01,benafitImg02,benafitImg03
        ]}
      />


      <OverViewDetails
        mainHeading="Type Of Animations"
        mainSubheading="Take Advantage Of Our Animation Services Mentioned Below."
        cardsData={cardsData}
        onButtonClick={handleButtonClick}
      />
      
      <OverviewGallery
        mediaLinks={imageGallery}
        title={"A Glimpse Into Our Award-Winning Animation Portfolio"}
        subtitle={
          "Having Spent Years In The Industry, We Have A Wide Portfolio To Talk About"
        }
        backgroundColor= "white"
      />
      <Box
        sx={{
          boxShadow: containerWidth === "initial" ? "0px 5px 20px rgba(0, 0, 0.2, 0.2)":"none",
        }}>
          <SelectUs
            buttonText1={"Select Package"}
            buttonText2={"Live chat"}
            onClickVoid={handlePackages}
            title={"Ready To Select Your Package ?"}
            subtitle={"Or Talk To Our Design Consultant"}
          />
        </Box>
        <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f1f1f1",
          width: containerWidth,
        }}
      >
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            marginTop: "50px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#990304",
              fontSize:{ xs: "2rem", sm: "1.5rem", md: "1.8rem"},
              fontWeight: "600",
              textAlign: "center",
              marginBottom: "10px",
              fontFamily: "Poppins",
            }}
          >
            Carefully Crafted Logo Packages For New And Existing Businesses
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#292323",
              textAlign: "center",
              marginBottom: "20px",
              fontFamily: "Poppins",
            }}
          >
            We Provide Highly Flexible And Affordable Design Options
          </Typography>
          <Box style={{ display: "flex", alignItems: "center", width: "90%" }}>
            <ArrowBackIosIcon
              onClick={handleScrollBack}
              style={{ cursor: "pointer", fontSize: "3rem", color: "#990304" }}
            />
            <div
              style={{
                display: "flex",
                gap: "0px", // Add some space between items
                overflowX: "auto", // Enable horizontal scrolling
                maxWidth: "90%",
                marginTop: "-3rem",
                padding: containerWidth === "initial" ? "1rem" : "5rem",
                paddingTop: "4rem",
                paddingBottom: "4rem"
              }}
              onMouseEnter={ScrollMouseEnter}
              onMouseLeave={ScrollMouseLeave}
              ref={brosheresRef}
            >
              {/* Container for the first set of three Brosheres */}
              <div style={{ display: "flex", flexWrap:containerWidth === "initial" ? "nowrap" : "wrap", gap: "25px", justifyContent: "center" }}>
                <>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                    mb={"1rem"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform 0.3s ease",
                      },
                    }}
                  >
                    <Brosheres
                      title1="Best seller"
                      title2="Smart design solution"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$39"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                    mb={"1rem"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform 0.3s ease",
                      },
                    }}
                  >
                    <Brosheres
                      title1="The Ultimate"
                      title2="Cutting-edge Design"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$68"
                      imageSrc= {brosherImg01}
                    />
                  </Box>

                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                    mb={"1rem"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform 0.3s ease",
                      },
                    }}
                  >
                    <Brosheres
                      title1="Business Plus"
                      title2="Ideal design solutions"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$119"
                      imageSrc={brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Business Plus"
                      title2="Ideal design solutions"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$119"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Enterprise"
                      title2="Perfect design solution"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$199"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="Enterprise Plus"
                      title2="Value added design solutions"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$349"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                  <Box
                    width={{
                      xs: "100%",
                      sm: "calc(50% - 10px)",
                      md: "calc(33.33% - 10px)",
                      lg: "calc(33.33% - 10px)",
                    }}
                  >
                    <Brosheres
                      title1="3D Special"
                      title2="Perspective Brand Identity"
                      listItems={[
                        "List Item 1",
                        "List Item 2",
                        "List Item 3",
                        "List Item 4",
                        "List Item 5",
                      ]}
                      amount="$399"
                      imageSrc= {brosherImg01}
                    />
                  </Box>
                </>
              </div>
            </div>
            <ArrowForwardIosIcon
              onClick={handleScrollForward}
              style={{ cursor: "pointer", fontSize: "3rem", color: "#990304" }}
            />
          </Box>

          {/* Add custom scrollbar styling */}
        <style>
          {`
        ::-webkit-scrollbar {
          display: none; /* Hide scrollbar */
        }
      `}
        </style>

        <Box
          justifyContent={"center"}
          sx={{ marginTop: "-30px", display:{xs: "none", sm: "flex", lg: "flex", xl: "flex", md: "flex"} }}
          color={"#990304"}
          fontSize={"1.2rem"}
          fontFamily={"Poppins"}
        >
          {" "}
          Key Feature
        </Box>
        <Box
          id="animated-box"
          justifyContent={"center"}
          sx={{
            display:{xs: "none", sm: "flex", lg: "flex", xl: "flex", md: "flex"},
            marginTop: "20px",
            color: "#990304",
            fontSize: "1rem",
            position: "relative",
            paddingBottom: "25px",
            left: isVisible ? "0" : "-100%", // Initial position outside the viewport
            transition: "left 0.5s ease", // Animation transition
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "8rem",

            }}
          >
            <img
              src= {keyFeatureImg01}
              alt="Image 1"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              100% Setisfaction 
              Gurentee
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "8rem",
              
            }}
          >
            <img
              src= {keyFeatureImg02}
              alt="Image 2"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              Unique logo
              design
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "8rem",
              
            }}
          >
            <img
              src= {keyFeatureImg03}
              alt="Image 3"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              100% money Back 
              Gurentee
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "8rem",
              
            }}
          >
            <img
              src= {keyFeatureImg04}
              alt="Image 3"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              24/4 Design 
              Consultancy
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "0",
              
            }}
          >
            <img
              src= {keyFeatureImg05}
              alt="Image 3"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textOrientation: "mixed",
                textAlign: "center",
                fontSize: "1rem",
                fontFamily: "Poppins",
              }}
            >
              Award Winning
              Designers
            </Typography>
          </div>
          {/* Repeat this structure for additional image and quote combinations */}
        </Box>
        </Box>
      </Box>
      <LeadDesignOverviewPack title="Lead Design All in one"
        subtitle="The Comprehensive Branding Solution"
        paragraph="Starting from scratch or revamping your brand`s existence? Uptown Logo Design brings forth the very package that gets your business rolling seamlessly. From providing a custom logo design to asserting your brand`s digital presence across all the major platforms, the “All in One” proves to be the singularly comprehensive solution to all your branding predicaments"
        list={[
          "Logo Design",
          "Website Design",
          "Social Media Design",
          "Stationery Design",
          "Banner Design",
          "Special Features",
        ]}
        amount="$1499"
      />
      <Box width={"99vw"}>
      <OverviewLogoDesignStepper title={"Our Seamless Logo Designing Process"} subtitle={"You Relax, While We Do All The Work. It`S This Simple."} steps={steps}/>
      </Box>
      <MoreIndustriesOverview title={"Over 200 Industries Served And Growing"} 
      subtitle={"Through Our Dynamic Logo Design Services, We Have Meritoriously Served Clients Emanating From Every Industry"} 
      topics={topics} 
      listItems={[
        "Accounting",
        "Agriculture & Construction",
        "Automative & Transportation",
        "Clearing & Maintainance",
        "Computer & Technology",
        "Education",
        "Fashion",
        "Industrial Logo Design",
        "Photography",
        "Restaurants",
        "Sports",
        "Technology",
        "Argricultural",
        "Art & Culture",
        "Bussiness Consulting",
        "Communications",
        "Constructing tools",
        "Entertainment",
        "Floral Plans",
        "Landscaping Logo Design",
        "Physical Fitness",
        "Real Estate",
        "Spa-ethistic",
        "Wedding Services",
        "Animal & Pets",
        "Attorney & Law",
        "Child Care",
        "Community",
        "Cosmatics & Beauty",
        "Food & Beverages",
        "Medical & Phrmaceutical",
        "Security",
        "Traval % Hotel",
        "Enviromental",
        "Real State & Mortgage",
        "Food & Drinks",
      ]}
       />
      <Reviews subtitle={"We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."} 
       heading={"Look What Our Customers Say About Us"} 
       paragraphs={[
         "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
         "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
        ]} 
        reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
        ratting={rattingImg} 
        images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}  
      />
       <RegistrationForm />
    </Box>
  );
};

export default Animations;
