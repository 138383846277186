import React, { useState } from "react";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import ForumIcon from "@mui/icons-material/Forum";
import CallIcon from "@mui/icons-material/Call";
// Define interface for props
interface BrosheresProps {
  title1?: string;
  title2?: string;
  listItems?: string[]; // Make listItems optional
  amount?: string;
  imageSrc?: string;
}

const Brosheres: React.FC<BrosheresProps> = ({
  title1,
  title2,
  listItems = [],
  amount,
  imageSrc,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "100vh",
      }}
    >
      <Paper
        elevation={4}
        style={{
          width: 300,
          height: 580,
          padding: 10,
          borderRadius: 20,
          marginTop: "-10rem",
          position: "relative",
          margin: "1rem",
          color: "#FFFFFF",
          backgroundColor: isHovered ? "#F1F1F1" : "#292323",
          border: isHovered ? "1px solid #990304" : "1px solid #F1F1F1",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <style>
          {`
            @keyframes pulse {
              0% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.1);
              }
              100% {
                transform: scale(1);
              }
            }

            /* Custom scrollbar styles */
            ::-webkit-scrollbar {
              width: 8px;
            }
            ::-webkit-scrollbar-track {
              background-color: transparent;
            }
            ::-webkit-scrollbar-thumb {
              background-color: #05386b;
              border-radius: 4px;
            }

            /* Hover effect for link */
            .link:hover {
              color: #fff; /* Change to your desired hover color */
            }
          `}
        </style>
        <Box display="flex" justifyContent="center">
          {imageSrc && (
            <img
              src={imageSrc}
              alt="Brosheres"
              style={{ width: "60px", height: "60px", objectFit: "cover" }}
            />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom={2}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{
              color: isHovered ? "#990304" : "white",
              fontWeight: "bold",
              fontSize: "1.5rem",
              textAlign: "center",
              marginBottom: "2px",
              fontFamily: "Poppins",
            }}
          >
            {title1}
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              color: isHovered ? "#990304" : "white",
              fontWeight: "400",
              textAlign: "center",
              fontSize: "1rem",
              marginBottom: "4px",
              fontFamily: "Poppins",
            }}
          >
            {title2}
          </Typography>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
            maxHeight: 300,

            overflowY: "auto",
            scrollbarWidth: "thin",
            marginRight: "8px",
          }}
        >
          <List
            style={{
              width: "100%",
              maxWidth: "100%",
              padding: 0,
              color: isHovered ? "#990304" : "white",
            }}
          >
            {listItems.map((item, index) => (
              <ListItem
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={item} style={{ fontFamily: "Poppins", fontSize: "12px"}}/>
              </ListItem>
            ))}
          </List>
        </div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="20px"
        >
          <Typography
            variant="body1"
            gutterBottom
            style={{
              color: isHovered ? "#990304" : "white",
              fontWeight: "700",
              textAlign: "center",
              fontSize: "2rem",
              marginTop: "10px",
              fontFamily: "Poppins",
            }}
          >
            {amount}
          </Typography>
          <Typography
            variant="h1"
            gutterBottom
            style={{
              color: "#edf5e1", // Your desired color for discount
              fontWeight: "bold",
              fontSize: "1.5rem", // Adjust as needed
              marginLeft: "5px", // Add spacing between amount and discount
            }}
          >
            | 75% off
          </Typography>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "auto",
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#990304",
              color: "white",
              borderRadius: "50px",
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "0.8rem",
              paddingX: "2.5rem",
              paddingY: "0.5rem",
              textAlign: "center",
              "& .MuiButton-label": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              "&:hover": {
                animation: "pulse 1s infinite",
                background: "#990304",
              },
            }}
            onClick={undefined}
          >
            Order Now
          </Button>
          <Box
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
              display: "flex",
              alignItems: "center", // Align items vertically
              justifyContent: "center", // Align content horizontally
            }}
          >
            <ForumIcon
              sx={{
                color: isHovered ? "#990304" : "white",
                marginRight: "10px",
              }}
            />
            <Link
              to={"livechat.com"}
              className="link"
              style={{
                color: isHovered ? "#990304" : "white",
                textDecoration: "none",
                fontWeight: "600",
                fontSize: "0.8rem",
                fontFamily: "Poppins",
                display: "flex",
                alignItems: "center", // Align items vertically
              }}
            >
              Live Chat
              <span
                style={{
                  color: "#990304",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                |
              </span>
              <CallIcon />
              <span style={{ marginLeft: "5px" }}>1-866-292-8272</span>
            </Link>
          </Box>
        </div>
      </Paper>
    </div>
  );
};

export default Brosheres;
