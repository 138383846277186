import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewSection from "../../components/OverviewSection";
import DownloadGuide from "../../components/DownloadGuide";
import SelectUs from "../../components/selectUs";

const Logo101Website = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://www.uptownlogodesign.com/assets/images/web-choose-slide1.png",
    "https://www.uptownlogodesign.com/assets/images/web-choose-slide2.png",
    "https://www.uptownlogodesign.com/assets/images/web-choose-slide3.png",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handlePackages = () => {
    window.location.href = "website/packages";
  };

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "170%" : "initial";


  return (
    <Box width="100%" height="100%" marginTop="160px">
      <OverviewSection
        title1="The Ultimate
        Web Guide 101"
        subTitle="Build Your Brand And Your Business - With The
        Right Website!"
        subTitleColor="#990304"
        listItems={[
          "Logo Design",
          "Business Cards",
          "Business Logos",
          "Company Logos",
        ]}
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/website-guide-101.jpg"
        reverseLayout={true}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"3rem"}
        width={containerWidth}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography
            variant="h1"
            color={"#990304"}
            fontWeight={"600"}
            fontSize={"2rem"}
            margin={"20px"}
            fontFamily= "Poppins"
          >
            Why Every Business Needs A Website?
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          padding={"1rem"}
        >
          <Box flex={"1"}>
            {/* Left side for typography */}
            <Typography
              variant="subtitle1"
              color={"#292323"}
              fontWeight={"400"}
              marginBottom={"3rem"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "0",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1rem" : "1.3rem",
              }}
            >
              DO YOU HAVE A BUSINESS WITHOUT A WEBSITE?
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#71706E"}
              fontWeight={"400"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "0",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1rem" : "1.3rem",
              }}
            >
              Some of the reasons we get for not designing the website for the
              businesses are ‘’I’m not tech savvy’’, ‘’they are too expensive’’,
              ‘’I don’t have time’’, or the infamous ‘’I have enough business, I
              don’t need one’’. But that is not the correct approach. You do not
              have to be tech savvy to have a website. The purpose is to get
              more clients in an easy and cost effective way and yet make your
              company reachable for thousands of clients around the world.
            </Typography>

            <Typography
             variant="h1"
             color={"#990304"}
             fontWeight={"500"}
             fontSize={"1.2rem"}
             marginTop={"5rem"}
             sx={{
               fontFamily:"Poppins",
               marginLeft: containerWidth === "initial" ? "10rem" : "0",
               width: containerWidth === "initial" ? "70%" : "100%",
             }}
            >
              CHECK THIS OUT...
            </Typography>
            <Typography
              variant="h4"
              color={"#000000"}
              fontWeight={"normal"}
              fontSize={"1.2rem"}
              marginLeft={"15rem"}
              width={"60%"}
            ></Typography>
            <Typography
              variant="subtitle1"
              color={"#71706E"}
              fontWeight={"400"}
              sx={{
                fontFamily: "Poppins",
                marginLeft: containerWidth === "initial" ? "10rem" : "0",
                width: containerWidth === "initial" ? "70%" : "100%",
                fontSize: containerWidth === "initial" ? "1rem" : "1.3rem",
              }}
            >
              81% of people research a business or service on the internet prior
              to making a purchase decision. That’s 25.92 million people
              searching for businesses online.
            </Typography>
          </Box>
          <Box flex={"1"} sx={{ display: { xs: "none", md: "block" } }}>
            {/* Right side for image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/web101-img1.png"
              alt="Your Image"
              style={{ width: "80%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "50px", backgroundColor: "#f1f1f1" ,display: { xs: "none", sm: "block" }, }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#990304",
                fontSize: { xs: "2rem", sm: "2rem", md: "2rem" },
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              Why Do I Need A Strong Brand?
            </Typography>
             <Typography
             variant="h5"
             sx={{
               color: "#292323",
               fontFamily: "Poppins",
               fontSize: { xs: "1.5rem", sm: "1rem", md: "1.2rem" },
               fontWeight: "300",
               display: "flex",
               flexDirection: { xs: "column", sm: "row" },
               justifyContent: { xs: "center", sm: "space-around" }, // Center on XS, space around on other sizes
               alignItems: "center",
               paddingX: "5rem", // Adjust padding for different screen sizes
               paddingBottom: "50px",
             }}
            >
              Branding creates a memorable impression of your business that
              builds trust, motivates employees and helps in acquiring new
              customers in no time
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "50px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "50px",
              paddingX: { xs: "10px", sm: "50px" },
              paddingY: "50px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-1.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontsize: "1rem",
                }}
              >
                IMPROVE RECOGNITION
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Your brand is the “face” of your company. Make it memorable —
                and strong enough to leave the desired impression.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-2.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                BUILD FINANCIAL VALUE
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Developing a strategic plan for the company can help in
                achieving long-term financial gain. To optimize the ROI,
                increase the company’s value and ensure the company meets its
                financial objectives.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "5px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-3.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                CREATE TRUST
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                A professional appearance builds credibility and trust. People
                are more likely to engage with a business that appears polished
                and legitimate.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "50px",
              paddingX: { xs: "10px", sm: "50px" },
              paddingY: "50px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "15px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "2rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-4.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />

              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                INSPIRE EMPLOYEES
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                When employees understand your mission, they feel the same pride
                and will work to achieve your shared goals. As it is said
                “Interesting things happen when the creative impulse is
                cultivated with curiosity, freedom and intensity.”
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: "15px",
                alignItems: "center",
                textAlign: "center",
                paddingY: "3rem",
                backgroundColor: "#f1f1f1",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
              <img
                src={
                  "https://www.uptownlogodesign.com/assets/images/why-us-5.png"
                }
                alt="Feature"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#990304",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "1.2rem",
                }}
              >
                GENERATE NEW CUSTOMERS
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#292323",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  paddingX: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Branding enables your company to get referral business. Could
                you tell a friend about the new shoes you love if you can’t
                remember the brand? Word of mouth referrals are only possible
                after you’ve delivered a memorable experience.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"3rem"}
        sx={{ backgroundColor: containerWidth === "initial" ?  "#f1f1f1": "white" }}
        width={containerWidth}

      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"1rem"}
          boxShadow={"0 0 15px #990304"}
          sx={{ backgroundColor: "#f1f1f1" }}
          width={containerWidth}
        >
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              variant="h1"
              color={"#990304"}
              fontWeight={"600"}
              fontSize={"2rem"}
              textAlign={"center"}
              margin={"20px"}
              fontFamily="Poppins"
            >
              What Makes A Great Website?
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            padding={"1rem"}
            
          >
            <Box flex={"2"} width={containerWidth}>
              {/* Left side for typography */}
              <Typography
                variant="h1"
                color={currentImageIndex === 0 ? "#990304" : "#292323"}
                fontWeight={"700"}
                fontSize={"1.2rem"}
                marginLeft={containerWidth === "initial" ? "10rem" : "5rem"}
                width={"60%"}
                fontStyle={"italic"}
                fontFamily="Poppins"
              >
                Responsive Design
              </Typography>
              <Typography
                variant="subtitle1"
                color={currentImageIndex === 0 ? "#990304" : "#292323"}
                fontWeight={"400"}
                fontSize={"1rem"}
                marginLeft={containerWidth === "initial" ? "10rem" : "5rem"}
                width={"50%"}
                marginBottom={"3rem"}
                fontFamily="Poppins"
              >
                The primary visual element of the overall brand identity. Logo
                is considered to be the face of the brand which is the key to
                get your business noticed.
              </Typography>
              <Typography
                variant="h1"
                color={currentImageIndex === 1 ? "#990304" : "#292323"}
                fontWeight={"700"}
                fontSize={"1.2rem"}
                marginLeft={containerWidth === "initial" ? "10rem" : "5rem"}
                width={"60%"}
                fontStyle={"italic"}
                fontFamily="Poppins"
              >
                User Friendly
              </Typography>
              <Typography
                variant="subtitle1"
                color={currentImageIndex === 1 ? "#990304" : "#292323"}
                fontWeight={"400"}
                fontSize={"1rem"}
                marginLeft={containerWidth === "initial" ? "10rem" : "5rem"}
                width={"50%"}
                marginBottom={"3rem"}
                fontFamily="Poppins"
              >
                Too often, small business owners think a professionally designed
                logo is an expensive project, but a perfect logo can be
                affordably designed making it look better in the eyes of the
                audience – and is a must for every business.
              </Typography>
              <Typography
                variant="h1"
                color={currentImageIndex === 2 ? "#990304" : "#292323"}
                fontWeight={"700"}
                fontSize={"1.2rem"}
                marginLeft={containerWidth === "initial" ? "10rem" : "5rem"}
                width={"60%"}
                fontStyle={"italic"}
                fontFamily="Poppins"
              >
                Minimalism
              </Typography>
              <Typography
                variant="subtitle1"
                color={currentImageIndex === 2 ? "#990304" : "#292323"}
                fontWeight={"400"}
                fontSize={"1rem"}
                marginLeft={containerWidth === "initial" ? "10rem" : "5rem"}
                width={"50%"}
                marginBottom={"3rem"}
                fontFamily="Poppins"
              >
                The primary visual element of the overall brand identity. Logo
                is considered to be the face of the brand which is the key to
                get your business noticed.
              </Typography>
            </Box>
            <Box flex={"2"} sx={{display: { xs: "none", sm: "block" },}}>
              {/* Right side for image */}
              <img
                src={images[currentImageIndex]}
                alt="Your Image"
                style={{ width: "80%", height: "auto", borderRadius: "10px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ width: containerWidth }}
        textAlign={"center"}

      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"3rem"}
          paddingLeft={containerWidth === "initial" ? "0rem" : "8rem"}
          
        >
          <Typography
            variant="h1"
            sx={{
              color: "#990304",
              fontWeight: "600",
              fontSize: "2rem",
              fontFamily: "Poppins",
            }}
          >
            Which Type Of Website Is Right For My Business?
          </Typography>
        </Box>
        <img
          src="https://www.uptownlogodesign.com/assets/images/web101-img3.jpg"
          alt="Your Image"
          style={{ width: "60%", height: "auto", borderRadius: "10px", paddingLeft: containerWidth === "initial" ? "0rem" : "8rem" }}
        />
      </Box>
      <Typography
        variant="body1"
        sx={{
          color: "#333",
          fontWeight: "400",
          fontSize: { xs: "1rem", sm: "1rem", md: "1rem" },
          wordSpacing: "5px",
          padding: "3rem",
          textAlign: "center",
          paddingX: containerWidth === "initial" ? "5rem" : "5rem",
          fontFamily: "Poppins",
          width: containerWidth,

        }}
      >
        <strong style={{ color: "#990304" }}>01. Business Website</strong> The
        business website is an important element of a larger marketing plan,
        which provides a presence for your business. It is a place where you can
        attempt your marketing activities to drive more traffic.{" "}
        <strong style={{ color: "#990304" }}>02. E-Commerce Website:</strong>{" "}
        The easiest and the simplest form for shopping online is through an
        e-commerce website. Internet sales are increasing rapidly through these
        websites. The vendors offer lower prices here compared to stores. It
        saves a lot of time and energy and is convenient for the customers to
        get hold of supplies at the comfort of their home.{" "}
        <strong style={{ color: "#990304" }}>03. Blog site:</strong> A blog is a
        place where you can express yourself to the world by sharing your
        passions and thoughts. You can continuously update the information
        whenever you feel the need for it. It is said to be your own website
        that you can update on an ongoing basis.
      </Typography>

      <Box sx={{ boxShadow: containerWidth === "initial" ? "0px 1px 5px rgba(0, 0, 0.4, 0.4)":"none",}}>
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"3rem"}
        sx={{ backgroundColor: "#f1f1f1" }}
        width={containerWidth}
        

      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography
            variant="h1"
            color={"#990304"}
            fontWeight={"600"}
            fontSize={"2rem"}
            textAlign={"center"}
            margin={"20px"}
            fontFamily="Poppins"
          >
            What Colors Should I Use In My Website?
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-around"}
          alignItems={"flex-start"}
          padding={"2rem"}
        >
           <Box flex={"1"}  marginLeft={containerWidth !== "initial" ? "0rem" : "5rem"} >
            {/* Left side for typography */}
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"400"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              // width={"60%"}
              marginBottom={"1rem"}
              fontFamily="Poppins"
            >
              Color is a key consideration for all visual materials
            </Typography>
            <Typography
              variant="h3"
              color={"#990304"}
              fontWeight={"500"}
              fontSize={"1.2rem"}
              // marginLeft={"15rem"}
              marginBottom={"1rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              Keep it simple
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"400"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"3rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              The most successful logos are simple in terms of colors. In fact,
              two of the most basic colors — black and red — are used most
              frequently. Think of some of the world’s most successful logos,
              such as Pepsi, Coke and Starbucks, which use just one or two
              colors each.
            </Typography>
            <Typography
              variant="h3"
              color={"#990304"}
              fontWeight={"500"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"1.2rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              KNOW WHAT EMOTIONS YOU WANT TO EVOKE, AND WHICH COLORS WILL DO
              THAT
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"400"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"3rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              Identify the key emotional message you want your logo to
              communicate and choose colors to convey that emotion:
            </Typography>
            <Typography
              variant="h3"
              color={"#990304"}
              fontWeight={"500"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"1.2rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              KNOW YOUR TARGET AUDIENCE AND WHAT’S LIKELY TO RESONATE WITH THEM
            </Typography>
            <Typography
              variant="subtitle1"
              color={"#000000"}
              fontWeight={"normal"}
              fontSize={"1rem"}
              // marginLeft={"15rem"}
              marginBottom={"1rem"}
              // width={"60%"}
              fontFamily="Poppins"
            >
              Color relatability can be generational and gender-specific. Both
              men and women say blue is their favorite color and brown their
              least favorite, but women also like purple, whereas men don’t care
              for it at all. On the other hand, men are slightly more partial to
              black.
            </Typography>
          </Box>
          <Box flex={"1"} 
            marginLeft={"2rem"}
          display={{ xs: "none", md: "flex" }}
          >
            {/* Right side for image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/logo101-img4.png"
              alt="Your Image"
              style={{ width: "90%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        bgcolor={"#292323"}
        padding={"2rem"}
        width={containerWidth}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h1"
            color={"white"}
            fontWeight={"600"}
            fontSize={"2rem"}
          fontFamily= "Poppins"

          >
            What Fonts Should I Use For My Logo?
          </Typography>
          <Typography
            variant="subtitle1"
            color={"white"}
            fontWeight={"400"}
            fontSize={"1.2rem"}
            marginY={"2rem"}
          fontFamily= "Poppins"

          >
            Typefaces are as important as color. Consider what the look of your
            text says to clients and potential customers:
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"2rem"}
            fontFamily={"Brush Script MT"}
            marginY={"5px"}
          >
            Script fonts: elegant, affectionate, creative
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"2rem"}
            fontFamily={"Serif "}
            marginY={"5px"}
          >
            Serif fonts: traditional, reliable
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"bold"}
            fontSize={"2rem"}
            fontFamily={"Sans serif "}
            marginY={"5px"}
          >
            Sans serif: stable, steady, clean
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"2rem"}
            fontFamily={"Modern"}
            marginY={"5px"}
          >
            Modern: strong, stylish
          </Typography>
          <Typography
            variant="h3"
            color={"white"}
            fontWeight={"normal"}
            fontSize={"2rem"}
            fontFamily={"Display"}
            marginY={"5px"}
          >
            Display: friendly, unique
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "50px", width: containerWidth }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#990304",
                fontSize: "2rem",
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              What’s A Style Guide? Do I Need One?
            </Typography>
            <Typography variant="h6" sx={{ color: "#292323", paddingX: containerWidth !== "initial" ? "0rem" : "5rem" ,fontFamily: "Poppins", fontWeight:400, marginBottom: "20px", fontSize: "1rem"}}>
              Yes! It’s important to have a style guide laying out standards for
              the visuals and copy associated with your brand. A style guide
              maintains consistency across your organization — no matter how
              large or small. Here are six elements to think about when
              developing your guide. After the guide is complete, share it with
              your employees and revisit it annually to see if updates are
              needed.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "50px" }}>
          <Grid container justifyContent="center" spacing={2} paddingX={containerWidth !== "initial" ? 20 : 5}>
            <Grid item xs={12} sm={4}  md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#f1f1f1",
                  boxShadow: "0 0 50px #EFEFEF",
                  borderRadius: "20px",
                  overflow: "hidden",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="bold"
                  fontFamily= "Poppins"
                >
                  ①
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ color: "#990304", fontWeight: "500",fontFamily: "Poppins", }}
                >
                  BUSINESS MISSION OR ESSENCE
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#292323",
                    textAlign: "center",
                    fontSize: "1rem",
                    paddingX: "50px",
                    fontFamily: "Poppins",
                  }}
                >
                  To study the mission is very important. Research on the
                  history of the company to get details and explain in clear
                  words, what the company is all about?
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}  md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#f1f1f1",
                  boxShadow: "0 0 50px #EFEFEF",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="bold"
                  fontFamily= "Poppins"
                >
                  ②
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "#990304", fontWeight: "500",fontFamily: "Poppins", }}
                >
                  LOGO
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#292323",
                    textAlign: "center",
                    fontSize: "1rem",
                    paddingX: "50px",
                    fontFamily: "Poppins",
                  }}
                >
                  Your style guide should include your logo in various sizes and
                  file formats, and guidelines dictating how and where the logo
                  may be used for making it.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}  md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#f1f1f1",
                  boxShadow: "0 0 50px #EFEFEF",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="bold"
                  fontFamily= "Poppins"
                >
                  ③
                </Typography>

                <Typography
                  variant="h5"
                  sx={{ color: "#990304", fontWeight: "500" ,fontFamily: "Poppins",}}
                >
                  FONTS
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#292323",
                    textAlign: "center",
                    fontSize: "1rem",
                    paddingX: "50px",
                    fontFamily: "Poppins",
                  }}
                >
                  Select fonts and sizes according to the company’s standard. A font does everything from representing the brand  name to the font you want
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={2} paddingX={containerWidth !== "initial" ? 20 : 5}>
            <Grid item xs={12} sm={4}  md={4}>
              <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              marginTop={"3rem"}
              paddingY="1rem"
              sx={{
                gap: "5px",
                backgroundColor: "#f1f1f1",
                boxShadow: "0 0 50px #EFEFEF",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="500"
                  fontFamily= "Poppins"
                >
                  ④
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "#990304", fontWeight: "500" ,fontFamily: "Poppins",}}
                >
                  VOICE
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#292323", fontSize: "1rem",fontFamily: "Poppins", }}
                >
                  The most important thing for the marketing professionals and
                  copywriters who are working on your brand is voice guidelines.
                  Who is speaking? What is the voice of the company
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}  md={4} >
              <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              marginTop={"3rem"}
              paddingY="1rem"
              sx={{
                gap: "5px",
                backgroundColor: "#f1f1f1",
                boxShadow: "0 0 50px #EFEFEF",
                borderRadius: "20px",
                border: "1px solid #990304",
              }}
            >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="500"
                  fontFamily= "Poppins"
                >
                  ⑤
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "#990304", fontWeight: "500",fontFamily: "Poppins", }}
                >
                  COLORS
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#292323", fontSize: "1rem",fontFamily: "Poppins", }}
                >
                  Some brands are instantly recognizable by the color of their
                  logos. These colors are forever associated with the companies.
                  Careful selection of colors will help in recalling the brand
                  easily.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}  md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                marginTop={"3rem"}
                paddingY="1rem"
                sx={{
                  gap: "5px",
                  backgroundColor: "#f1f1f1",
                  boxShadow: "0 0 50px #EFEFEF",
                  borderRadius: "20px",
                  border: "1px solid #990304",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#990304",
                    fontSize: "5rem",
                    marginBottom: "15px",
                  }}
                  fontWeight="bold"
                  fontFamily= "Poppins"
                >
                  ⑥
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "#990304", fontWeight: "500", fontFamily: "Poppins" }}
                >
                  IMAGES
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#292323", fontSize: "1rem", paddingX: "50px",fontFamily: "Poppins" }}
                >
                  Are there certain types of imagery that fit with your brand
                  style? Provide clear guidelines to simplify selecting photos
                  for creative materials.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DownloadGuide
        pdfUrl={"https://clickdimensions.com/links/TestPDFfile.pdf"}
      />
    </Box>
  );
};

export default Logo101Website;

{
  /* <DownloadGuide pdfUrl={"https://clickdimensions.com/links/TestPDFfile.pdf"} /> */
}
