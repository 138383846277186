import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import OverviewSection from "../components/OverviewSection";
import OverviewBenefits from "../components/OverviewBenefits";
import OverViewDetails from "../components/OverViewDetails";
import SelectUs from "../components/selectUs";
import { useNavigate } from "react-router-dom";
import OverviewLogoDesignStepper from "../components/OverviewLogoDesignStappers";
import Reviews from "../components/Reviews";
import benafitImg01 from "../Assets/benefitsImages/logo-pic-01.png"
import benafitImg02 from "../Assets/benefitsImages/logo-pic-02.png"
import benafitImg03 from "../Assets/benefitsImages/logo-pic-03.png"
import step01 from "../Assets/stappers/stappers1.gif"
import step02 from "../Assets/stappers/stappers2.gif"
import step03 from "../Assets/stappers/stappers3.gif"
import step04 from "../Assets/stappers/stappers4.gif"
import step05 from "../Assets/stappers/stappers5.gif"
import step06 from "../Assets/stappers/stappers6.gif"
import reviewDefultImg01 from "../components/assets/BrandImg/10.png"
import reviewDefultImg02 from "../components/assets/BrandImg/11.png"
import reviewDefultImg03 from "../components/assets/BrandImg/12.png"
import rattingImg from "../Assets/reviwesImg/rating.png"
import reviewImg01 from "../Assets/reviwesImg/topReview1.jpg"
import reviewImg02 from "../Assets/reviwesImg/topReview2.jpg"
import reviewImg03 from "../Assets/reviwesImg/topReview3.jpg"

const MobileApps = () => {

    //Form submitions

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('Select Your Country');
    const [industry, setIndustry] = useState('Select Your Industry');
    const [platform, setPlatform] = useState('Select Your Plateform');
    const [device, setDevice] = useState('Select Your Device');
    const [screens, setScreens] = useState('');
    const [idea, setIdea] = useState('');
    const [budget, setBudget] = useState('');
    const [requirement, setRequirement] = useState('');
    const [pushNotification, setPushNotification] = useState(false);
    const [socialAndEngagement, setSocialAndEngagement] = useState(false);
    const [addChat, setAddChat] = useState(false);
    const [billingAndECommerce, setBillingAndECommerce] = useState(false);
    const [userGenerateContent, setUserGenerateContent] = useState(false);
    const [adminFeedbackAndAnalytics, setAdminFeedbackAndAnalytics] = useState(false);
    const [datesAndLocations, setDatesAndLocations] = useState(false);
    const [marketing, setMarketing] = useState(false);

    const handleSubmit = (event:any) => {
        event.preventDefault();
        // Form data
        const formData = {
          name,
          email,
          phoneNumber,
          country,
          industry,
          platform,
          device,
          screens,
          idea,
          budget,
          requirement,
          pushNotification,
          socialAndEngagement,
          addChat,
          billingAndECommerce,
          userGenerateContent,
          adminFeedbackAndAnalytics,
          datesAndLocations,
          marketing,
        };
        console.log('Form submitted:', formData);
      };

  //for overview Details
  const cardsData = [
    {
      title: "iOS Applications",
      paragraph:
        "Your audience deserves a flawless user experience which encompasses their expectations when using your app. We delivers you a fast, attractive and engaging app that gives a boost to audience engagement.",
      // buttonText: "View More",
    },
    {
      title: "Android Applications",
      paragraph:
        "Apps are a maze of multi-platform features serving varied purposes. The design and structure portray the quality which your brand promises. Uptown Logo Design serves you a flawless app that is a joy to use.",
      // buttonText: "View More",
    },
    {
      title: "Multi Platform Applications",
      paragraph:
        "Engage a multitude of smartphone users with your app, regardless of the operating system your audience use. Uptown Logo Design can help you design and develop",
      // buttonText: "View More",
    },
    {
      title: "Game Applications",
      paragraph:
        "Game apps require extensive industry knowledge to develop a seamless interface. Uptown Logo Design has the required expertise which brews the right components for the ultimate gaming experience.",
      // buttonText: "View More",
    },
  ];
  function handleButtonClick(): void {
    throw new Error("Function not implemented.");
  }

  const Navigate = useNavigate();
  const handlePackages = () => {
    Navigate("/website/packages");
  };

  //for stapper
  const steps = [
    {
      step: "Reflection and Concept",
      content:
        "Our team of experts carries out an extensive research regime to find out the industrials norms and latest trends. This research analysis is compiled together and reflected upon by experts. The final idea is then incorporated into a concept for your App..",
      imageUrl: step01
    },
    {
      step: "Design",
      content:
        "Our design experts create a design for your app, this design is presented to you for confirmation. You can request for the finals tweaks in the layout and design. Our designing team makes the changes as per your requirement and proceed to the development stage..",
        imageUrl: step02

    },
    {
      step: "Development",
      content:
        "Our developers take up the task and efficiently develop your app. We assure you that your app will be compatible with all mobile devices. If you want any specific animations in your app you can request it from our design consultants and our developers will carry it out effectively..",
        imageUrl: step03

    },
    {
      step: "Review",
      content:
        "Once the final app is ready, with complete design and development. Our experts conduct a review regime which allows them to gauge the efficiency of the app. Our consultants will help you go through your app's functionality thoroughly, once you finalize the app we will upload it.",
        imageUrl: step04

    },
    {
      step: "Upload",
      content:
        "Once you are completely satisfied with design and development of your app, our team will carry out the uploading procedure as per your requirement. We can upload it to both Apple Store, Play Store or Windows App Store or all three as per your target audience..",
        imageUrl: step05

    },
    {
      step: "Maintenance and Evolution",
      content:
        "To help you optimize your app we offer maintenance and evolution service. We will help you fix bugs and make tweaks over the period of six months and make sure your app is fully functional and in the best shape when we leave it in your hands. Your satisfaction is our success.",
        imageUrl: step06

    },
  ];

    //for Reviews

    const ReviewsSlidingimages = [
        // "/assets/BrandImg/01.png",
        "/assets/BrandImg/02.png",
        "/assets/BrandImg/03.png",
        "/assets/BrandImg/04.png",
        "/assets/BrandImg/05.png",
        "/assets/BrandImg/06.png",
        "/assets/BrandImg/07.png",
        "/assets/BrandImg/08.png",
    
        // Add more logo URLs as needed
      ];

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "200%" : "inital";


  return (
    <Box style={{ width: "100%", height: "100%", marginTop: "100px" }}>
      <OverviewSection
        title1="Connect Smartly With Mobile App"
        subTitle="Guaranteed Impressive Business Outcomes"
        listItems={[
          "IOS Application",
          "Android Application",
          "Cross-Platform Compatibility",
          "Game App",
          "100% Ownership Rights",
        ]}
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/mobile-apps.jpg"
        setPaddingY="10rem"
        reverseLayout={true}
        subTitleColor="#990304"
      />
      <OverviewBenefits
        title="Benefits of Mobile Applications"
        subtitles={[
          "Cost Efficient",
          "A Consultancy for Your product",
          "Increased Productivity",
        ]}
        paragraphs={[
          " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
          "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
          "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley",
        ]}
        images={[
          benafitImg01,benafitImg02,benafitImg03
        ]}
      />
      <OverViewDetails
        mainHeading={"Types Of Platforms"}
        mainSubheading={
          "ake Advantage Of Our Mobile Apps Services Mentioned Below."
        }
        cardsData={cardsData}
        onButtonClick={handleButtonClick}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          padding: 1,
          paddingBottom: "3rem",
          width: containerWidth,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding={5}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#990304",
              fontWeight: "600",
              fontSize: "2rem",
              fontFamily: "Poppins",
            }}
          >
            Our Award-Winning Portfolio
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: "#666", fontWeight: "400", fontSize: "1.2rem",fontFamily: "Poppins", textAlign: "center" }}
          >
            Having Spent Years In The Industry, We Have A Wide Portfolio To Talk
            About
          </Typography>
        </Box>
        <Grid container spacing={3} sx={{ width: "70%", padding: "3rem" }}>
          {/* First Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/apps-1.png"
              alt="Launch Image"
              style={{ width: "60%", borderRadius: "8px" }}
            />
          </Grid>
          {/* Second Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                
              }}
            >
              <Typography
                variant="h3"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem" }}
              >
                Herman’s World
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#666",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                }}
              >
                Herman’s world is a brand owned by a New York-based fashion
                designer, gaining a reputation for exceptional office wear. They
                came to us looking for ways to optimize their brand reach. With
                this e-commerce mobile app, they were able to provide their
                customers the quality service and products, which helped them
                build a credible clientele over a short time period. Now they
                enjoy a huge clientele and several loyal customers!
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "70%", padding: "3rem" }}>
          {/* Third Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5rem",
              }}
            >
              <Typography
                variant="h3"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem" }}
              >
                Mindful Body Fitness
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#666",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                }}
              >
                Instead of getting people to come to a gym and pay a fee for
                their own fitness, Mindful came up with an idea to reach out to
                people more effectively, through an app, motivating, tracking
                and training people on a daily basis. We designed their app with
                a build in trainer guide, tracking system, and music syncing
                system. Making it a go-to app for thousands of people. Our
                client surely benefitted aplenty.
              </Typography>
            </Box>
          </Grid>
          {/* Fourth Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/apps-2.png"
              alt="About Uptown Image"
              style={{
                width: "60%",
                borderRadius: "8px",
                marginTop: "5rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "70%", padding: "3rem" }}>
          {/* First Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/apps-3.png"
              alt="Launch Image"
              style={{ width: "60%", borderRadius: "8px" }}
            />
          </Grid>
          {/* Second Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                
              }}
            >
              <Typography
                variant="h3"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem" }}
              >
                Young at Heart
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#666",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                }}
              >
                We assisted our client with brand revamp, changed the look and
                interface of the app, making it more attractive and
                user-friendly in the customization process. We added thousands
                of customization elements, making the app fun to use, dynamic
                and enhancing both its look and usability. Our customer has
                expanded their reach ever since the revamp.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "70%", padding: "3rem" }}>
          {/* Third Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5rem",
              }}
            >
              <Typography
                variant="h3"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem" }}
              >
                Plan Smart Planner
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#666",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                }}
              >
                A quick signup, easy selection of dates and paying method is
                what we planned in this app for our client Plan Smart Planner.
                We strategically planned and developed a secure payment process
                which allowed our customers to ensure their clients about their
                app. Now they have gained a reputation for excellent travel
                planner in their region, we also assisted them with branding
                strategy and now they enjoy a large number of loyal customers.
              </Typography>
            </Box>
          </Grid>
          {/* Fourth Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/apps-4.png"
              alt="About Uptown Image"
              style={{
                width: "60%",
                borderRadius: "8px",
                marginTop: "5rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ width: "70%", padding: "3rem" }}>
          {/* First Grid Item */}
          <Grid item xs={12} md={6}>
            {/* Image */}
            <img
              src="https://www.uptownlogodesign.com/assets/images/apps-5.png"
              alt="Launch Image"
              style={{ width: "60%", borderRadius: "8px" }}
            />
          </Grid>
          {/* Second Grid Item */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

              }}
            >
              <Typography
                variant="h3"
                sx={{ color: "#990304", fontWeight: "600",fontFamily: "Poppins", fontSize: "1.5rem" }}
              >
                Two Pesos
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#666",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  fontFamily: "Poppins",
                }}
              >
                Our client came to us with a creative name “Two Pesos” an
                incredible idea! We made their dream come true with this app
                which is easy to use and simple. It includes all restaurants,
                cafes and other eatery options the user can select from. They
                run their bikes to deliver ordered food to their users. Their
                efficiency along with our user-friendly interface has taken
                their business to another level altogether.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{  boxShadow: {
      xs: "none", 
      md: "0px 1px 5px rgba(0, 0, 0.4, 0.4)" 
    } }}>
        
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"} // Ensure items are stacked vertically
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          backgroundImage:
            "url(https://www.uptownlogodesign.com/assets/images/portf-bg.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "30px",
          width: containerWidth,
        }}
      >
        {/* Typography wrapped in a Box to start on a new line */}
        <Box mb={3}>
          {" "}
          {/* Adding margin bottom for separation */}
          <Typography
            variant="h4"
            sx={{ color: "#990304", fontWeight: "600", fontSize: "2rem",fontFamily: "Poppins", }}
          >
            Request For Proposal
          </Typography>
        </Box>

        {/* Grid wrapped in a Box */}
        <Box padding={5}>
          <Grid container spacing={12} padding={3}>
            {/* Each item takes 3 grids on small screens and 12 grids on extra small screens */}
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-basic"
                label="Name"
                variant="standard"
                size="small"
                value={name}
                fullWidth
                onChange={(e)=>{setName(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-search"
                label="Email"
                type="search"
                variant="standard"
                size="small"
                fullWidth
                value={email}
                onChange={(e)=>{setEmail(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-search"
                label="phone number"
                type="search"
                variant="standard"
                size="small"
                fullWidth
                value={phoneNumber}
                onChange={(e)=>{setPhoneNumber(e.target.value)}}
                InputProps={{
                  style: {
                    color: "#898989",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-search"
                label="Country"
                select
                variant="standard"
                size="small"
                fullWidth
                value={country}
                onChange={(e)=>{setCountry(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                // Define a list of industries
                SelectProps={{
                  defaultValue: "Select Your Country", // Set default value to empty
                }}
              >
                {/* List of countries */}
                <MenuItem value="Select Your Country">
                  Select Your Country
                </MenuItem>
                <MenuItem value="United States of America">
                  United States of America
                </MenuItem>
                <MenuItem value="Canada">Canada</MenuItem>
                <MenuItem value="UK">UK</MenuItem>
                {/* Add more countries as needed */}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={12} padding={3}>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-search"
                label="Select Your Industry"
                select
                variant="standard"
                size="small"
                fullWidth
                value={industry}
                onChange={(e)=>{setIndustry(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                SelectProps={{
                  defaultValue: "Select Your Industry", // Set default value to empty
                }}
              >
                {/* Empty value as a placeholder */}
                <MenuItem value="Select Your Industry">
                  Select Your Industry
                </MenuItem>
                <MenuItem value="Games">Games</MenuItem>
                <MenuItem value="Social Media">Social Media</MenuItem>
                <MenuItem value="Education">Education</MenuItem>
                {/* Add more industries as needed */}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="standard-search"
                label="Select Plateform"
                select // Setting select to true to render it as a select field
                variant="standard"
                size="small"
                fullWidth
                value={platform}
                onChange={(e)=>{setPlatform(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                // Define a list of countries
                SelectProps={{
                  defaultValue: "Select Your Plateform", // Set default value to empty
                }}
              >
                <MenuItem value="Select Your Plateform">
                  Select Your Plateform
                </MenuItem>
                <MenuItem value="Ios game development">
                  Ios game development
                </MenuItem>
                <MenuItem value="Android game development">
                  Android game development
                </MenuItem>
                <MenuItem value="game development">game development</MenuItem>
                <MenuItem value="Cross plateforms">Cross plateforms</MenuItem>
                {/* Add more countries as needed */}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-search"
                label="Select Device"
                select // Setting select to true to render it as a select field
                variant="standard"
                size="small"
                fullWidth
                value={device}
                onChange={(e)=>{setDevice(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                // Define a list of countries
                SelectProps={{
                  defaultValue: "Select Your Device", // Set default value to empty
                }}
              >
                <MenuItem value="Select Your Device">
                  Select Your Device
                </MenuItem>
                <MenuItem value="Android">Android</MenuItem>
                <MenuItem value="IOS">IOS</MenuItem>
                {/* Add more countries as needed */}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-search"
                label="Number of Screens"
                type="number"
                variant="standard"
                size="small"
                fullWidth
                value={screens}
                onChange={(e)=>{setScreens(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={12} padding={3}>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-search"
                label="Any Idea you want to attach"
                type="multiline"
                variant="standard"
                size="small"
                fullWidth
                value={idea}
                onChange={(e)=>{setIdea(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="standard-search"
                label="Select your budget"
                type="search"
                variant="standard"
                size="small"
                fullWidth
                value={budget}
                onChange={(e)=>{setBudget(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="standard-search"
                label="Any Other Requirement"
                type="search"
                variant="standard"
                size="small"
                fullWidth
                value={requirement}
                onChange={(e)=>{setRequirement(e.target.value)}}
                InputProps={{
                  style: {
                    color: "Black",
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: "Normal",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  // Style the label
                  style: {
                    color: "#898989",
                    fontSize: "1rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                    // Change the underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "black", // Change the underline color when hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black", // Change the underline color after focus
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontSize: "1.2rem", // Change label font size
                    fontFamily: "Poppins", // Change label font family
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={12} padding={5} >
            <Grid item xs={12} sm={6} md={6}>
              <FormGroup >
                <FormControlLabel sx={{fontFamily: "Poppins", }}
                  control={
                    <Checkbox sx={{ "&.Mui-checked": { color: "#990304", fontFamily: "Poppins" } }} checked={pushNotification} onChange={(e)=>{setPushNotification(e.target.checked)}} />
                  }
                  label="Would you like to Push Notification,Emails,SMS "
                />
                <FormControlLabel
                  control={
                    <Checkbox sx={{ "&.Mui-checked": { color: "#990304",fontFamily: "Poppins" } }} checked={socialAndEngagement} onChange={(e)=>{setSocialAndEngagement(e.target.checked)}} />
                  }
                  label="Socila and Engagement"
                />
                <FormControlLabel
                  control={
                    <Checkbox sx={{ "&.Mui-checked": { color: "#990304",fontFamily: "Poppins" } }} checked={addChat} onChange={(e)=>{setAddChat(e.target.checked)}} />
                  }
                  label="Would you like to add chat on your App"
                />
                <FormControlLabel
                  control={
                    <Checkbox sx={{ "&.Mui-checked": { color: "#990304",fontFamily: "Poppins" } }} checked={billingAndECommerce} onChange={(e)=>{setBillingAndECommerce(e.target.checked)}} />
                  }
                  label="Billing and e-Commerce"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox sx={{ "&.Mui-checked": { color: "#990304",fontFamily: "Poppins" } }} checked={userGenerateContent} onChange={(e)=>{setUserGenerateContent(e.target.checked)}}/>
                  }
                  label="Add user Genrate Content "
                />
                <FormControlLabel
                  control={
                    <Checkbox sx={{ "&.Mui-checked": { color: "#990304",fontFamily: "Poppins" } }} checked={adminFeedbackAndAnalytics} onChange={(e)=>{setAdminFeedbackAndAnalytics(e.target.checked)}}/>
                  }
                  label="Admin feedback and analytics"
                />
                <FormControlLabel
                  control={
                    <Checkbox sx={{ "&.Mui-checked": { color: "#990304",fontFamily: "Poppins" } }} checked={datesAndLocations} onChange={(e)=>{setDatesAndLocations(e.target.checked)}} />
                  }
                  label="dates and locations"
                />
                <FormControlLabel
                  control={
                    <Checkbox sx={{ "&.Mui-checked": { color: "#990304",fontFamily: "Poppins" } }} checked={marketing} onChange={(e)=>{setMarketing(e.target.checked)}}/>
                  }
                  label="Would you like to add Marketing to your App"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent="center" mt={3}>
          <Button onClick={handleSubmit} variant="contained" sx={{ background: '#990304', borderRadius: '30px', height: '60px', width: '30%', fontSize: '1rem', fontWeight: 'bold' }}>
            Submit
          </Button>
          </Box>
        </Box>
      </Box>
      <Box width={"99vw"}>
        <OverviewLogoDesignStepper
          title={"Our Comprehensive Process"}
          subtitle={
            "Our Process Is Simple And Aims At Delivering Flawless Results For Every Project"
          }
          steps={steps}
        />
      </Box>
      <Reviews subtitle={"We Have Been Applauded And Recognized By Prestigious Entities Of The Industry."} 
      heading={"Look What Our Customers Say About Us"} 
      paragraphs={[
        "A review is a survey over a whole subject or division of it, or especially an article making a critical reconsideration and summary of something written: a review of the latest book on Chaucer",
        "A criticism is a judgment, usually in an article, either favorable or unfavorable or both: a criticism of a proposed plan",
      ]} 
      reviewImages={[reviewImg01,reviewImg02,reviewImg03]}
      ratting={rattingImg} 
      images={[reviewDefultImg01,reviewDefultImg02,reviewDefultImg03]}   />
    </Box>
  );
};

export default MobileApps;
