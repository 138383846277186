import React from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  QuestionAnswerRounded as QuestionAnswerRoundedIcon,
  CallRounded as CallRoundedIcon,
} from "@mui/icons-material";
import styled, { keyframes } from "styled-components";

interface SelectUsProps {
  title?: string;
  subtitle?: string;
  buttonText1: string;
  buttonText2: string;
  onClickVoid: () => void;
}

const fadeInBottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedTypography = styled(Typography)`
  opacity: 0;
  transform: translateY(50px);
  animation: ${fadeInBottomAnimation} 1s ease-in-out forwards;
`;

const AnimatedButton = styled(Button)`
  animation: ${fadeInBottomAnimation} 1s ease-in-out forwards;
  &:hover {
    animation: ${pulseAnimation} 0.5s infinite;
  }
`;

const SelectUs: React.FC<SelectUsProps> = ({
  title,
  subtitle,
  buttonText1,
  buttonText2,
  onClickVoid,
}: SelectUsProps) => {

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "190%" : "inital";

  return (
    <Box
      sx={{
        width: containerWidth,
        p: 4,
        textAlign: "center",
      }}
    >
      <Box
        marginY={0}
      >
        <AnimatedTypography
          variant="h5"
          sx={{
            color: "#353535",
            fontWeight: "600",
            fontFamily: "Poppins",
          
          }}
        >
          {title}
        </AnimatedTypography>
        <AnimatedTypography
          variant="h6"
          sx={{
            color: "#777777",
            marginTop: 1,
            fontWeight: "500",
            fontFamily: "Poppins",
          }}
        >
          {subtitle}
        </AnimatedTypography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: "center",
          marginTop: 1,
          marginBottom: 0,
        }}
      >
        <AnimatedButton
          variant="contained"
          sx={{
            bgcolor: "#05386b",
            color: "white",
            marginBottom: { xs: 2, sm: 0 },
            marginRight: { sm: 2 },
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: "0.8rem",
            padding: "10px 30px",
            textAlign: "center",
          }}
          onClick={onClickVoid}
        >
          <CheckCircleRoundedIcon
            sx={{ marginRight: 1, fontSize: "1.5rem", fontWeight: "bold" }}
          />
          {buttonText1}
        </AnimatedButton>
        <AnimatedButton
          variant="contained"
          sx={{
            bgcolor: "#FE5501",
            color: "white",
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: "0.8rem",
            padding: "10px 40px",
            textAlign: "center",
          }}
          onClick={onClickVoid}
        >
          <QuestionAnswerRoundedIcon
            sx={{ marginRight: 1, fontSize: "1rem", fontWeight: "bold" }}
          />
          {buttonText2}
        </AnimatedButton>
      </Box>
      <AnimatedTypography
        variant="h6"
        sx={{
          color: "black",
          marginTop: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        or call us at
        <span style={{ marginLeft: 1 }}>
          <CallRoundedIcon sx={{ fontSize: "1rem", fontWeight: "bold" }} />
        </span>
        <span style={{ marginLeft: 1 }}>1-866-292-8272</span>
      </AnimatedTypography>
    </Box>
  );
};

export default SelectUs;
