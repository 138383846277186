import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewSection from "../../components/OverviewSection";
import rattingImg from "../../Assets/reviwesImg/rating.png"

const ReviewsLogoDesign = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const reviews = [
    {
      imageUrl:
        "https://www.uptownlogodesign.com/assets/images/testi-author-01.jpg",
      text: "The last time I picked a brand after searching through web design company reviews it left me unsatisfied. However, this time it has been totally opposite. UptownLogoDesign has given my website a brand-new life. Keep up the good work guys!",
    },
    {
      imageUrl:
        "https://www.uptownlogodesign.com/assets/images/testi-author-02.jpg",
      text: "This is my first project with UptownLogoDesign and I cannot be happier. They are literally the best designing agency I have ever worked with, and trust me I have worked with a lot of them. Giving them five stars on being best custom logo designers..",
    },
    {
      imageUrl:
        "https://www.uptownlogodesign.com/assets/images/testi-author-03.jpg",
      text: "I believe I wanted the most complicated logo design ever. So, I searched through logo design guarantee reviews to look for a reliable company. I picked a few firms but finally decided on UptownLogoDesign. To my surprise they really designed the logo as it was asked. Rather did a better job than I expected actually. Well they truly are legends..",
    },
    // Add more review objects here
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) =>
        prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const currentReview = reviews[currentReviewIndex];
  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "210%" : "inital";

  return (
    <>
      <Box
        width={containerWidth}
        height={"100%"}
        marginTop={containerWidth === "inital" ? "160px" : "0"}
        overflow={"hidden"}
      >
        <OverviewSection
          title1="Our Client’s"
          titleColor="#292323"
          subTitle="Our Client’s Feedbacks & Daires"
          subTitleColor="#990304"
          bgImage="https://www.uptownlogodesign.com/assets/images/banners/logodesign-reviewbann.jpg"
          reverseLayout={true}
        />
        <Box
          display={"flex"}
          flexDirection={"column"} // Change to column direction
          justifyContent={"center"}
          alignItems={"center"}
          padding={"3rem"}
          // width={containerWidth}
        >
          <Typography
            variant="h3"
            color={"#990304"}
            fontSize={"1.8rem"}
            fontWeight={"600"}
            textAlign={"center"}
            fontFamily="Poppins"
          >
            Over 46,000+ Entrepreneurs, Businesses, And Non-Profit Love Uptown
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#666"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            fontFamily="Poppins"
            sx={{
              paddingX: { sm: "15rem", md: "5rem", },
            }}
          >
            Read online logo design guarantee reviews from our valuable clients
            to see how a true design experience changed their world.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.5rem"}
            marginTop={"35px"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily="Poppins"
          >
            Leat Logo Design Exclusive Logo Design Services
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#71706E"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            fontFamily="Poppins"
            sx={{
              paddingX: { sm: "15rem", md: "5rem", },
            }}
          >
            Having a ground-breaking logo design is paramount for any business.
            Uptown’s cutting-edge logo design services ensure to provide
            longevity to your business with a well-curated logo design.
            <br />
            <br />
            Uptown is a dedicated online marketplace that offers customized logo
            designs to clients. With an expert team of designers, it strives to
            provide a variety of logo styles and designs as per the needs of the
            clients. Moreover, our custom logo designers’ reviews resonate with
            our designing principles and depict our client’s journey from
            beginning to end.
            <br />
            <br />
            So, if you’re expecting a remarkable logo design for your business,
            UptownLogoDesign is an ideal choice for all your designing needs.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.5rem"}
            marginTop={"35px"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily="Poppins"
          >
            What Services Do We Provide?
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#71706E"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            fontFamily="Poppins"
            sx={{
              paddingX: { sm: "15rem", md: "5rem" },
            }}
          >
            From our custom logo design benefits review, you can have an idea
            about Uptown’s benefits and additional services.
            <br />
            <br />
            Affordability- Get unique logo designs by availing discounts from
            the company or choose a customized option from our various
            affordable packages.
            <br />
            <br />
            Creativity- Uptown’s talented team is ever ready to define its
            client’s unique brand identity by boosting creativity in the overall
            design process.
            <br />
            <br />
            Smooth Communication- With well-designed concepts and a vision of
            clients’ needs, we ensure a smooth communication process at every
            step.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.5rem"}
            marginTop={"35px"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily="Poppins"
          >
            About Leat Logo Design
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#71706E"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            fontFamily="Poppins"
            sx={{
              paddingX: { sm: "15rem", md: "5rem" },
            }}
          >
            Over a decade ago, we started our incredible journey with an
            unfaltering determination and a handful of challenges. The company
            has grown over the years with an addition of 150+ designers and more
            than 500 staff members
            <br />
            <br />
            Today, it enjoys a great market reputation with the best online logo
            design company reviews in the USA due to its versatile logo design
            services. Uptown’s marketplace and visionary designers allow clients
            to turn their designing ideas and concepts into benefitting logo
            designs.
            <br />
            <br />
            That’s why Uptown has its clients’ utmost trust during every step of
            the process to lead the way and deliver smooth custom design
            projects.
            <br />
            <br />
            If you’re also up for a design adventure, choose Uptown’s creative
            team of designers
            <br />
            <br />
            for your custom logo design projects for an ultimate experience.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            transition: "opacity 0.5s ease",
            opacity: 1,
            marginLeft: containerWidth !== "inital" ? "0" : "20%",
          }}
          padding={5}
          // width={"100%"}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  animation: "fadeIn 0.5s ease",
                  textAlign: "center",
                  alignItems: "center",
                  width: containerWidth !== "inital" ? "90%" : "60%",
                  height: 300,
                  overflow: "hidden",
                }}
              >
                <img
                  src={currentReview.imageUrl}
                  alt="logo"
                  style={{
                    width: "100px", // Set width
                    height: "100px", // Maintain aspect ratio
                    borderRadius: "100%", // Make it circular
                    border: "2px solid #990304", // Add border
                  }}
                />
                <Box
                  bgcolor={"#EBECEE"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    padding: "2rem",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#333",
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}
                  >
                    {currentReview.text}
                  </Typography>
                  <img
                    src={rattingImg}
                    alt="ratting"
                    style={{
                      width: "130px", // Set width
                      height: "100px", // Maintain aspect ratio
                      alignItems: "start",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ReviewsLogoDesign;
