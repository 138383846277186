import React from 'react'
import PortfolioSelector from '../../components/PortfolioSelector'
import OverviewSection from '../../components/OverviewSection'
import { Box } from '@mui/material'
import SelectUs from '../../components/selectUs'
import { useNavigate } from 'react-router-dom'

const PortfolioLogoDesign = () => {

  const menuItemData = [
    {
      label: "Select Your Industry",
      value: "",
      images: [
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/11.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/12.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/13.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/14.jpg",
        // Add more image URLs as needed
      ],
    },
    {
      label: "Accounting",
      value: "accounting",
      images: [
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/11.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/12.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/13.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/14.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/15.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/16.jpg",


        // Add more image URLs as needed
      ],
    },
    {
      label: "Advertising Agency",
      value: "advertising",
      images: [
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/thmb/06.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/thmb/07.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/thmb/08.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/thmb/08.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/thmb/08.jpg",
        "https://www.uptownlogodesign.com/assets/images/industry/accounting/thmb/08.jpg",



        // Add more image URLs as needed
      ],
    },
    // Add more menu items as needed
  ];

  // for select us
  const Navigate = useNavigate();
  const handlePackages = () => {
    Navigate("/logo-design/packages");
  };

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "220%" : "initial";

  
  return (
    <div  style={{ width: "100%", height: "100%", marginTop: containerWidth === "initial" ? "160px" : "0", backgroundColor: "#FFFFFF" }}>
      <OverviewSection
        title1="A Quick Look
        At Our Portfolio"
        subTitle="The Projects We Created For Our Clients Are A Source Of Pride And Motivation For Us."
        listItems={[
          "Modern Logo Design Concepts",
          "Free Logo Revision Cycles",
          "Iconic Logos For 100% Satisfaction",
          "Affordable Logo Design Packages",
          "Logo Design Gurus At Work",
        ]}
        // imageUrl="/assets/MainContentImg/workspace_04.jpg"
        // imageUrl2="/assets/MainContentImg/workspace_04.jpg"
        bgImage="https://www.uptownlogodesign.com/assets/images/banners/portfolio.jpg"
        reverseLayout={true}
      />
      <Box sx={{ padding: "60px" }}>
    <PortfolioSelector menuItemData={menuItemData} />
      </Box>
      <Box
        sx={{
          boxShadow: containerWidth === "initial" ? "0px 5px 20px rgba(0, 0, 0.2, 0.2)":"none",
          marginBottom: containerWidth === "initial" ? "0.5rem":"0rem",
          padding: "0rem",
        }}
      >
        <SelectUs
          buttonText1={"Select Package"}
          buttonText2={"Live chat"}
          onClickVoid={handlePackages}
          title={"Ready To Select Your Package ?"}
          subtitle={"Or Talk To Our Design Consultant"}
        />
      </Box>
    </div>
  )
}

export default PortfolioLogoDesign
