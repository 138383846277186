import React from 'react';
import { Box, Typography, Stepper, Step, StepButton, ThemeProvider, createTheme, Grid, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Custom icon component for the stepper
const CustomStepIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="12" r="10" fill={props.active ? '#990304' : '#71706E'} stroke="none" />
            <text x="12" y="14" textAnchor="middle" fill="#fff" fontSize="8" fontFamily="Poppins">{props.icon}</text>
        </SvgIcon>
    );
};

interface Step {
    step: string;
    content: string;
    imageUrl: string;
}

interface OverviewLogoDesignStepperProps {
    title: string;
    subtitle: string;
    steps?: Step[];
}

const OverviewLogoDesignStepper: React.FC<OverviewLogoDesignStepperProps> = ({ title, subtitle, steps = [] }) => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, steps.length - 1));
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    };

    // Custom theme with green color for stepper points
    const theme = createTheme({
        components: {
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        color: '#5cdb95', // Green color for stepper points
                    },
                    active: {
                        color: '#5cdb95', // Green color for active stepper point
                    },
                },
            },
        },
    });

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "180%" : "initial";


    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingBottom={10}
                sx={{
                    backgroundColor: 'white',
                    width:containerWidth,
                    padding:{ sm: 10, md: 3,}
                }}
                // Adjust the height as needed
            >
                <Box textAlign="center">
                    <Typography variant="h4" fontWeight={'600'} fontSize={'2.5rem'} fontFamily="Poppins" color={'#990304'} gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="#292323" fontFamily="Poppins" gutterBottom marginBottom={3} textAlign={'center'}>
                        {subtitle}
                    </Typography>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepButton onClick={handleStep(index)} icon={<CustomStepIcon icon={index + 1} active={activeStep === index} sx={{ fontSize: '2rem' ,backgroundcolor: '#5cdb95' }} />}>
                                    {step.step}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    {steps[activeStep] && (
                        <Grid container alignItems="center" spacing={2} >
                            <Grid item xs={2} sm={2}>
                            <ArrowBackIosIcon
                                        onClick={handleBack}
                                        style={{ color: '#990304', fontSize: '5rem', cursor: 'pointer', transition: 'font-size 0.3s', marginLeft: '10rem' }}
                                        onMouseOver={(event) => { event.currentTarget.style.fontSize = '7rem'; }}
                                        onMouseOut={(event) => { event.currentTarget.style.fontSize = '6rem'; }}
                                    />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Box sx={{textAlign: "left", justifyContent: "center" ,display: "flex", alignItems: "start", flexDirection: "column",overflow: "hidden", marginLeft: "5rem", flexGrow: 1, height: containerWidth !== "initial" ? "600px" : "400px",}} >
                                    <Typography variant="h4" fontWeight="500" fontFamily="Poppins" marginY={2} fontSize={"1.5rem"}>
                                        {steps[activeStep].step}
                                    </Typography>
                                    <Typography variant="h6" textAlign="start" fontFamily="Poppins" fontWeight="200" fontSize={"1rem"} color={"#71706E"}>
                                        {steps[activeStep].content}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={0} sx={{ display: { xs: 'none' ,md:"initial",  }, marginLeft: "12rem" }}>
                                <Box marginTop={2}>
                                    <img src={steps[activeStep].imageUrl} alt="Step Image" style={{ width: '200px', height: '200px' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                            <ArrowForwardIosIcon 
                            onClick={handleNext}
                            style={{ color: '#990304', fontSize: '5rem', cursor: 'pointer', transition: 'font-size 0.3s' }}
                                        onMouseOver={(event) => { event.currentTarget.style.fontSize = '7rem'; }}
                                        onMouseOut={(event) => { event.currentTarget.style.fontSize = '6rem'; }}
                                    />
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default OverviewLogoDesignStepper;
