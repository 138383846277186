import React from 'react'
import { Route, Routes } from 'react-router-dom'
import OverviewBranding from './componentsPage/OverviewBranding'
import WhyChooseUsBranding from './componentsPage/WhyChooseUsBranding'
import BrandingProcessBranding from './componentsPage/BrandingProcessBranding'
import PortfolioBranding from './componentsPage/PortfolioBranding'
import PackagesBranding from './componentsPage/PackagesBranding'
import ReviewsBranding from './componentsPage/ReviewsBranding'
import FaqsBranding from './componentsPage/FaqsBranding'
import Logo101Branding from './componentsPage/Logo101Branding'
import OurGuaranteeBranding from './componentsPage/OurGuaranteeBranding'

const Branding = () => {
  return (
  <>
    <Routes>
      <Route path="/" element={<OverviewBranding />} />
      <Route path="overview" element={<OverviewBranding />} />
      <Route path="why-choose-us" element={<WhyChooseUsBranding />} />
      <Route path='branding-process' element={<BrandingProcessBranding />} />
      <Route path ='portfolio' element={<PortfolioBranding />} />
      <Route path='packages' element={<PackagesBranding />} />
      <Route path='reviews' element={< ReviewsBranding/>} />
      <Route path='faqs' element={<FaqsBranding />} />
      <Route path='branding-101' element={<Logo101Branding />} />
      <Route path='our-guarantee' element={<OurGuaranteeBranding />} />
    </Routes>
  </>
  )
}

export default Branding
