import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewSection from "../../components/OverviewSection";

const ReviewsBranding = () => {
  const reviews = [
    {
      ImgUrl:
        "https://www.uptownlogodesign.com/assets/images/testi-author-01.jpg",
      Review:
        "The last time I picked a brand after searching through web design company reviews it left me unsatisfied. However, this time it has been totally opposite. ",
    },

    {
      ImgUrl:
        "https://www.uptownlogodesign.com/assets/images/testi-author-02.jpg",
      Review:
        "ULD has given my website a whole new life. Thank you for understanding my complicated project and putting in all the efforts. 5 stars to you",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle changing the review
  const changeReview = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  // useEffect to change review every 5 seconds
  useEffect(() => {
    const interval = setInterval(changeReview, 5000);
    return () => clearInterval(interval);
  }, []);

  const containerWidth = window.innerWidth >= 300 && window.innerWidth <= 900 ? "160%" : "inital";


  return (
    <>
      <Box width={"100%"} height={"100%"} marginTop={containerWidth === "inital" ? "150px" : "0"}>
        <OverviewSection
          title1="Branding Reviews For Our Stellar Branding Services"
          titleColor="white"
          subTitle="Our Clients' Feedback Featured Under The Branded Logo Designs Review Section Showcases Why We Dominate Branding Services In USA."
          subTitleColor="white"
          bgImage="https://www.uptownlogodesign.com/assets/images/banners/brandingdesign-reviewbann.jpg"
          reverseLayout={true}
        />
        <Box
          display={"flex"}
          flexDirection={"column"} // Change to column direction
          justifyContent={"center"}
          alignItems={"center"}
          width={containerWidth}
          paddingLeft={containerWidth !== "inital" ? "5rem" : "0rem"}
        >
          <Typography
            variant="h3"
            color={"#990304"}
            marginTop={"50px"}
            fontSize={"2rem"}
            fontWeight={"600"}
            fontFamily= "Poppins"
            textAlign={"center"}
          >
            Successfully Served 5000+ Projects From Over 200 Niches
          </Typography>
          <Typography
            variant="subtitle1"
            color={"black"}
            fontSize={containerWidth !== "inital" ? "1.5rem" : "1rem"}
            marginTop={"15px"}
            textAlign={"center"}
            paddingX = {containerWidth !== "inital" ? "0rem" : "10rem"}
            fontFamily= "Poppins"
          >
            We give your business strength to fly solo. Read our branded logo
            designs reviews to learn more about how we satiate our customers
            quench for creativity To get an exceptional branding service is the
            prerequisite of every business. At UptownLogoDesign, we provide
            cutting-edge branding services to ensure the longevity of your brand
            with well-strategized digital marketing.
            <br />
            <br />
            Do you think that small business are not able to afford the services
            of a top-notch digital marketing agency? We say, think again! At
            UptownLogoDesign, we have specific affordable branding services for
            all-sized enterprises. Book your quote now, and we will set up your
            brand to the route for success.
            <br />
            <br />
            Before choosing the best branding agency for your business, we
            highly recommend that you do comprehensive research on Branding
            reviews of all the branding agencies alternatives. Doing so will
            provide you a clear cut idea of which brands are the best in the
            market and which of them are a no go area.
            <br />
            <br />
            With UptownLogoDesign, you will find our online marketing strategies
            highly functional and impressive. We tie together a variety of
            digital marketing services to help your brand reach the peak it
            deserves. We know that consumers of the digital era flip back and
            forth between stages in the buyer’s journey. Therefore, we employ
            various tactics like email marketing, video promotion, SEO, and PPC.
            <br />
            <br />
            Our expert team of brand managers continuously strive to provide the
            best and the latest branding services as per the clients' needs.
            Furthermore, our custom design benefits reviews also resonate with
            our principles and depict our client’s journey from beginning to
            end.
            <br />
            <br />
            Thus, if you expect the best branding services for your business,
            UptownLogoDesign is a perfect choice.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.2rem"}
            marginTop={"35px"}
            fontWeight={500}
            paddingX = {containerWidth !== "inital" ? "0rem" : "10rem"}
            fontFamily= "Poppins"
          >
            Why choose us?
          </Typography>
          <Typography
            variant="subtitle1"
            color={"black"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            paddingX = {containerWidth !== "inital" ? "0rem" : "10rem"}
            fontFamily= "Poppins"
          >
            Here are all the reasons why UptownLogoDesign is the one-stop-shop
            for all your branding needs.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.2rem"}
            marginTop={"35px"}
            fontWeight={500}
            fontFamily= "Poppins"
            paddingX = {containerWidth !== "inital" ? "0rem" : "10rem"}
          >
            Search Engine Optimization (SEO)
          </Typography>
          <Typography
            variant="subtitle1"
            color={"black"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            paddingX = {containerWidth !== "inital" ? "0rem" : "10rem"}
            fontFamily= "Poppins"
          >
            We offer advanced SEO techniques and provide a competitive edge in
            search results on Google, Bing, and other search engines. With
            Uptown, your company can gain a custom strategy and start driving
            revenue today.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.2rem"}
            marginTop={"35px"}
            fontWeight={500}
            fontFamily= "Poppins"
          >
            Content Marketing
          </Typography>
          <Typography
            variant="subtitle1"
            color={"black"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            paddingX = {containerWidth !== "inital" ? "0rem" : "10rem"}
            fontFamily= "Poppins"
          >
            The brands that incorporate content marketing in their branding
            strategy see six times more conversions as compared to businesses
            without it. With Uptown, your company gets the full advantage of
            content marketing. Moreover, you get future shoppers and clients in
            every stage of the buying funnel with our data-driven branding
            services.
          </Typography>
          <Typography
            variant="h1"
            color={"#292323"}
            fontSize={"1.2rem"}
            marginTop={"35px"}
            fontWeight={500}
            fontFamily= "Poppins"
            paddingX = {containerWidth !== "inital" ? "0rem" : "10rem"}

          >
            Website Design and Development
          </Typography>
          <Typography
            variant="subtitle1"
            color={"black"}
            fontSize={"1rem"}
            marginTop={"10px"}
            textAlign={"center"}
            paddingX = {containerWidth !== "inital" ? "0rem" : "10rem"}
            fontFamily= "Poppins"
          >
            Almost 90 percent of the users bounce off from a website because of
            a poor website experience. To stop your business from losing
            potential customers, leads, and revenue, it must get a market
            competitive a professional web design and the highest branding
            reviews. UptownLogoDesign is an award-winning designing agency. We
            are the web you can trust!
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }} padding={10}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12}>
            <Box sx={{ display: "flex", justifyContent: "center", width:containerWidth !== "inital" ? "250%" : "100%" }}>
                <img
                  src={reviews[currentIndex].ImgUrl}
                  alt="logo"
                  style={{
                    width: "120px", // Set width
                    height: "120px", // Maintain aspect ratio
                    borderRadius: "100%", // Make it circular
                    border: "2px solid #990304", // Add border
                  }}
                />
                <Box
                  bgcolor={"#f1f1f1"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    width: "700px",
                    height: "100px", 
                    padding: "3rem",
                    overflow: "hidden"
                    
                  }}
                >
                  <Typography  variant="subtitle1"
                    sx={{
                      color: "#333",
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}>
                    {reviews[currentIndex].Review }
                  </Typography>
                  <img
                    src={"/assets/reviwesImg/rating.png"}
                    alt="ratting"
                    style={{
                      width: "100px", // Set width
                      height: "100px", // Maintain aspect ratio
                      alignItems: "start",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ReviewsBranding;
